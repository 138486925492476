import { AxiosAPiPost } from "AxiosAPi";
import React, { useContext, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import classes from "../../assets/css/Payment.module.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { Dialog, DialogContent } from "@mui/material";
import { AxiosAPiGet } from "AxiosAPi";
import { useFormik } from "formik";
import { PaymentSchema } from "utils/schemas/PaymentSchema";
import ReferenceDataContext from "AvatarContext";
function Payment() {
  // permissions list based on different roles //
  const { rolePermissions } = useContext(ReferenceDataContext);
  // permissions list based on different roles //
  // Use to redirect //
  const history = useHistory();
  // Use to redirect //
  // notification popup //
  const notificationAlertRef = useRef(null);
  // notification popup //
  // loading state //
  const [loading, setLoading] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  // loading state //
  // client type value capturing from local storage //
  const clientType = JSON.parse(localStorage.getItem("userData"))?.data
    ?.client_type;
  // client type value capturing from local storage //
  // payment inputs //
  const [paymentData, setPaymentData] = useState({
    llc_name: "",
    llc_address: "",
    bank_name: "",
    account_number: "",
    wire_routing_number: "",
    ach_routing_number: "",
    invoice_email: "",
  });
  // payment inputs //
  // encrypted number inputs //
  const [encryptAccountNumber, setEncryptAccountNumber] = useState("");
  const [encryptWireRoutingNumber, setEncryptWireRoutingNumber] = useState("");
  const [encryptACHRoutingNumber, setEncryptACHRoutingNumber] = useState("");
  // encrypted number inputs //
  // show details flag //
  const [showDetails, setShowDetails] = useState(false);
  // show details flag //
  // password inputs //
  const [password, setPassword] = useState("");
  // password inputs //
  // useEffect to get payment details //
  useEffect(() => {
    setLoading(true);
    AxiosAPiPost(
      `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/getPaymentInfo`,
      "post",
      "",
      (res) => {
        setLoading(false);
        setPaymentData({
          llc_name: res.data?.data?.llc_name,
          llc_address: res.data?.data?.llc_address,
          bank_name: res.data?.data?.bank_name,
          invoice_email: res.data?.data?.invoice_email,
          account_number: res.data?.data?.account_number,
          wire_routing_number: res.data?.data?.wire_routing_number,
          ach_routing_number: res.data?.data?.ach_routing_number,
        });
        setEncryptAccountNumber(res.data?.data?.encrypt_account_number);
        setEncryptWireRoutingNumber(
          res.data?.data?.encrypt_wire_routing_number
        );
        setEncryptACHRoutingNumber(res.data?.data?.encrypt_ach_routing_number);
      },
      history,
      (err) => {
        let options = {};
        options = {
          place: "tr",
          message: err.response.data.message,
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
        setLoading(false);
      }
    );
  }, [history]);
  // useEffect to get payment details //
  // initial payment inputs //
  const initialValues = {
    llc_name: paymentData.llc_name,
    llc_address: paymentData.llc_address,
    bank_name: paymentData.bank_name,
    account_number: paymentData.account_number,
    wire_routing_number: paymentData.wire_routing_number,
    ach_routing_number: paymentData.ach_routing_number,
    invoice_email: paymentData.invoice_email,
  };
  // initial payment inputs //
  // form submission and error validation //
  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: PaymentSchema,
    onSubmit: (values, action) => {
      setLoading(true);
      const payload = {
        llc_name: values.llc_name,
        llc_address: values.llc_address,
        bank_name: values.bank_name,
        account_number: values.account_number?.toString().includes("X")
          ? encryptAccountNumber
          : values.account_number?.toString(),
        invoice_email: values.invoice_email,
        wire_routing_number: values.wire_routing_number
          ?.toString()
          .includes("X")
          ? encryptWireRoutingNumber
          : values.wire_routing_number?.toString(),
        ach_routing_number: values.ach_routing_number?.toString().includes("X")
          ? encryptACHRoutingNumber
          : values.ach_routing_number?.toString(),
      };

      AxiosAPiPost(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/updatePaymentInfo`,
        "post",
        payload,
        async (response) => {
          if (response?.data.status === true) {
            let options = {};
            options = {
              place: "tr",
              message: response.data.message,
              type: "success",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 3,
            };

            notificationAlertRef?.current?.notificationAlert(options);
            setLoading(false);
            setLoading(true);
            await AxiosAPiGet(
              `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/getPaymentInfo`,
              "get",
              (res) => {
                setPaymentData({
                  llc_name: res.data?.data?.llc_name,
                  llc_address: res.data?.data?.llc_address,
                  bank_name: res.data?.data?.bank_name,
                  invoice_email: res.data?.data?.invoice_email,
                  account_number: res.data?.data?.account_number,
                  wire_routing_number: res.data?.data?.wire_routing_number,
                  ach_routing_number: res.data?.data?.ach_routing_number,
                });
                setEncryptAccountNumber(res.data?.data?.encrypt_account_number);
                setEncryptWireRoutingNumber(
                  res.data?.data?.encrypt_wire_routing_number
                );
                setEncryptACHRoutingNumber(
                  res.data?.data?.encrypt_ach_routing_number
                );
                setLoading(false);
              },
              history,
              (err) => {
                let options = {};
                options = {
                  place: "tr",
                  message: err.response.data.message,
                  type: "warning",
                  icon: "tim-icons icon-bell-55",
                  autoDismiss: 3,
                };
                notificationAlertRef.current.notificationAlert(options);
              }
            );
          } else {
            let options = {};
            options = {
              place: "tr",
              message: response.data.message,
              type: "warning",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 3,
            };
            notificationAlertRef?.current?.notificationAlert(options);
            // setLoadingButton(false);
          }
        },
        history,
        (err) => {
          if (err.response.status === 422) {
            if (err.response.data.errors.invoice_email) {
              let options = {};
              options = {
                place: "tr",
                message: err.response.data.errors.invoice_email.message,
                type: "warning",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3,
              };
              notificationAlertRef?.current?.notificationAlert(options);
              setLoading(false);
            }
          } else {
            let options = {};
            options = {
              place: "tr",
              message: err.response.data.message,
              type: "warning",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 3,
            };
            notificationAlertRef?.current?.notificationAlert(options);
            setLoading(false);
          }
        }
      );
    },
  });
  // form submission and error validation //
  // close dialog functionality //
  const handleClose = () => {
    setShowDetails(false);
    setPassword("");
  };
  // close dialog functionality //
  // password verification functionality //
  const handlePasswordVerification = (e) => {
    e.preventDefault();
    setLoadingVerify(true);
    AxiosAPiPost(
      `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/viewEncryptData`,
      "post",
      {
        password: password,
      },
      (res) => {
        if (res.data.status === true) {
          let options = {};
          options = {
            place: "tr",
            message: res.data.message,
            type: "success",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef.current.notificationAlert(options);
          setPaymentData({
            llc_name: paymentData.llc_name,
            llc_address: paymentData?.llc_address,
            bank_name: paymentData?.bank_name,
            account_number: res.data?.data?.account_number,
            wire_routing_number: res.data?.data?.wire_routing_number,
            ach_routing_number: res.data?.data?.ach_routing_number,
          });
          setPassword("");
          setLoadingVerify(false);
          setShowDetails(false);
        } else {
          let options = {};
          options = {
            place: "tr",
            message: res.data.message,
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef.current.notificationAlert(options);
          setLoadingVerify(false);
        }
      },
      history,
      (err) => {
        let options = {};
        options = {
          place: "tr",
          message: err.response.data.message,
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
        setLoadingVerify(false);
      }
    );
  };
  // password verification functionality //
  // toggle details functionality //
  const handleToggle = () => {
    setShowDetails(!showDetails);
  };
  // toggle details functionality //
  // Disable "e", "E", "+", "-", "." on number input //
  const handleKeyPress = (event) => {
    var key = window.event ? event.keyCode : event.which;
    if (
      event.keyCode === 8 ||
      event.keyCode === 46 ||
      event.keyCode === 37 ||
      event.keyCode === 39
    ) {
      return true;
    } else if (event.shiftKey || key < 48 || key > 57) {
      event.preventDefault();
    } else return true;
  };
  // Disable "e", "E", "+", "-", "." on number input //
  return (
    <div className="content">
      <NotificationAlert ref={notificationAlertRef} />
      <Row>
        <Col md="12">
          <div className="customColor4 p-3 p-sm-4 rounded-2">
            <Form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              id="RegisterValidation"
              className="commonForm popupForm"
            >
              <Card
                className={
                  clientType === "shopify"
                    ? "commonForm customColor4"
                    : "commonForm customColor6 rounded-2  py-3 px-sm-3 mb-0"
                }
              >
                <p className="select-title textColor pt-1 pt-md-2 px-3">
                  Payment Details
                </p>
                <CardBody>
                  {rolePermissions?.includes("view") ? (
                    <>
                      {" "}
                      <div
                        className={
                          clientType === "shopify"
                            ? "customColor6 rounded-2  py-3 px-sm-3 mb-0"
                            : ""
                        }
                      >
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label className="darkText labelText">
                                LLC Name *{" "}
                              </label>
                              <Input
                                id="llc_name"
                                name="llc_name"
                                className="bg-white"
                                type="text"
                                placeholder="Enter your LLC Name"
                                value={values.llc_name}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                              {errors.llc_name && touched.llc_name ? (
                                <p className={classes["form_error"]}>
                                  {errors.llc_name}
                                </p>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <label className="darkText labelText">
                                LLC Address *{" "}
                              </label>
                              <Input
                                id="llc_address"
                                name="llc_address"
                                className="bg-white"
                                type="text"
                                required
                                placeholder="Enter your LLC Address"
                                value={values.llc_address}
                                onChange={handleChange}
                                // onBlur={handleBlur}
                              />
                              {errors.llc_address && touched.llc_address ? (
                                <p className={classes["form_error"]}>
                                  {errors.llc_address}
                                </p>
                              ) : null}
                            </FormGroup>
                          </Col>
                        </Row>
                        {clientType === "api" ? (
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <label className="darkText labelText">
                                  Invoice Email *
                                </label>
                                <Input
                                  id="invoice_email"
                                  type="email"
                                  name="invoice_email"
                                  className="bg-white"
                                  placeholder="Enter your Invoice Email"
                                  value={values.invoice_email}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                />
                                {errors.invoice_email &&
                                touched.invoice_email ? (
                                  <p className={classes["form_error"]}>
                                    {errors.invoice_email}
                                  </p>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <label className="darkText labelText">
                                  Bank Name *
                                </label>
                                <Input
                                  id="bank_name"
                                  name="bank_name"
                                  className="bg-white"
                                  type="text"
                                  placeholder="Enter your Bank Name"
                                  value={values.bank_name}
                                  onChange={handleChange}
                                  // onBlur={handleBlur}
                                />
                                {errors.bank_name && touched.bank_name ? (
                                  <p className={classes["form_error"]}>
                                    {errors.bank_name}
                                  </p>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        )}
                      </div>
                      <br />
                      <div
                        className={
                          clientType === "shopify"
                            ? "customColor6 rounded-2  py-3 px-sm-3 mb-0"
                            : ""
                        }
                      >
                        {clientType === "shopify" && (
                          <>
                            {" "}
                            <Row>
                              <Col className="d-flex justify-content-end">
                                <i
                                  className={
                                    paymentData.account_number.includes("X") &&
                                    (paymentData.wire_routing_number.includes(
                                      "X"
                                    ) ||
                                      paymentData.ach_routing_number.includes(
                                        "X"
                                      ))
                                      ? showDetails
                                        ? `fas fa-eye ${classes["password_toggle"]}`
                                        : `fas fa-eye-slash ${classes["password_toggle"]}`
                                      : `${classes["show_icon"]}`
                                  }
                                  onClick={handleToggle}
                                ></i>
                              </Col>
                              <Col md="12">
                                <FormGroup>
                                  <label className="darkText labelText">
                                    Account Number *
                                  </label>
                                  <Input
                                    id="account_number"
                                    name="account_number"
                                    className="bg-white"
                                    type={
                                      values.account_number
                                        .toString()
                                        .includes("X")
                                        ? "text"
                                        : "number"
                                    }
                                    placeholder="Enter your Account Number"
                                    value={values.account_number}
                                    readOnly={
                                      values.account_number &&
                                      values.account_number
                                        .toString()
                                        .includes("X")
                                    }
                                    onChange={handleChange}
                                    minLength={10}
                                    min={0}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                  {!values.account_number
                                    .toString()
                                    .includes("X") &&
                                  errors.account_number &&
                                  touched.account_number ? (
                                    <p className={classes["form_error"]}>
                                      {errors.account_number}
                                    </p>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>{" "}
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <label className="darkText labelText">
                                    Wire Routing Number *
                                  </label>
                                  <Input
                                    id="wire_routing_number"
                                    name="wire_routing_number"
                                    className="bg-white"
                                    type={
                                      values.wire_routing_number
                                        .toString()
                                        .includes("X")
                                        ? "text"
                                        : "number"
                                    }
                                    placeholder="Enter your Wire Routing Number"
                                    value={values.wire_routing_number}
                                    readOnly={
                                      values.wire_routing_number &&
                                      values.wire_routing_number
                                        .toString()
                                        .includes("X")
                                    }
                                    onChange={handleChange}
                                    minLength={10}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                  {!values.wire_routing_number
                                    .toString()
                                    .includes("X") &&
                                  errors.wire_routing_number &&
                                  touched.wire_routing_number ? (
                                    <p className={classes["form_error"]}>
                                      {errors.wire_routing_number}
                                    </p>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col md="12">
                                <FormGroup>
                                  <label className="darkText labelText">
                                    ACH Routing Number *
                                  </label>
                                  <Input
                                    id="ach_routing_number"
                                    name="ach_routing_number"
                                    className="bg-white"
                                    type={
                                      values.ach_routing_number
                                        .toString()
                                        .includes("X")
                                        ? "text"
                                        : "number"
                                    }
                                    placeholder="Enter your ACH Routing Number"
                                    value={values.ach_routing_number}
                                    readOnly={
                                      values.ach_routing_number &&
                                      values.ach_routing_number
                                        .toString()
                                        .includes("X")
                                    }
                                    onChange={handleChange}
                                    minLength={10}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                    onWheel={(e) => e.target.blur()}
                                  />
                                  {!values.ach_routing_number
                                    .toString()
                                    .includes("X") &&
                                  errors.ach_routing_number &&
                                  touched.ach_routing_number ? (
                                    <p className={classes["form_error"]}>
                                      {errors.ach_routing_number}
                                    </p>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </CardBody>
                {rolePermissions?.includes("add/update") ? (
                  <CardFooter className="btn-set pt-0">
                    <Button color="info" type="submit">
                      {loading ? "Processing..." : "Update"}
                    </Button>
                  </CardFooter>
                ) : (
                  <></>
                )}
              </Card>
            </Form>
          </div>
        </Col>
      </Row>
      <Dialog
        open={showDetails}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
          },
        }}
      >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <DialogContent dividers className="px-0 px-sm-3 px-md-4">
          <div className={`popupForm-holder ${classes["dialog_content"]}`}>
            <Row>
              <Col md="12">
                <Form
                  id="RegisterValidation"
                  onSubmit={(e) => handlePasswordVerification(e)}
                  className="commonForm popupForm"
                >
                  <Card className="customColor4">
                    <CardHeader>
                      <CardTitle
                        tag="h4"
                        className={`darkText labelText mb-0 ${classes["view_icon_cross"]}`}
                      >
                        Please verify your password
                        <i
                          onClick={handleClose}
                          className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
                        ></i>
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">Password *</label>
                        <Input
                          className="bg-white"
                          name="new_password"
                          placeholder="Enter your password"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="text-center btn-set">
                      <Button color="info" type="submit" disabled={!password}>
                        {loadingVerify ? "Processing..." : "Verify"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Payment;
