import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import NotificationAlert from "react-notification-alert";
import classes from "../../assets/css/DomainLabelling.module.css";
import "../../assets/css/DomainLabelling.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import { DomainLabellingSchema } from "utils/schemas/DomainLabellingSchema";
import {
  Box,
  CardContent,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { AxiosAPiPost } from "AxiosAPi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FaTimesCircle } from "react-icons/fa";
import { AxiosAPiGet } from "AxiosAPi";
import { DomainLabellingClientSchema } from "utils/schemas/DomainLabellingSchema";
import axios from "axios";
import ReferenceDataContext from "AvatarContext";

function DomainLabelling() {
  // permissions list based on different roles //
  const { rolePermissions } = useContext(ReferenceDataContext);
  // permissions list based on different roles //
  // Use to show table div when load //
  const tableRef = useRef(null);
  // Use to show table div when load //
  // Use to redirect //
  const history = useHistory();
  // Use to redirect //
  //notification popup //
  const notificationAlertRef = useRef(null);
  //notification popup //
  // loading state //
  const [loadingList, setLoadingList] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  // loading state //
  // Pagination page input //
  const [page, setPage] = useState(0);
  // Pagination page input //
  // Pagination number of rows per page //
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // Pagination number of rows per page //
  // Input for capturing total client data count //
  const [dataCount, setDataCount] = useState(0);
  // Input for capturing total client data count //
  // input for storing client list i.e coming from API //
  const [clientList, setClientList] = useState([]);
  // input for storing client list i.e coming from API //
  // input for counting progress after creation of domain //
  const [progress, setProgress] = useState(10);
  // input for counting progress after creation of domain //
  // input for storing names //
  const [names, setNames] = useState([]);
  // input for storing names //
  // input for storing client type //
  const [clientType, setClientType] = useState("");
  // input for storing client type //
  // user data //
  const userData = JSON.parse(localStorage.getItem("userData"));
  // user data //
  // input for storing client Id //
  const [clientId, setClientId] = useState([]);
  // input for storing client Id //
  // input for storing domain list i.e coming from API //
  const [domainDetailsList, setDomainDetailsList] = useState([]);
  // input for storing domain list i.e coming from API //
  // input for storing active step on dialog //
  const [activeStep, setActiveStep] = React.useState(0);
  // input for storing active step on dialog //
  // input for storing steps value on dialog //
  const [stepperValue, setStepperValue] = React.useState("");
  // input for storing steps value on dialog //
  // input for storing current page value //
  const [currentPage, setCurrentPage] = useState("");
  // input for storing current page value //
  // Input for storing total page value //
  const [totalPage, setTotalPage] = useState("");
  // Input for storing total page value //
  // Steps Heading inputs //
  const [steps, setSteps] = useState([
    "Add type",
    "Add domain/sub-domain name",
    "Add value",
    "Finish",
  ]);
  // Steps Heading inputs //
  // Steps contents //
  const [stepItems, setStepItems] = useState([
    {
      src: require("../../assets/img/type_image.png"),
      altText: "Type image",
      step_name: "Add type",
      caption: (
        <Typography variant="body2" color="text.secondary">
          Please choose "A" for "Type"
        </Typography>
      ),
    },
    {},
    {
      src: require("../../assets/img/value_image.png"),
      altText: "Value image",
      step_name: "Add value",
      caption: (
        <Typography variant="body2" color="text.secondary">
          Please add "52.87.93.111" for "Value"
        </Typography>
      ),
    },
    {
      src: "",
      altText: "",
      step_name: "Finish",
      caption: (
        <Typography variant="body2" color="text.secondary">
          After adding the above details to DNS management, an SSL certificate
          will be assigned and the update can be checked from the 'Status'
          column.
        </Typography>
      ),
    },
  ]);
  let url_list = rolePermissions?.includes("list")
    ? "?"
    : `?where_clause={"where_fields":["clientId"],"where_values":["${userData?.data?.clientId}"]}&`;
  // Steps contents //
  //initial form inputs //
  const initialValues = {
    domain_name: "",
    app_name: "",
    client_type: "",
    user: "",
  };

  //initial form inputs //
  // Form submission and error handling //
  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: rolePermissions?.includes("list")
        ? DomainLabellingSchema
        : DomainLabellingClientSchema,
      onSubmit: (values, action) => {
        setLoadingProgress(true);
        const data = {
          domain_name: values.domain_name,
          app_name: values.app_name,
          clientId: rolePermissions?.includes("list")
            ? clientId[0]
            : userData?.data?.clientId,
        };
        AxiosAPiPost(
          `${process.env.REACT_APP_AXIOS_BASE_URL}/domaindetails/save`,
          "post",
          data,
          (res) => {
            setClientId([]);
            setClientList([]);
            setClientType("");
            setFieldValue("client_type", "");
            if (res.data.status === true) {
              var progress_number = 10;
              const timer = setInterval(() => {
                progress_number += 10;
                setProgress(progress_number);
                if (progress_number > 100) {
                  clearInterval(timer);
                  setProgress(10);

                  setLoadingProgress(false);
                  let options = {};
                  options = {
                    place: "tr",
                    message: res.data.message,
                    type: "success",
                    icon: "tim-icons icon-bell-55",
                    autoDismiss: 3,
                  };
                  notificationAlertRef.current.notificationAlert(options);
                  async function getDomainListDataAfterCreate() {
                    setLoadingList(true);
                    AxiosAPiGet(
                      `${process.env.REACT_APP_AXIOS_BASE_URL}/domaindetails/list` +
                        url_list +
                        `show=${rowsPerPage}&page=${page + 1}`,
                      "get",
                      (res) => {
                        setLoadingList(false);
                        setCurrentPage(
                          res?.data?.results?.results?.current_page
                        );
                        setTotalPage(res?.data?.results?.results?.last_page);
                        setDomainDetailsList(res?.data?.results?.results?.data);
                        setDataCount(res?.data?.results?.results_count);
                        if (
                          res?.data?.results?.results?.data?.filter(
                            (val) => val?.status !== 3
                          )?.length !== 0
                        ) {
                          const checkDns = async () => {
                            var status_without_three =
                              res?.data?.results?.results?.data?.filter(
                                (val) => val?.status !== 3
                              );
                            if (status_without_three?.length !== 0) {
                              let status_without_three_id =
                                status_without_three?.map((val) => {
                                  return val?._id;
                                });
                              const data = {
                                ids: status_without_three_id,
                              };
                              await axios
                                .post(
                                  `${process.env.REACT_APP_AXIOS_BASE_URL}/domaindetails/checkDns`,
                                  data,
                                  {
                                    headers: {
                                      "Content-Type": "application/json",
                                      token:
                                        localStorage.getItem("access_token"),
                                    },
                                  }
                                )
                                .then((res) => {})
                                .catch((err) => {
                                  if (
                                    err.response.data.message ===
                                    "Invalid Token"
                                  ) {
                                    localStorage.clear();
                                    history.push("/login");
                                  } else {
                                    let options = {};
                                    options = {
                                      place: "tr",
                                      message: err.response.data.message,
                                      type: "warning",
                                      icon: "tim-icons icon-bell-55",
                                      autoDismiss: 3,
                                    };
                                    notificationAlertRef?.current?.notificationAlert(
                                      options
                                    );
                                  }
                                });
                            } else {
                              return;
                            }
                          };
                          checkDns();
                        } else {
                          clearInterval(setTimer);
                        }
                      },
                      history,
                      (err) => {
                        let options = {};
                        options = {
                          place: "tr",
                          message: err.response.data.message,
                          type: "warning",
                          icon: "tim-icons icon-bell-55",
                          autoDismiss: 3,
                        };
                        notificationAlertRef?.current?.notificationAlert(
                          options
                        );
                        setLoadingList(false);
                      }
                    );
                  }
                  const setTimer = setInterval(() => {
                    getDomainListDataAfterCreate();
                  }, 3 * 60 * 1000);
                  getDomainListDataAfterCreate();
                  setTimeout(() => {
                    setShowDialog(true);
                  }, 1000);
                  return () => clearInterval(setTimer);
                }
              }, 800);
            } else {
              let options = {};
              options = {
                place: "tr",
                message: res.data.message,
                type: "warning",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3,
              };
              notificationAlertRef?.current?.notificationAlert(options);
              setLoadingProgress(false);
            }
          },
          history,
          (err) => {
            if (err.response.status === 422) {
              if (err.response?.data?.errors?.domain_name) {
                let options = {};
                options = {
                  place: "tr",
                  message: err.response?.data?.errors?.domain_name?.message,
                  type: "warning",
                  icon: "tim-icons icon-bell-55",
                  autoDismiss: 3,
                };
                notificationAlertRef?.current?.notificationAlert(options);
                setLoadingProgress(false);
              }
            } else {
              let options = {};
              options = {
                place: "tr",
                message: err.response.data.message,
                type: "warning",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3,
              };
              notificationAlertRef?.current?.notificationAlert(options);
              setLoadingProgress(false);
            }
          }
        );

        action.resetForm();
      },
    });
  // Form submission and error handling //
  //useEffect for getting domain list //
  useEffect(() => {
    async function getDomainListData() {
      setLoadingList(true);
      AxiosAPiGet(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/domaindetails/list` +
          url_list +
          `show=${rowsPerPage}&page=${page + 1}`,
        "get",
        (res) => {
          setLoadingList(false);
          setCurrentPage(res?.data?.results?.results?.current_page);
          setTotalPage(res?.data?.results?.results?.last_page);
          setDomainDetailsList(res?.data?.results?.results?.data);
          setDataCount(res?.data?.results?.results_count);
          if (
            res?.data?.results?.results?.data?.filter(
              (val) => val?.status !== 3
            )?.length !== 0
          ) {
            const checkDns = async () => {
              var status_without_three =
                res?.data?.results?.results?.data?.filter(
                  (val) => val?.status !== 3
                );
              if (status_without_three?.length !== 0) {
                let status_without_three_id = status_without_three?.map(
                  (val) => {
                    return val?._id;
                  }
                );
                const data = {
                  ids: status_without_three_id,
                };
                await axios
                  .post(
                    `${process.env.REACT_APP_AXIOS_BASE_URL}/domaindetails/checkDns`,
                    data,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        token: localStorage.getItem("access_token"),
                      },
                    }
                  )
                  .then((res) => {})
                  .catch((err) => {
                    if (err.response.data.message === "Invalid Token") {
                      localStorage.clear();
                      history.push("/login");
                    } else {
                      let options = {};
                      options = {
                        place: "tr",
                        message: err.response.data.message,
                        type: "warning",
                        icon: "tim-icons icon-bell-55",
                        autoDismiss: 3,
                      };
                      notificationAlertRef?.current?.notificationAlert(options);
                    }
                  });
              } else {
                return;
              }
            };
            checkDns();
          } else {
            clearInterval(setTimer);
          }
        },
        history,
        (err) => {
          let options = {};
          options = {
            place: "tr",
            message: err.response.data.message,
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef?.current?.notificationAlert(options);
          setLoadingList(false);
        }
      );
    }
    const setTimer = setInterval(() => {
      getDomainListData();
    }, 3 * 60 * 1000);
    getDomainListData();

    return () => clearInterval(setTimer);
  }, [history, page, rowsPerPage, url_list]);
  //useEffect for getting domain list //
  // useEffect for client list i.e coming from API //
  useEffect(() => {
    let url = "";
    const NotificationPeopleList = (url) => {
      AxiosAPiGet(
        url,
        "get",
        (res) => {
          setNames(
            res?.data?.results?.results.data?.map((name) => {
              let updatedName;

              updatedName = name?.hasOwnProperty("company_name")
                ? name?.company_name === "" ||
                  name?.company_name === undefined ||
                  name?.company_name === null
                  ? name?.name === "" ||
                    name?.name === undefined ||
                    name?.name === null
                    ? {
                        ...name,
                        updated_name: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                      }
                    : {
                        ...name,
                        updated_name: `${name?.name} (${name?.clientId})`,
                      }
                  : {
                      ...name,
                      updated_name: `${name.company_name} (${name?.clientId})`,
                    }
                : name?.name === "" ||
                  name?.name === undefined ||
                  name?.name === null
                ? {
                    ...name,
                    updated_name: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                  }
                : {
                    ...name,
                    updated_name: `${name?.name} (${name?.clientId})`,
                  };
              return { updatedName };
            })
          );
        },
        history,
        (err) => {
          let options = {};
          options = {
            place: "tr",
            message: err.response.data.message,
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef.current.notificationAlert(options);
        }
      );
    };
    switch (clientType) {
      case "":
        setNames([]);
        break;
      case "all":
        url = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","is_test_account","status"],"where_values":["client","false","Active"]}`;
        NotificationPeopleList(url);
        break;
      default:
        url = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","client_type","is_test_account","status"],"where_values":["client","${values.client_type}","false","Active"]}`;
        NotificationPeopleList(url);
        break;
    }
  }, [values, history, clientType]);
  // useEffect for client list i.e coming from API //
  // useEffect for change the client name //
  useEffect(() => {
    if (clientList?.length !== 0) {
      let newArr = clientList?.map((person) => {
        return person?.split(" ")[person?.split(" ").length - 1];
      });
      let secondArr = newArr?.map((val) => {
        return val?.substr(1)?.substr(0, val?.substr(1).length - 1);
      });
      setClientId(secondArr);
    }
  }, [clientList]);
  // useEffect for change the client name //
  // Handle page change in pagination //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };
  // Handle page change in pagination //
  //Rows per page in pagination //
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  //Rows per page in pagination //
  // Function for selecting client //
  const handleChangeClientList = (e) => {
    const {
      target: { value },
    } = e;
    setClientList(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    values.user = value;
    setFieldValue("user", value);
  };
  // Function for selecting client //
  // Function for remove selected client //
  const handleDelete = (value) => {
    let remove_names = clientList?.filter((name) => name !== value);
    setClientList(remove_names);
    values.user = remove_names.join();
    setFieldValue("user", remove_names.join());
  };
  // Function for remove selected client //
  //Function for handle date format //
  const handleDateFormat = (date) => {
    let dateFull = new Date(date?.slice(0, 10));
    let day = dateFull.getDate();
    let month = dateFull.getMonth() + 1;
    let year = dateFull.getFullYear();
    return (
      (month < 10 ? `0${month}` : month) +
      "-" +
      (day < 10 ? `0${day}` : day) +
      "-" +
      year
    );
  };
  //Function for handle date format //
  // Skeleton Loader //
  const DomainListLoader = () => {
    return [...Array(rowsPerPage)].map((val, index) => (
      <tr key={index}>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        {rolePermissions?.includes("list") && (
          <td className="textColor">
            <Skeleton animation="wave" variant="text" />
          </td>
        )}
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
      </tr>
    ));
  };
  // Skeleton Loader //
  // Function for handle client type change //
  const handleClientTypeChange = (e) => {
    setFieldValue("client_type", e.target.value);
    values.client_type = e.target.value;
    setClientType(e.target.value);
    setClientList([]);
    setFieldValue("user", "");
    values.user = "";
  };
  // Function for handle client type change //
  // Function for change active step value while click on next //
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  // Function for change active step value while click on next //
  // Function while click on finish //
  const handleFinish = () => {
    setShowDialog(false);
    setActiveStep(0);
    setStepperValue("");
    steps.splice(1, 1, "Add domain/sub-domain name");
    stepItems.splice(1, 1, {});
    setSteps(steps);
    setStepItems(stepItems);
  };
  // Function while click on finish //
  // Function for change active step value while click on back //
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  // Function for change active step value while click on back //
  // Function for change radio buttons values //
  const handleRadioButtonValues = (e) => {
    let new_steps = [...steps];
    let new_steps_items = [...stepItems];
    setStepperValue(e.target.value);
    if (e.target.value === "domain") {
      new_steps[1] = "Add domain name";
      new_steps_items[1] = {
        src: require("../../assets/img/domain_name_image.png"),
        altText: "Domain name image",
        step_name: "Add domain name",
        caption: (
          <Typography variant="body2" color="text.secondary">
            Please add "@" for "Name"
          </Typography>
        ),
      };
    } else {
      new_steps[1] = "Add sub-domain name";
      new_steps_items[1] = {
        src: require("../../assets/img/sub-domain_name_image.png"),
        altText: "Sub-domain name image",
        step_name: "Add sub-domain name",
        caption: (
          <Typography variant="body2" color="text.secondary">
            Please add subdomain name for "Name"
          </Typography>
        ),
      };
    }
    setSteps(new_steps);
    setStepItems(new_steps_items);
  };
  // Function for change radio buttons values //
  // Function for close dialog //
  const handleClose = () => {
    setShowDialog(false);
    setActiveStep(0);
    setStepperValue("");
    steps.splice(1, 1, "Add domain/sub-domain name");
    stepItems.splice(1, 1, {});
    setSteps(steps);
    setStepItems(stepItems);
  };
  // Function for close dialog //
  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="customColor4 p-3 p-sm-4 rounded-2">
        {rolePermissions?.includes("add") ? (
          loadingProgress ? (
            <div className={classes["progress_text"]}>
              <p>The branded domain creation process is in progress...</p>
              <Box
                sx={{
                  position: "relative",
                  textAlign: "center",
                  fontSize: "40px",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={progress}
                  size={60}
                />

                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "12px",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    className={classes["progress_number_text"]}
                  >
                    {`${Math.round(progress)}%`}
                  </Typography>
                </Box>
              </Box>
            </div>
          ) : (
            <Form className="commonForm popupForm" onSubmit={handleSubmit}>
              <Card className="commonForm customColor4">
                <p className="select-title textColor pt-1 pt-md-2 px-3">
                  Create Branded Web App
                </p>
                <CardBody className="customColor6 rounded-2  py-3 px-sm-3 mb-0">
                  {rolePermissions?.includes("list") && (
                    <Row>
                      <Col lg="6" sm="6" className="mt-3 mt-lg-0">
                        <FormGroup>
                          <Label className="darkText labelText">
                            Client Type:
                          </Label>
                          <Select
                            className={`w-100 bg-white ${classes["client_type_input"]}`}
                            name="client_type"
                            value={values.client_type}
                            onChange={(e) => handleClientTypeChange(e)}
                            displayEmpty
                          >
                            <MenuItem value="">Choose Client Type</MenuItem>
                            <MenuItem value="all">All</MenuItem>
                            <MenuItem value="api">API</MenuItem>
                            <MenuItem value="shopify">Shopify</MenuItem>
                          </Select>
                          {errors.client_type && touched.client_type ? (
                            <p className={classes["form_error"]}>
                              {errors.client_type}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col lg="6" sm="6" className="mt-3 mt-lg-0">
                        <FormGroup>
                          <Label className="darkText labelText">
                            Client List:
                          </Label>
                          <Select
                            className={`w-100 bg-white ${classes["client_list_input"]}`}
                            value={clientList}
                            onChange={(e) => handleChangeClientList(e)}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return `Choose a client`;
                              }
                              return (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.6,
                                  }}
                                >
                                  {selected.map((value, i) => (
                                    <Chip
                                      key={i}
                                      label={value}
                                      deleteIcon={
                                        <FaTimesCircle
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                      clickable
                                      onDelete={() => handleDelete(value)}
                                    />
                                  ))}
                                </Box>
                              );
                            }}
                            // MenuProps={MenuProps}
                            inputProps={{ "aria-label": "Without label" }}
                            displayEmpty
                          >
                            <MenuItem
                              className={
                                values.client_type === "" ? "d-none" : ""
                              }
                              disabled
                              value=""
                            >
                              Choose a client
                            </MenuItem>

                            {names.length !== 0 ? (
                              names?.map((name, i) => (
                                <MenuItem
                                  key={i}
                                  value={name?.updatedName?.updated_name}
                                >
                                  {name?.updatedName?.updated_name}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem
                                className={classes["no_items_data"]}
                              >{`No client list found`}</MenuItem>
                            )}
                          </Select>
                          {errors.user && touched.user ? (
                            <p className={classes["form_error"]}>
                              {errors.user}
                            </p>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col lg="6" sm="6" className="mt-3 mt-lg-0">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Domain Name:
                          <Tooltip
                            title="Insert your custom domain or sub-domain here. Do not include http or https. For example: www.exampledomain.com"
                            placement="top-start"
                          >
                            <button
                              type="button"
                              className={`${classes["information_icon"]}`}
                            >
                              <i className={`fa-solid fa-circle-info`}></i>
                            </button>
                          </Tooltip>
                        </Label>
                        <Input
                          className="from-control bg-white"
                          name="domain_name"
                          type="text"
                          value={values.domain_name}
                          placeholder="Enter Your Domain"
                          onChange={handleChange}
                        />
                        {errors.domain_name && touched.domain_name ? (
                          <p className={classes["form_error"]}>
                            {errors.domain_name}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg="6" sm="6" className="mt-3 mt-lg-0">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Product Name:
                        </Label>
                        <Select
                          className={`w-100 bg-white ${classes["app_name_input"]}`}
                          name="app_name"
                          value={values.app_name}
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value="">Choose a Product</MenuItem>
                          <MenuItem value="coupons">Coupons</MenuItem>
                          <MenuItem value="yoga">Yoga</MenuItem>
                          <MenuItem value="workout">Workout</MenuItem>
                        </Select>
                        {errors.app_name && touched.app_name ? (
                          <p className={classes["form_error"]}>
                            {errors.app_name}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <CardFooter className="btn-set d-flex justify-content-end">
                    <Button color="info" type="submit">
                      Create
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Form>
          )
        ) : (
          <></>
        )}

        <>
          {" "}
          <Row>
            <Col>
              {dataCount !== 0 && (
                <Card className="rounded-2 mb-2">
                  <CardBody className="py-1 pagination-holder">
                    <TablePagination
                      className={classes["pagination_style"]}
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      backIconButtonProps={{
                        style: {
                          color: Number(currentPage) === 1 ? "grey" : "black",
                        },
                      }}
                      nextIconButtonProps={{
                        style: {
                          color:
                            Number(currentPage) === totalPage
                              ? "grey"
                              : "black",
                        },
                      }}
                      component="div"
                      count={dataCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="rounded-2 my-3">
                <CardBody>
                  <div
                    className={`${classes["table-responsive"]} table-full-width`}
                    ref={tableRef}
                  >
                    <Table className="text-center mb-0">
                      <tbody>
                        <tr>
                          <th className="darkText labelText">Sl. No.</th>
                          {rolePermissions?.includes("list") && (
                            <th className="darkText labelText">Client Id</th>
                          )}
                          <th className="darkText labelText">Domain Name</th>
                          <th className="darkText labelText">App Name</th>
                          {/* {userData?.data?.roles[0] !== "client" && (
                          <th className="darkText labelText">Client Type</th>
                        )} */}
                          <th className="darkText labelText">Added On</th>
                          <th className="darkText labelText">Instruction</th>
                          <th className="darkText labelText">Status</th>
                        </tr>
                        {loadingList ? (
                          DomainListLoader()
                        ) : domainDetailsList?.length !== 0 ? (
                          domainDetailsList?.map((domain, i) => {
                            return (
                              <tr key={i}>
                                <td className="textColor">{i + 1}</td>
                                {rolePermissions?.includes("list") && (
                                  <td>{domain?.clientId}</td>
                                )}
                                <td className="textColor">
                                  {domain?.domain_name}{" "}
                                  <a
                                    href={`https://${domain?.domain_name}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none text-dark"
                                  >
                                    <img
                                      src={require("../../assets/img/redirect-icon-new.png")}
                                      alt="redirect_image"
                                    />
                                  </a>
                                </td>
                                <td className="textColor">
                                  {domain?.app_name
                                    .substring(0, 1)
                                    ?.toUpperCase() + domain?.app_name.slice(1)}
                                </td>
                                {/* {userData?.data?.roles[0] !== "client" && (
                                <td>{domain?.client_type}</td>
                              )} */}
                                <td className="textColor">
                                  {handleDateFormat(domain?.createdAt)}
                                </td>
                                <td
                                  className="textColor"
                                  onClick={() => setShowDialog(true)}
                                >
                                  <i
                                    className={`fa-solid fa-circle-info ${classes["information_icon"]}`}
                                  ></i>
                                </td>
                                {domain?.status !== 3 ? (
                                  <td
                                    className={
                                      domain?.status === 2
                                        ? `${classes["dns_added_color"]}`
                                        : `${classes["pending_color"]}`
                                    }
                                  >
                                    {domain?.status === 2
                                      ? "DNS added"
                                      : "Pending"}
                                  </td>
                                ) : (
                                  <td className={classes["active_color"]}>
                                    Active
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan={12}
                              className={classes["no_records_title"]}
                            >
                              No records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {dataCount !== 0 && (
                <Card className="rounded-2 mb-2">
                  <CardBody className="py-1 pagination-holder">
                    <TablePagination
                      className={classes["pagination_style"]}
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      backIconButtonProps={{
                        style: {
                          color: Number(currentPage) === 1 ? "grey" : "black",
                        },
                      }}
                      nextIconButtonProps={{
                        style: {
                          color:
                            Number(currentPage) === totalPage
                              ? "grey"
                              : "black",
                        },
                      }}
                      component="div"
                      count={dataCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </>
      </div>
      <Dialog
        open={showDialog}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#EEEFEF",
            width: "600px",
          },
        }}
      >
        <DialogTitle className={`darkText ${classes["view_icon_cross"]}`}>
          Please follow these steps:
          <i
            onClick={handleClose}
            className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
          ></i>
        </DialogTitle>
        <DialogContent dividers>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps?.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <br />

          <Card className="customColor6 rounded-2">
            {stepItems.map((item, index) => {
              return (
                activeStep === index && (
                  <React.Fragment key={index}>
                    {item.src !== "" && (
                      <CardMedia
                        component="img"
                        height="250"
                        className={classes["image_style"]}
                        image={item?.src}
                        alt={item?.altText}
                      />
                    )}

                    <CardContent>
                      {item?.caption}
                      {index === 0 && (
                        <FormControl
                          className={`customColor4 rounded-2 w-100 p-2 pb-4 ${classes["radio_group_content"]}`}
                        >
                          <Label className="pl-2">
                            Please choose domain/sub-domain:
                          </Label>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            className={`customColor6 rounded-2 pl-3 w-100 ${classes["radio_group_label"]} d-flex flex-row align-items-center`}
                            value={stepperValue}
                            onChange={(e) => handleRadioButtonValues(e)}
                          >
                            <FormControlLabel
                              value="domain"
                              control={<Radio />}
                              label="Domain"
                            />
                            <FormControlLabel
                              value="sub-domain"
                              control={<Radio />}
                              label="Sub-domain"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    </CardContent>
                  </React.Fragment>
                )
              );
            })}
            <CardFooter className="d-flex justify-content-between">
              <Button
                color="info"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              {activeStep === steps.length - 1 ? (
                <Button color="info" onClick={handleFinish}>
                  Finish
                </Button>
              ) : (
                <Button
                  color="info"
                  disabled={stepperValue === ""}
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </CardFooter>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DomainLabelling;
