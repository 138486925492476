import * as Yup from "yup";

export const MemberFormSchema = Yup.object({
  client_type: Yup.string().required("Member role can not be blank"),
  client_first_name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Member first name can not be blank"),

  client_last_name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Member last name can not be blank"),
  client_email: Yup.string()
    .email("Invalid Email")
    .required("Member email can not be blank"),
  client_phone_number: Yup.string()
  .min(10, "Phone no is invalid")
  .required("Member phone number can not be blank"),
  client_status: Yup.string().required("Member status can not be blank"),
});