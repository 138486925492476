import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import classes from "../../assets/css/SubUser.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Skeleton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import Select from "@mui/material/Select";
import MaskedInput from "react-text-mask";
import { useFormik } from "formik";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { AxiosAPiPost } from "AxiosAPi";
import { FaEdit, FaKey } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import SubUserEditForm from "./SubUserEditForm";
import SubUserDeleteForm from "./SubUserDeleteForm";
import { SubUserSchema } from "utils/schemas/SubUserSchema";
import SubUserPasswordUpdateForm from "./SubUserPasswordUpdateForm";
import ReferenceDataContext from "AvatarContext";
import { AxiosAPiGet } from "AxiosAPi";
import { SuperAdminSubUserSchema } from "utils/schemas/SubUserSchema";
// import SubUserEditForm from "./SubUserEditForm";
// Reducer function for edit,login update,delete dialog //
const editSubUserReducer = (state, action) => {
  if (action.type === "OPEN_EDIT_DIALOG") {
    return {
      editSubUserData: action.editData,
      isOpenEdit: action.editData?.id !== "",
    };
  }
  return { editSubUserData: {}, isOpenEdit: false };
};
const passwordUpdateReducer = (state, action) => {
  if (action.type === "OPEN_PASS_UPDATE_DIALOG") {
    return {
      passwordUpdateId: action.updateId,
      isOpenUpdatePassword: action.updateId !== "",
    };
  }
  return { passwordUpdateId: "", isOpenUpdatePassword: false };
};
const deleteSubUserReducer = (state, action) => {
  if (action.type === "OPEN_DELETE_DIALOG") {
    return {
      deleteSubUserId: action.deleteData,
      isOpenDelete: action.deleteData !== "",
    };
  }
  return { deleteSubUserId: "", isOpenDelete: false };
};
// Reducer function for edit,login update,delete dialog //
function SubUser() {
  // Use to show table div when load //
  const tableRef = useRef(null);
  // Use to show table div when load //
  // Use to redirect //
  const history = useHistory();
  // Use to redirect //
  // permissions list based on different roles //
  const { rolePermissions } = useContext(ReferenceDataContext);
  // permissions list based on different roles //
  // user object //
  const userData = JSON.parse(localStorage.getItem("userData"));
  // user object //
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // inputs for loading state //
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  // inputs for loading state //
  // input for storing sub user list //
  const [subUserList, setSubUserList] = useState([]);
  // input for storing sub user list //
  // Pagination page input //
  const [page, setPage] = useState(0);
  // Pagination page input //
  // Pagination number of rows per page //
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // Pagination number of rows per page //
  // Input for capturing total customer data count //
  const [dataCount, setDataCount] = useState(0);
  // Input for capturing total customer data count //
  // input for storing current page value //
  const [currentPage, setCurrentPage] = useState(0);
  // input for storing current page value //
  // input for storing last page value //
  const [lastPage, setLastPage] = useState(0);
  // input for storing last page value //
  // input for storing individual sub-user permission data //
  const [editedPermission, setEditedPermission] = useState({});
  // input for storing individual sub-user permission data //
  // input for storing selected client id from client dropdown list //
  const [selectedClientId, setSelectedClientId] = useState("");
  // input for storing selected client id from client dropdown list //
  // input for storing the client list i.e coming from API //
  const [clientList, setClientList] = useState([]);
  // input for storing the client list i.e coming from API //
  // input for storing search value //
  const [searchValue, setSearchValue] = useState("");
  // input for storing search value //
  // inputs for edit,login update, delete dialog state handle //
  const [editSubUserState, dispatchEditSubUserState] = useReducer(
    editSubUserReducer,
    {
      editSubUserData: {},
      isOpenEdit: false,
    }
  );
  const [deleteSubUserState, dispatchDeleteSubUserData] = useReducer(
    deleteSubUserReducer,
    {
      deleteSubUserId: "",
      isOpenDelete: false,
    }
  );
  const [passwordUpdateState, dispatchPasswordUpdate] = useReducer(
    passwordUpdateReducer,
    {
      passwordUpdateId: "",
      isOpenUpdatePassword: false,
    }
  );
  // inputs for edit,login update, delete dialog state handle //
  // inputs for storing default sidebar permissions value //
  const [sidebarOptions, setSidebarOptions] = useState({
    dashboard: {
      key: "dashboard",
      sidebar_dashboard: false,
    },
    customers: {
      key: "customers",
      sidebar_customers: false,
      view_customers: false,
    },

    payment: {
      key: "payment",
      sidebar_payment: false,
      update_add_payment_details: false,
    },
    domainLabelling: {
      key: "domainLabelling",
      sidebar_domain: false,
      add_domain: false,
    },
    support: {
      key: "support",
      sidebar_support: false,
    },
  });
  // inputs for storing default sidebar permissions value //
  // inputs for storing initial values for creating new sub-user//
  const initialValues = {
    clientId:
      userData?.data?.roles[0] === "client" ? userData?.data?.clientId : "",
    clientType: "",
    client_status: "Active",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    permissions: [],
    permission_string: "",
  };
  // inputs for storing initial values for creating new sub-user//

  // form submission and error handling of creating sub-user //
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    // enableReinitialize: true,
    validationSchema: rolePermissions?.includes("clientList")
      ? SuperAdminSubUserSchema
      : SubUserSchema,
    onSubmit: async (values, action) => {
      let sidebar_filter = Object.values(sidebarOptions).map((val) => {
        for (var i in val) {
          if (val[i] === false) {
            delete val[i];
          }
        }
        return val;
      });
      let sidebar_filter_key = sidebar_filter.map((val) => Object.keys(val));
      let arr_split = sidebar_filter_key.map((val) => val.splice(1));
      let arr_structure = arr_split.map((val, index) => {
        return {
          [sidebar_filter[index]["key"]]: val,
        };
      });
      let filtered_arr_structure = arr_structure.filter(
        (val, index) => val[sidebar_filter[index]["key"]]?.length !== 0
      );
      const data = {
        clientId: values.clientId,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email.trim(),
        phone: values.phone,
        password: values.password,
        permissions: filtered_arr_structure,
      };

      setLoading(true);
      await axios
        .post(`${process.env.REACT_APP_AXIOS_BASE_URL}/subusers/save`, data, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setLoading(false);
            AlertShow("Sub-user created successfully", "success");
            setFieldValue(
              "clientId",
              userData?.data?.roles[0] === "client"
                ? userData?.data?.clientId
                : ""
            );
            tableRef?.current?.scrollIntoView();
            fetchSubUserList();

            setSidebarOptions({
              dashboard: {
                key: "dashboard",
                sidebar_dashboard: false,
              },
              customers: {
                key: "customers",
                sidebar_customers: false,
                view_customers: false,
              },

              payment: {
                key: "payment",
                sidebar_payment: false,
                update_add_payment_details: false,
              },
              domainLabelling: {
                key: "domainLabelling",
                sidebar_domain: false,
                add_domain: false,
              },
              support: {
                key: "support",
                sidebar_support: false,
              },
            });
            setSelectedClientId("");
            setSearchValue("");
            setOpenDropdown(false);
            action.resetForm();
          }
        })
        .catch((err) => {
          setLoading(false);
          if (Object.keys(err.response.data.errors).length !== 0) {
            if (err.response.data.errors.email) {
              AlertShow(err.response.data.errors.email.message, "warning");
            }
          }
          if (err.response.data.message === "Invalid Token") {
            localStorage.clear();
            history.push("/login");
          }
        });
    },
  });
  // form submission and error handling of creating sub-user //
  // Generalize function for notification alert //
  const AlertShow = (message, type) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // Generalize function for notification alert //
  // fetch sub-user list from api //
  const fetchSubUserList = useCallback(async () => {
    let url_subUserList = rolePermissions.includes("clientList")
      ? `${
          process.env.REACT_APP_AXIOS_BASE_URL
        }/subusers/list?where_clause={"where_fields":["roles","deleted"],"where_values":["sub_client",false]}&page=${
          page + 1
        }&show=${rowsPerPage}`
      : `${
          process.env.REACT_APP_AXIOS_BASE_URL
        }/subusers/list?where_clause={"where_fields":["roles","parent_id","deleted"],"where_values":["sub_client","${
          userData?.data.clientId
        }",false]}&page=${page + 1}&show=${rowsPerPage}`;

    setLoadingList(true);
    AxiosAPiPost(url_subUserList, "post", "", (res) => {
      if (res.status === 200) {
        setSubUserList(
          res?.data?.results?.results.data?.map((name) => {
            let updatedName;
            updatedName = name?.hasOwnProperty("company_name")
              ? name?.company_name === "" ||
                name?.company_name === undefined ||
                name?.company_name === null
                ? name?.name === "" ||
                  name?.name === undefined ||
                  name?.name === null
                  ? {
                      ...name,
                      updated_name_with_id: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                      updated_name: `${name?.first_name} ${name?.last_name}`,
                    }
                  : {
                      ...name,
                      updated_name_with_id: `${name?.name} (${name?.clientId})`,
                      updated_name: `${name?.name}`,
                    }
                : {
                    ...name,
                    updated_name_with_id: `${name.company_name} (${name?.clientId})`,
                    updated_name: `${name.company_name}`,
                  }
              : name?.name === "" ||
                name?.name === undefined ||
                name?.name === null
              ? {
                  ...name,
                  updated_name_with_id: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                  updated_name: `${name?.first_name} ${name?.last_name}`,
                }
              : {
                  ...name,
                  updated_name_with_id: `${name?.name} (${name?.clientId})`,
                  updated_name: `${name?.name}`,
                };
            return { updatedName };
          })
        );
        setDataCount(res.data?.results?.results?.count);
        setCurrentPage(res.data.results?.results?.current_page);
        setLastPage(res.data.results?.results?.last_page);
        setLoadingList(false);
      }
    });
  }, [userData?.data.clientId, page, rowsPerPage, rolePermissions]);
  useEffect(() => {
    fetchSubUserList();
  }, [fetchSubUserList]);
  // fetch sub-user list from api //
  // fetch client list from api //
  useEffect(() => {
    let url_client = "";
    async function ClientList(url) {
      AxiosAPiGet(
        url,
        "get",
        (res) => {
          setClientList(
            res?.data?.results?.results.data?.map((name) => {
              let updatedName;
              updatedName = name?.hasOwnProperty("company_name")
                ? name?.company_name === "" ||
                  name?.company_name === undefined ||
                  name?.company_name === null
                  ? name?.name === "" ||
                    name?.name === undefined ||
                    name?.name === null
                    ? {
                        ...name,
                        updated_name_with_id: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                        updated_name: `${name?.first_name} ${name?.last_name}`,
                      }
                    : {
                        ...name,
                        updated_name_with_id: `${name?.name} (${name?.clientId})`,
                        updated_name: `${name?.name}`,
                      }
                  : {
                      ...name,
                      updated_name_with_id: `${name.company_name} (${name?.clientId})`,
                      updated_name: `${name.company_name}`,
                    }
                : name?.name === "" ||
                  name?.name === undefined ||
                  name?.name === null
                ? {
                    ...name,
                    updated_name_with_id: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                    updated_name: `${name?.first_name} ${name?.last_name}`,
                  }
                : {
                    ...name,
                    updated_name_with_id: `${name?.name} (${name?.clientId})`,
                    updated_name: `${name?.name}`,
                  };
              return { updatedName };
            })
          );
        },
        history,
        (err) => {
          let options = {};
          options = {
            place: "tr",
            message: err.response.data.message,
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef.current.notificationAlert(options);
        }
      );
    }

    switch (values.clientType) {
      case "":
        setClientList([]);
        break;
      case "all":
        switch (values.client_status) {
          case "":
            setSelectedClientId("");
            setFieldValue("clientId", "");
            url_client = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","is_test_account"],"where_values":["client","false"]}`;
            ClientList(url_client);
            break;

          default:
            setSelectedClientId("");
            setFieldValue("clientId", "");
            url_client = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","is_test_account","status"],"where_values":["client","false","${values.client_status}"]}`;
            ClientList(url_client);
            break;
        }

        break;
      default:
        switch (values.client_status) {
          case "":
            setSelectedClientId("");
            setFieldValue("clientId", "");
            url_client = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","client_type","is_test_account"],"where_values":["client","${values.clientType}","false"]}`;
            ClientList(url_client);
            break;

          default:
            setSelectedClientId("");
            setFieldValue("clientId", "");
            url_client = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","client_type","is_test_account","status"],"where_values":["client","${values.clientType}","false","${values.client_status}"]}`;
            ClientList(url_client);
            break;
        }

        break;
    }
  }, [history, values.clientType, values.client_status, setFieldValue]);
  // fetch client list from api //
  // Function for opening edit dialog and also storing sub-user route permission according to their Client Id //
  const fetchSubUserDetails = (data) => {
    dispatchEditSubUserState({
      type: "OPEN_EDIT_DIALOG",
      editData: data?.updatedName,
    });
    let new_arr = {
      dashboard: {
        key: "dashboard",
        sidebar_dashboard: false,
      },
      customers: {
        key: "customers",
        sidebar_customers: false,
        view_customers: false,
      },

      payment: {
        key: "payment",
        sidebar_payment: false,
        update_add_payment_details: false,
      },
      domainLabelling: {
        key: "domainLabelling",
        sidebar_domain: false,
        add_domain: false,
      },
      support: {
        key: "support",
        sidebar_support: false,
      },
    };
    for (let index = 0; index < data.updatedName.permissions.length; index++) {
      for (
        let k = 0;
        k < Object.values(data.updatedName.permissions[index])[0].length;
        k++
      ) {
        new_arr = {
          ...new_arr,
          [Object.keys(data.updatedName.permissions[index])[0]]: {
            ...new_arr[Object.keys(data.updatedName.permissions[index])[0]],
            [Object.values(data.updatedName.permissions[index])[0][k]]: true,
          },
        };
      }
    }
    setEditedPermission(new_arr);
  };
  // Function for opening edit dialog and also storing sub-user route permission according to their Client Id //
  // function for fetch ID while open delete sub-user dialog //
  const fetchSubUserDetailsDelete = (id) => {
    dispatchDeleteSubUserData({ type: "OPEN_DELETE_DIALOG", deleteData: id });
  };
  // function for fetch ID while open delete sub-user dialog //
  // Function for opening update password sub-user dialog //
  const fetchSubUserPassowordUpdate = (id) => {
    dispatchPasswordUpdate({ type: "OPEN_PASS_UPDATE_DIALOG", updateId: id });
  };
  // Function for opening update password sub-user dialog //
  // Close dialog functionality //
  const closeEditDialog = () => {
    dispatchEditSubUserState({ type: "CLOSE_EDIT_DIALOG" });
  };
  const closeDeleteDialog = () => {
    dispatchDeleteSubUserData({ type: "CLOSE_DELETE_DIALOG" });
  };
  const closeUpdatePasswordDialog = () => {
    dispatchPasswordUpdate({ type: "CLOSE_PASS_UPDATE_DIALOG" });
  };
  // Close dialog functionality //
  // Function for changing app club checkbox //
  const handleChangeDashboard = (e) => {
    setSidebarOptions({
      ...sidebarOptions,
      dashboard: {
        ...sidebarOptions.dashboard,
        [e.target.name]: e.target.checked,
      },
    });
  };
  // Function for changing app club checkbox //
  // Function for changing customer sidebar checkbox //
  const handleChangeCustomersSidebar = (e) => {
    if (!sidebarOptions["customers"]["sidebar_customers"]) {
      setSidebarOptions({
        ...sidebarOptions,
        customers: {
          key: "customers",
          sidebar_customers: e.target.checked,
          view_customers: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        customers: {
          key: "customers",
          sidebar_customers: e.target.checked,
          view_customers:
            e.target.checked === false
              ? false
              : sidebarOptions["customers"]["view_customers"],
        },
      });
    }
  };
  // Function for changing customer sidebar checkbox //
  // Function for changing customer list view button visible/invisible checkbox //
  const handleChangeCustomersView = (e) => {
    if (!sidebarOptions["customers"]["sidebar_customers"]) {
      setSidebarOptions({
        ...sidebarOptions,
        customers: {
          key: "customers",
          sidebar_customers: sidebarOptions["customers"]["sidebar_customers"],
          view_customers: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        customers: {
          key: "customers",
          sidebar_customers: sidebarOptions["customers"]["sidebar_customers"],
          view_customers: e.target.checked,
        },
      });
    }
  };
  // Function for changing customer list view button visible/invisible checkbox //
  // Function for changing payment sidebar checkbox //
  const handleChangePaymentSidebar = (e) => {
    if (!sidebarOptions["payment"]["sidebar_payment"]) {
      setSidebarOptions({
        ...sidebarOptions,
        payment: {
          key: "payment",
          sidebar_payment: e.target.checked,
          update_add_payment_details: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        payment: {
          key: "payment",
          sidebar_payment: e.target.checked,
          update_add_payment_details:
            e.target.checked === false
              ? false
              : sidebarOptions["payment"]["update_add_payment_details"],
        },
      });
    }
  };
  // Function for changing payment sidebar checkbox //
  // Function for changing payment module update button visible/invisible checkbox //
  const handleChangePaymentAdd = (e) => {
    if (!sidebarOptions["payment"]["sidebar_payment"]) {
      setSidebarOptions({
        ...sidebarOptions,
        payment: {
          key: "payment",
          sidebar_payment: sidebarOptions["payment"]["sidebar_payment"],
          update_add_payment_details: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        payment: {
          key: "payment",
          sidebar_payment: sidebarOptions["payment"]["sidebar_payment"],
          update_add_payment_details: e.target.checked,
        },
      });
    }
  };
  // Function for changing payment module update button visible/invisible checkbox //
  // Function for changing web app management sidebar checkbox //
  const handleChangeDomainLabellingSidebar = (e) => {
    if (!sidebarOptions["domainLabelling"]["sidebar_domain"]) {
      setSidebarOptions({
        ...sidebarOptions,
        domainLabelling: {
          key: "domainLabelling",
          sidebar_domain: e.target.checked,
          add_domain: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        domainLabelling: {
          key: "domainLabelling",
          sidebar_domain: e.target.checked,
          add_domain:
            e.target.checked === false
              ? false
              : sidebarOptions["domainLabelling"]["add_domain"],
        },
      });
    }
  };
  // Function for changing web app management sidebar checkbox //
  // Function for changing web app management module creation form visible/invisible checkbox //
  const handleChangeDomainLabellingAdd = (e) => {
    if (!sidebarOptions["domainLabelling"]["sidebar_domain"]) {
      setSidebarOptions({
        ...sidebarOptions,
        domainLabelling: {
          key: "domainLabelling",
          sidebar_domain: sidebarOptions["domainLabelling"]["sidebar_domain"],
          add_domain: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        domainLabelling: {
          key: "domainLabelling",
          sidebar_domain: sidebarOptions["domainLabelling"]["sidebar_domain"],
          add_domain: e.target.checked,
        },
      });
    }
  };
  // Function for changing web app management module creation form visible/invisible checkbox //
  // Function for changing support sidebar checkbox //
  const handleChangeSupport = (e) => {
    setSidebarOptions({
      ...sidebarOptions,
      support: {
        ...sidebarOptions.support,
        [e.target.name]: e.target.checked,
      },
    });
  };
  // Function for changing support sidebar checkbox //
  // Handle page change in pagination //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };
  // Handle page change in pagination //
  //Rows per page in pagination //
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  //Rows per page in pagination //
  // Function when removing selected client name //
  const handleClientNameRemove = () => {
    setFieldValue("clientId", "");
    setSelectedClientId("");
  };
  // Function when removing selected client name //
  // Function for select client name from the list //
  const handleClientList = (k, client) => {
    setSelectedClientId(client.updated_name_with_id);
    setFieldValue("clientId", client?.clientId);
    setSearchValue("");
    // setOpenDropdown(false)
  };
  // Function for select client name from the list //
  // Function for handling special character in search field //
  const handleSpecialCharacters = (e) => {
    var keycode = e.which || e.keyCode;

    if (
      (keycode > 8 && keycode < 48 && keycode !== 32) ||
      (keycode > 57 && keycode < 65) ||
      (keycode > 90 && keycode < 97) ||
      keycode > 122
    ) {
      e.preventDefault();
    }
  };
  // Function for handling special character in search field //
  // Skeleton loader for sub-user list //
  const SubUserListLoader = () => {
    return [...Array(rowsPerPage)].map((val, index) => (
      <tr key={index}>
        {rolePermissions?.includes("clientList") && (
          <>
            <td className="textColor">
              <Skeleton animation="wave" variant="text" />
            </td>
            <td className="textColor">
              <Skeleton animation="wave" variant="text" />
            </td>
          </>
        )}
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
      </tr>
    ));
  };
  // Skeleton loader for sub-user list //
  // useEffect for error validation in sub-user permission section //
  useEffect(() => {
    let permission_true_arr = [];
    for (let index = 0; index < Object.values(sidebarOptions).length; index++) {
      if (Object.values(Object.values(sidebarOptions)[index])?.includes(true)) {
        permission_true_arr = [...permission_true_arr, "Value True"];
      }
    }
    if (permission_true_arr.length !== 0) {
      setFieldValue("permission_string", "Permission Added");
    } else {
      setFieldValue("permission_string", "");
    }
  }, [sidebarOptions, setFieldValue]);
  // useEffect for error validation in sub-user permission section //
  // useEffect for focusing search input while open client list dropdown //
  useEffect(() => {
    if (openDropdown) {
      document.getElementById("client_name_search")?.focus();
    }
  }, [openDropdown]);
  // useEffect for focusing search input while open client list dropdown //
  // useEffect for closing client list dropdown while clicking on any section on sub-user module //
  useEffect(() => {
    document
      .getElementById("subUser_content")
      ?.addEventListener("click", () => {
        setOpenDropdown(false);
      });
  }, []);
  // useEffect for closing client list dropdown while clicking on any section on sub-user module //
  return (
    <div
      className="content"
      id={rolePermissions?.includes("clientList") ? "subUser_content" : ""}
    >
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="customColor4 p-3 p-sm-4 rounded-2">
        <Accordion
          className={`px-sm-3 my-0 ${classes["accordion_border"]}`}
          sx={{
            backgroundColor: "#ced0d1",
          }}
        >
          <AccordionSummary
            expandIcon={
              <i
                className={`fa-solid fa-chevron-down textColor display-4 ${classes["accordion_exapnd"]}`}
              ></i>
            }
          >
            <Typography className="select-title textColor">
              Create Sub User
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Form onSubmit={handleSubmit}>
              <Card className="trnstprnt-bg commonForm mb-0">
                <CardBody>
                  {rolePermissions?.includes("clientList") && (
                    <>
                      <Row>
                        <Col lg="6" sm="6">
                          <FormGroup>
                            <label className="darkText labelText">
                              Client Type
                            </label>
                            <Select
                              name="clientType"
                              className={`w-100 bg-white ${classes["client_status_input"]}`}
                              value={values.clientType}
                              onChange={handleChange}
                              displayEmpty
                            >
                              <MenuItem value="">Choose client type</MenuItem>
                              <MenuItem value="all">All</MenuItem>
                              <MenuItem value="api">API</MenuItem>
                              <MenuItem value="shopify">Shopify</MenuItem>
                            </Select>
                            {errors.clientType && touched.clientType ? (
                              <p className={classes["form_error"]}>
                                {errors.clientType}
                              </p>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6" sm="12">
                          <FormGroup>
                            <Label className="darkText labelText">
                              Client Status
                            </Label>
                            <Select
                              name="client_status"
                              className={`w-100 bg-white ${classes["client_status_input"]}`}
                              value={values.client_status}
                              onChange={handleChange}
                              displayEmpty
                            >
                              <MenuItem value="Active">Active</MenuItem>
                              <MenuItem value="Deactivated">
                                Deactivated
                              </MenuItem>
                              <MenuItem value="Hold">Hold</MenuItem>
                              <MenuItem value="Pause">Pause</MenuItem>
                              <MenuItem value="">All Clients</MenuItem>
                            </Select>
                          </FormGroup>
                        </Col>
                      </Row>

                      {values.clientType !== "" && (
                        <Row>
                          <Col lg="12" sm="6">
                            <FormGroup>
                              <Label className="darkText labelText">
                                Choose Client List:
                              </Label>

                              <div className={classes["input_container"]}>
                                <Input
                                  type="text"
                                  id="client_name_input"
                                  className={`${classes["client_name_style"]} bg-white`}
                                  name="clientId"
                                  autoComplete="off"
                                  placeholder={
                                    selectedClientId === ""
                                      ? "Search Client Name"
                                      : ""
                                  }
                                  onClick={() => setOpenDropdown(!openDropdown)}
                                  // onChange={(e) => setClientName(e.target.value)}
                                  onKeyDown={(e) => e.preventDefault()}
                                  // value={clientName}
                                />
                                {selectedClientId !== "" && (
                                  <button
                                    className={classes["main_button_div"]}
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <p className={classes["client_name_cross"]}>
                                      {selectedClientId}
                                    </p>
                                    <p
                                      onClick={handleClientNameRemove}
                                      className={
                                        classes["input_cross_container"]
                                      }
                                    >
                                      <i className="fa-solid fa-xmark"></i>
                                    </p>
                                  </button>
                                )}
                              </div>

                              <div
                                className={
                                  openDropdown
                                    ? `${classes["client_name_list_div"]} ${classes["client_name_list_show"]}`
                                    : `${classes["client_name_list_hide"]}`
                                }
                              >
                                <Input
                                  className={`bg-white ${classes["search_input_style"]}`}
                                  id="client_name_search"
                                  type="search"
                                  placeholder="Enter your search"
                                  autoComplete="off"
                                  value={searchValue}
                                  pattern="^\w\s\(\d\)$"
                                  onChange={(e) =>
                                    setSearchValue(e.target.value)
                                  }
                                  onClick={() => setOpenDropdown(true)}
                                  onKeyDown={(e) => handleSpecialCharacters(e)}
                                />
                                {clientList?.length !== 0 ? (
                                  clientList
                                    ?.filter((data) =>
                                      data?.updatedName?.updated_name_with_id
                                        ?.toLowerCase()
                                        ?.includes(searchValue?.toLowerCase())
                                    )
                                    ?.map(
                                      (client, k) =>
                                        client.updatedName?.clientId && (
                                          <p
                                            onClick={() =>
                                              handleClientList(
                                                k,
                                                client?.updatedName
                                              )
                                            }
                                            key={k}
                                            className={
                                              selectedClientId ===
                                              client.updatedName
                                                ?.updated_name_with_id
                                                ? `${classes["client_name_selected"]} ${classes["client_name_list"]}`
                                                : `${classes["client_name_not_selected"]} ${classes["client_name_list"]}`
                                            }
                                            id={`client_name${k}`}
                                          >
                                            {
                                              client?.updatedName
                                                ?.updated_name_with_id
                                            }
                                          </p>
                                        )
                                    )
                                ) : (
                                  <p className={classes["no_records_style"]}>
                                    No records found
                                  </p>
                                )}
                              </div>
                              {errors.clientId && touched.clientId ? (
                                <p className={classes["form_error"]}>
                                  {errors.clientId}
                                </p>
                              ) : null}
                              {/* <Input
                      className="from-control bg-white"
                      name="clientId"
                      type="text"
                      readOnly
                      placeholder="Enter client Id"
                      value="Pupsocks"
                    /> */}
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  <Row>
                    <Col lg="6" sm="6">
                      <FormGroup>
                        <label className="darkText">First Name *</label>
                        <Input
                          name="first_name"
                          placeholder="Enter your first name"
                          type="text"
                          className="bg-white"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p className={classes["form_error"]}>
                            {errors.first_name}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg="6" sm="6">
                      <label className="darkText">Last Name *</label>
                      <Input
                        name="last_name"
                        placeholder="Enter your last name"
                        type="text"
                        className="bg-white"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                      {errors.last_name && touched.last_name ? (
                        <p className={classes["form_error"]}>
                          {errors.last_name}
                        </p>
                      ) : null}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" sm="6">
                      <FormGroup>
                        <label className="darkText">Email Address *</label>
                        <Input
                          name="email"
                          placeholder="Enter your email address"
                          type="email"
                          className="bg-white"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <p className={classes["form_error"]}>
                            {errors.email}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" sm="6">
                      <FormGroup>
                        <label className="darkText labelText">
                          Password *{" "}
                        </label>
                        <Input
                          id="password"
                          className="bg-white"
                          type="password"
                          //   type={passwordShown ? "text" : "password"}
                          placeholder="Enter your password"
                          value={values.password}
                          onChange={handleChange}
                        />
                        {errors.password && touched.password ? (
                          <p className={classes["form_error"]}>
                            {errors.password}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg="6" sm="6">
                      <FormGroup className={`has-label`}>
                        <label className="darkText">Phone Number *</label>
                        <MaskedInput
                          mask={[
                            "(",
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          className="form-control bg-white"
                          placeholder="Enter a phone number"
                          name="phone"
                          guide={true}
                          id="my-input-id"
                          value={values.phone}
                          onChange={(e) => {
                            setFieldValue(
                              "phone",
                              e.target.value.replace(/[^A-Z0-9]/gi, "")
                            );
                          }}
                          onBlur={handleBlur}
                        />
                        {errors.phone && touched.phone ? (
                          <p className={classes["form_error"]}>
                            {errors.phone}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <label className="darkText">
                    Subuser permissions <span className="text-danger">*</span>
                  </label>
                  <div className="customColor4 p-3 p-sm-4 rounded-2">
                    <Row>
                      <Col lg="6" sm="6">
                        <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                          <FormControlLabel
                            label="Customers"
                            className="text-dark"
                            control={
                              <Checkbox
                                checked={
                                  sidebarOptions["customers"][
                                    "sidebar_customers"
                                  ]
                                }
                                onChange={(e) =>
                                  handleChangeCustomersSidebar(e)
                                }
                              />
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              ml: 3,
                            }}
                          >
                            {/* {userData?.data?.roles[0] !== "client" && (
                              <FormControlLabel
                                label="Sidebar customers"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    name="sidebar_customers"
                                    checked={
                                      sidebarOptions["customers"][
                                        "sidebar_customers"
                                      ]
                                    }
                                    onChange={(e) =>
                                      handleChangeCustomersSidebar(e)
                                    }
                                  />
                                }
                              />
                            )} */}

                            <FormControlLabel
                              label="View customers"
                              className="text-dark"
                              control={
                                <Checkbox
                                  id="view_customers"
                                  name="view_customers"
                                  disabled={
                                    !sidebarOptions["customers"][
                                      "sidebar_customers"
                                    ]
                                  }
                                  checked={
                                    sidebarOptions["customers"][
                                      "view_customers"
                                    ] &&
                                    sidebarOptions["customers"][
                                      "sidebar_customers"
                                    ]
                                  }
                                  onChange={(e) => handleChangeCustomersView(e)}
                                />
                              }
                            />
                          </Box>
                        </div>
                      </Col>
                      <Col lg="6" sm="6">
                        <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                          <FormControlLabel
                            label="Payment"
                            className="text-dark"
                            control={
                              <Checkbox
                                checked={
                                  sidebarOptions["payment"]["sidebar_payment"]
                                }
                                onChange={(e) => handleChangePaymentSidebar(e)}
                              />
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              ml: 3,
                            }}
                          >
                            {/* {userData?.data?.roles[0] !== "client" && (
                              <FormControlLabel
                                label="Sidebar payment"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    name="sidebar_payment"
                                    checked={
                                      sidebarOptions["payment"][
                                        "sidebar_payment"
                                      ]
                                    }
                                    onChange={(e) =>
                                      handleChangePaymentSidebar(e)
                                    }
                                  />
                                }
                              />
                            )} */}

                            <FormControlLabel
                              label="Add/Update payment details"
                              className="text-dark"
                              control={
                                <Checkbox
                                  id="update_add_payment_details"
                                  name="update_add_payment_details"
                                  disabled={
                                    !sidebarOptions["payment"][
                                      "sidebar_payment"
                                    ]
                                  }
                                  checked={
                                    sidebarOptions["payment"][
                                      "sidebar_payment"
                                    ] &&
                                    sidebarOptions["payment"][
                                      "update_add_payment_details"
                                    ]
                                  }
                                  onChange={(e) => handleChangePaymentAdd(e)}
                                />
                              }
                            />
                          </Box>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col lg="6" sm="6">
                        <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                          <FormControlLabel
                            label="Support"
                            className="text-dark"
                            control={
                              <Checkbox
                                name="sidebar_support"
                                checked={
                                  sidebarOptions["support"]["sidebar_support"]
                                }
                                onChange={(e) => handleChangeSupport(e)}
                              />
                            }
                          />
                        </div>
                      </Col>
                      <Col lg="6" sm="6">
                        <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                          <FormControlLabel
                            label="App Club"
                            className="text-dark"
                            control={
                              <Checkbox
                                name="sidebar_dashboard"
                                checked={
                                  sidebarOptions["dashboard"][
                                    "sidebar_dashboard"
                                  ]
                                }
                                onChange={(e) => handleChangeDashboard(e)}
                              />
                            }
                          />
                          {/* <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              ml: 3,
                            }}
                          >
                            {userData?.data?.roles[0] !== "client" && (
                              <FormControlLabel
                                label="Sidebar dashboard"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    name="sidebar_dashboard"
                                    checked={
                                      sidebarOptions["dashboard"][
                                        "sidebar_dashboard"
                                      ]
                                    }
                                    onChange={(e) => handleChangeDashboard(e)}
                                  />
                                }
                              />
                            )}
                          </Box> */}

                          {/* <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              ml: 3,
                            }}
                          >
                            {userData?.data?.roles[0] !== "client" && (
                              <FormControlLabel
                                label="Sidebar support"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    name="sidebar_support"
                                    checked={
                                      sidebarOptions["support"][
                                        "sidebar_support"
                                      ]
                                    }
                                    onChange={(e) => handleChangeSupport(e)}
                                  />
                                }
                              />
                            )}
                          </Box> */}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-4" lg="6" sm="6">
                        <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                          <FormControlLabel
                            label="Domain Labelling"
                            className="text-dark"
                            control={
                              <Checkbox
                                checked={
                                  sidebarOptions["domainLabelling"][
                                    "sidebar_domain"
                                  ]
                                }
                                onChange={(e) =>
                                  handleChangeDomainLabellingSidebar(e)
                                }
                              />
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              ml: 3,
                            }}
                          >
                            <FormControlLabel
                              label="Add domain"
                              className="text-dark"
                              control={
                                <Checkbox
                                  id="add_domain"
                                  name="add_domain"
                                  disabled={
                                    !sidebarOptions["domainLabelling"][
                                      "sidebar_domain"
                                    ]
                                  }
                                  checked={
                                    sidebarOptions["domainLabelling"][
                                      "add_domain"
                                    ] &&
                                    sidebarOptions["domainLabelling"][
                                      "sidebar_domain"
                                    ]
                                  }
                                  onChange={(e) =>
                                    handleChangeDomainLabellingAdd(e)
                                  }
                                />
                              }
                            />
                          </Box>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {errors.permission_string && touched.permission_string ? (
                    <p className={classes["form_error"]}>
                      {errors.permission_string}
                    </p>
                  ) : null}
                  <br />
                  <Row>
                    <Col
                      lg="12"
                      className={`btn-set ${classes["filter_buttons"]} d-flex justify-content-end`}
                    >
                      <Button color="info" type="submit">
                        {loading ? "Processing..." : "Create"}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </AccordionDetails>
        </Accordion>

        <div className="mt-4">
          <Row>
            {dataCount !== 0 && (
              <Col>
                <Card className="rounded-2 mb-2">
                  <CardBody className="py-1 pagination-holder">
                    <TablePagination
                      className={classes["pagination_style"]}
                      component="div"
                      count={dataCount}
                      backIconButtonProps={{
                        style: {
                          color: Number(currentPage) === 1 ? "grey" : "black",
                        },
                      }}
                      nextIconButtonProps={{
                        style: {
                          color:
                            Number(currentPage) === lastPage ? "grey" : "black",
                        },
                      }}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Row>
            <Col md="12">
              <Card className="rounded-2 my-3">
                <CardBody>
                  <div
                    className={`${classes["table-responsive"]} table-full-width `}
                    ref={tableRef}
                  >
                    <Table className="text-center mb-0 ">
                      <tbody>
                        <tr>
                          {rolePermissions?.includes("clientList") && (
                            <>
                              <th className="darkText labelText">ClientId</th>
                              <th className="darkText labelText">
                                Client Name
                              </th>
                            </>
                          )}
                          <th className="darkText labelText">Name</th>

                          <th className="darkText labelText">Email</th>
                          <th className="darkText labelText">Phone</th>
                          <th className="darkText labelText">Status</th>
                          <th className="darkText labelText">Action</th>
                        </tr>
                        {loadingList ? (
                          SubUserListLoader()
                        ) : subUserList?.length !== 0 ? (
                          subUserList.map((data, i) => (
                            <tr key={i}>
                              {rolePermissions?.includes("clientList") && (
                                <>
                                  <td className="textColor">
                                    {data.updatedName.clientId}
                                  </td>
                                  <td>{data.updatedName.updated_name}</td>
                                </>
                              )}

                              <td className="textColor">
                                {data?.updatedName.name}
                              </td>

                              <td className="textColor">
                                {data?.updatedName.email}
                              </td>
                              <td className="textColor">
                                {data?.updatedName.phone}
                              </td>
                              <td className="textColor">
                                {data?.updatedName.status}
                              </td>
                              <td className="">
                                <p className={classes["icon_alignment"]}>
                                  <Tooltip title="Edit" placement="top">
                                    <button
                                      type="button"
                                      onClick={() => fetchSubUserDetails(data)}
                                      className={`${classes["edit_icon"]}`}
                                    >
                                      <FaEdit />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title="Delete" placement="top">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        fetchSubUserDetailsDelete(
                                          data.updatedName.id
                                        )
                                      }
                                      className={`${classes["edit_icon"]}`}
                                    >
                                      <MdDelete />
                                    </button>
                                  </Tooltip>
                                  <Tooltip
                                    title="Update password"
                                    placement="top"
                                  >
                                    <button
                                      type="button"
                                      onClick={() =>
                                        fetchSubUserPassowordUpdate(
                                          data.updatedName.id
                                        )
                                      }
                                      className={`${classes["edit_icon"]}`}
                                    >
                                      <FaKey />
                                    </button>
                                  </Tooltip>
                                </p>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={12}
                              className={classes["no_records_title"]}
                            >
                              No records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {dataCount !== 0 && (
              <Col>
                <Card className="rounded-2 mb-2">
                  <CardBody className="py-1 pagination-holder">
                    <TablePagination
                      className={classes["pagination_style"]}
                      component="div"
                      count={dataCount}
                      backIconButtonProps={{
                        style: {
                          color: Number(currentPage) === 1 ? "grey" : "black",
                        },
                      }}
                      nextIconButtonProps={{
                        style: {
                          color:
                            Number(currentPage) === lastPage ? "grey" : "black",
                        },
                      }}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      </div>
      {editSubUserState.isOpenEdit && (
        <SubUserEditForm
          onAlertShow={AlertShow}
          onCloseEditDialog={closeEditDialog}
          getSubUsersList={fetchSubUserList}
          openEdit={editSubUserState.isOpenEdit}
          editData={editSubUserState.editSubUserData}
          editedPermission={editedPermission}
        />
      )}
      {deleteSubUserState.isOpenDelete && (
        <SubUserDeleteForm
          onAlertShow={AlertShow}
          onCloseDeleteDialog={closeDeleteDialog}
          getSubUsersList={fetchSubUserList}
          openDelete={deleteSubUserState.isOpenDelete}
          deleteId={deleteSubUserState.deleteSubUserId}
        />
      )}
      {passwordUpdateState.isOpenUpdatePassword && (
        <SubUserPasswordUpdateForm
          onAlertShow={AlertShow}
          onCloseUpdatePasswordDialog={closeUpdatePasswordDialog}
          getSubUsersList={fetchSubUserList}
          openPasswordUpdate={passwordUpdateState.isOpenUpdatePassword}
          updateId={passwordUpdateState.passwordUpdateId}
        />
      )}
    </div>
  );
}

export default SubUser;
