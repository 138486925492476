import * as Yup from "yup";

export const UpdateProfileSchema = Yup.object({
  first_name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your first name"),

  last_name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Please enter your last name"),
  email: Yup.string()
    .email("Invalid Email")
    .required("Please enter your email"),
  phone: Yup.string()
    .min(10, "Phone no is invalid")
    .required("Please enter your phone number"),
});
