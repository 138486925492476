import axios from "axios";

export const axiosCreate = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
});

axiosCreate.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token !== null) {
    config.headers["token"] = token;
    config.headers["Content-Type"] = "application/json";
  }
  return config;
});
