import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import classes from "../../assets/css/SubUserEditForm.module.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import MaskedInput from "react-text-mask";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { SubUserEditSchema } from "utils/schemas/SubUserEditSchema";
import ReferenceDataContext from "AvatarContext";
function SubUserEditForm({
  onCloseEditDialog,
  openEdit,
  editData,
  editedPermission,
  onAlertShow,
  getSubUsersList,
}) {
  // Use to redirect to member list page after form submission //
  const history = useHistory();
  // Use to redirect to member list page after form submission //
  // permissions list based on different roles //
  const { rolePermissions } = useContext(ReferenceDataContext);
  // permissions list based on different roles //
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // inputs for storing sidebar permission value according to their ID //
  const [sidebarOptions, setSidebarOptions] = useState(editedPermission);
  // inputs for storing sidebar permission value according to their ID //
  // Loading state //
  const [loadingEdit, setLoadingEdit] = useState(false);
  // Loading state //
  // Set initial values //
  const initialValues = {
    first_name: editData.first_name,
    last_name: editData.last_name,
    email: editData.email,
    phone: editData.phone,
    status: editData.status,
    permissions: editData.permissions,
    permission_string: "",
  };
  // Set initial values //
  // Submit edit sub-user form and form validation,error handling //
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: SubUserEditSchema,
    onSubmit: async (values, action) => {
      let sidebar_filter = Object.values(sidebarOptions).map((val) => {
        for (var i in val) {
          if (val[i] === false) {
            delete val[i];
          }
        }
        return val;
      });
      let sidebar_filter_key = sidebar_filter.map((val) => Object.keys(val));
      let arr_split = sidebar_filter_key.map((val) => val.splice(1));
      let arr_structure = arr_split.map((val, index) => {
        return {
          [sidebar_filter[index]["key"]]: val,
        };
      });
      let filtered_arr_structure = arr_structure.filter(
        (val, index) => val[sidebar_filter[index]["key"]]?.length !== 0
      );
      setLoadingEdit(true);
      const data = {
        id: editData.id,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email.trim(),
        phone: values.phone,
        status: values.status,
        permissions: filtered_arr_structure,
      };
      await axios
        .post(`${process.env.REACT_APP_AXIOS_BASE_URL}/subusers/update`, data, {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("access_token"),
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            setLoadingEdit(false);
            onAlertShow("Sub-user updated successfully", "success");
            getSubUsersList();
            onCloseEditDialog();

            setSidebarOptions({
              dashboard: {
                key: "dashboard",
                sidebar_dashboard: false,
              },
              customers: {
                key: "customers",
                sidebar_customers: false,
                view_customers: false,
              },

              payment: {
                key: "payment",
                sidebar_payment: false,
                update_add_payment_details: false,
              },
              domainLabelling: {
                key: "domainLabelling",
                sidebar_domain: false,
                add_domain: false,
              },
              support: {
                key: "support",
                sidebar_support: false,
              },
            });
            action.resetForm();
          }
        })
        .catch((err) => {
          setLoadingEdit(false);
          if (Object.keys(err.response.data.errors).length !== 0) {
            if (err.response.data.errors.email) {
              AlertShow(err.response.data.errors.email.message, "warning");
            }
          }
          if (err.response.data.message === "Invalid Token") {
            localStorage.clear();
            history.push("/login");
          }
        });
    },
  });
  // Submit edit sub-user form and form validation,error handling //
  // Function for changing app club checkbox //
  const handleChangeDashboard = (e) => {
    setSidebarOptions({
      ...sidebarOptions,
      dashboard: {
        ...sidebarOptions.dashboard,
        [e.target.name]: e.target.checked,
      },
    });
  };
  // Function for changing app club checkbox //
  // Function for changing customer sidebar checkbox //
  const handleChangeCustomersSidebar = (e) => {
    if (!sidebarOptions["customers"]["sidebar_customers"]) {
      setSidebarOptions({
        ...sidebarOptions,
        customers: {
          key: "customers",
          sidebar_customers: e.target.checked,
          view_customers: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        customers: {
          key: "customers",
          sidebar_customers: e.target.checked,
          view_customers:
            e.target.checked === false
              ? false
              : sidebarOptions["customers"]["view_customers"],
        },
      });
    }
  };
  // Function for changing customer sidebar checkbox //
  // Function for changing customer list view button visible/invisible checkbox //
  const handleChangeCustomersView = (e) => {
    if (!sidebarOptions["customers"]["sidebar_customers"]) {
      setSidebarOptions({
        ...sidebarOptions,
        customers: {
          key: "customers",
          sidebar_customers: sidebarOptions["customers"]["sidebar_customers"],
          view_customers: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        customers: {
          key: "customers",
          sidebar_customers: sidebarOptions["customers"]["sidebar_customers"],
          view_customers: e.target.checked,
        },
      });
    }
  };
  // Function for changing customer list view button visible/invisible checkbox //
  // Function for changing payment sidebar checkbox //
  const handleChangePaymentSidebar = (e) => {
    if (!sidebarOptions["payment"]["sidebar_payment"]) {
      setSidebarOptions({
        ...sidebarOptions,
        payment: {
          key: "payment",
          sidebar_payment: e.target.checked,
          update_add_payment_details: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        payment: {
          key: "payment",
          sidebar_payment: e.target.checked,
          update_add_payment_details:
            e.target.checked === false
              ? false
              : sidebarOptions["payment"]["update_add_payment_details"],
        },
      });
    }
  };
  // Function for changing payment sidebar checkbox //
  // Function for changing payment module update button visible/invisible checkbox //
  const handleChangePaymentAdd = (e) => {
    if (!sidebarOptions["payment"]["sidebar_payment"]) {
      setSidebarOptions({
        ...sidebarOptions,
        payment: {
          key: "payment",
          sidebar_payment: sidebarOptions["payment"]["sidebar_payment"],
          update_add_payment_details: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        payment: {
          key: "payment",
          sidebar_payment: sidebarOptions["payment"]["sidebar_payment"],
          update_add_payment_details: e.target.checked,
        },
      });
    }
  };
  // Function for changing payment module update button visible/invisible checkbox //
  // Function for changing web app management sidebar checkbox //
  const handleChangeDomainLabellingSidebar = (e) => {
    if (!sidebarOptions["domainLabelling"]["sidebar_domain"]) {
      setSidebarOptions({
        ...sidebarOptions,
        domainLabelling: {
          key: "domainLabelling",
          sidebar_domain: e.target.checked,
          add_domain: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        domainLabelling: {
          key: "domainLabelling",
          sidebar_domain: e.target.checked,
          add_domain:
            e.target.checked === false
              ? false
              : sidebarOptions["domainLabelling"]["add_domain"],
        },
      });
    }
  };
  // Function for changing web app management sidebar checkbox //
  // Function for changing web app management module creation form visible/invisible checkbox //
  const handleChangeDomainLabellingAdd = (e) => {
    if (!sidebarOptions["domainLabelling"]["sidebar_domain"]) {
      setSidebarOptions({
        ...sidebarOptions,
        domainLabelling: {
          key: "domainLabelling",
          sidebar_domain: sidebarOptions["domainLabelling"]["sidebar_domain"],
          add_domain: false,
        },
      });
    } else {
      setSidebarOptions({
        ...sidebarOptions,
        domainLabelling: {
          key: "domainLabelling",
          sidebar_domain: sidebarOptions["domainLabelling"]["sidebar_domain"],
          add_domain: e.target.checked,
        },
      });
    }
  };
  // Function for changing web app management module creation form visible/invisible checkbox //
  // Function for changing support sidebar checkbox //
  const handleChangeSupport = (e) => {
    setSidebarOptions({
      ...sidebarOptions,
      support: {
        ...sidebarOptions.support,
        [e.target.name]: e.target.checked,
      },
    });
  };
  // Function for changing support sidebar checkbox //
  // Function for close dialog //
  const handleClose = () => {
    onCloseEditDialog();
  };
  // Function for close dialog //
  // Generalize function for notification alert //
  const AlertShow = (message, type) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // Generalize function for notification alert //
  // useEffect for error validation in sub-user permission section //
  useEffect(() => {
    let permission_true_arr = [];
    for (let index = 0; index < Object.values(sidebarOptions).length; index++) {
      if (Object.values(Object.values(sidebarOptions)[index])?.includes(true)) {
        permission_true_arr = [...permission_true_arr, "Value True"];
      }
    }
    if (permission_true_arr.length !== 0) {
      setFieldValue("permission_string", "Permission Added");
    } else {
      setFieldValue("permission_string", "");
    }
  }, [sidebarOptions, setFieldValue]);
  // useEffect for error validation in sub-user permission section //
  return (
    <div>
      <Dialog
        open={openEdit}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
          },
        }}
      >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>

        <DialogContent dividers className="px-0 px-sm-3 px-md-4">
          <div
            className={`popupForm-holder ${classes["dialog_content_subUser"]}`}
          >
            <Row>
              <Col md="12">
                <Form
                  id="RegisterValidation"
                  onSubmit={handleSubmit}
                  className="commonForm popupForm"
                >
                  <Card className="customColor4">
                    <CardHeader>
                      <CardTitle
                        className={`darkText labelText mb-0 ${classes["view_icon_cross"]}`}
                        tag="h4"
                      >
                        Edit Subuser Form
                        <i
                          onClick={handleClose}
                          className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
                        ></i>
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      {rolePermissions?.includes("clientList") && (
                        <>
                          <FormGroup className={`has-label`}>
                            <label className="darkText">
                              Client Id <span className="text-danger">*</span>
                            </label>
                            <Input
                              name="clientId"
                              placeholder="Enter your clientId"
                              type="text"
                              className={`${classes["clientId_style"]} ${classes["disabled_input"]}`}
                              min={0}
                              disabled={true}
                              value={editData?.clientId}
                              readOnly
                            />
                          </FormGroup>
                          <FormGroup className={`has-label`}>
                            <label className="darkText">
                              Client Name <span className="text-danger">*</span>
                            </label>
                            <Input
                              name="clientName"
                              placeholder="Enter your client name"
                              type="text"
                              className={`${classes["clientId_style"]} ${classes["disabled_input"]}`}
                              min={0}
                              value={editData?.updated_name}
                              readOnly
                            />
                          </FormGroup>
                        </>
                      )}

                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="first_name"
                          placeholder="Enter your first name"
                          type="text"
                          className="bg-white"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p className={classes["form_error"]}>
                            {errors.first_name}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="last_name"
                          placeholder="Enter your last name"
                          type="text"
                          className="bg-white"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p className={classes["form_error"]}>
                            {errors.last_name}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="email"
                          placeholder="Enter your email address"
                          type="email"
                          className="bg-white"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <p className={classes["form_error"]}>
                            {errors.email}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <MaskedInput
                          mask={[
                            "(",
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          className="form-control bg-white"
                          placeholder="Enter a phone number"
                          name="phone"
                          guide={true}
                          id="my-input-id"
                          value={values.phone}
                          onChange={(e) => {
                            setFieldValue(
                              "phone",
                              e.target.value.replace(/[^A-Z0-9]/gi, "")
                            );
                          }}
                          onBlur={handleBlur}
                        />

                        {errors.phone && touched.phone ? (
                          <p className={classes["form_error"]}>
                            {errors.phone}
                          </p>
                        ) : null}
                      </FormGroup>

                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Status <span className="text-danger">*</span>
                        </label>
                        <Select
                          name="status"
                          className={`w-100 bg-white ${classes["status_dropdown"]}`}
                          value={values.status}
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value="">Choose your Status</MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                        {errors.status && touched.status ? (
                          <p className={classes["form_error"]}>
                            {errors.status}
                          </p>
                        ) : null}
                      </FormGroup>
                      <br />
                      <label className="darkText">
                        Subuser permissions
                        <span className="text-danger">*</span>
                      </label>
                      <Row>
                        <Col>
                          <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                            <FormControlLabel
                              label="Customers"
                              className="text-dark"
                              control={
                                <Checkbox
                                  checked={
                                    sidebarOptions["customers"][
                                      "sidebar_customers"
                                    ]
                                  }
                                  onChange={(e) =>
                                    handleChangeCustomersSidebar(e)
                                  }
                                />
                              }
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label="View customers"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    id="view_customers"
                                    name="view_customers"
                                    disabled={
                                      !sidebarOptions["customers"][
                                        "sidebar_customers"
                                      ]
                                    }
                                    checked={
                                      sidebarOptions["customers"][
                                        "view_customers"
                                      ] &&
                                      sidebarOptions["customers"][
                                        "sidebar_customers"
                                      ]
                                    }
                                    onChange={(e) =>
                                      handleChangeCustomersView(e)
                                    }
                                  />
                                }
                              />
                            </Box>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-4">
                          <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                            <FormControlLabel
                              label="Payment"
                              className="text-dark"
                              control={
                                <Checkbox
                                  checked={
                                    sidebarOptions["payment"]["sidebar_payment"]
                                  }
                                  onChange={(e) =>
                                    handleChangePaymentSidebar(e)
                                  }
                                />
                              }
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                ml: 3,
                              }}
                            >
                              <FormControlLabel
                                label="Add/Update payment details"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    id="update_add_payment_details"
                                    name="update_add_payment_details"
                                    disabled={
                                      !sidebarOptions["payment"][
                                        "sidebar_payment"
                                      ]
                                    }
                                    checked={
                                      sidebarOptions["payment"][
                                        "sidebar_payment"
                                      ] &&
                                      sidebarOptions["payment"][
                                        "update_add_payment_details"
                                      ]
                                    }
                                    onChange={(e) => handleChangePaymentAdd(e)}
                                  />
                                }
                              />
                            </Box>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="mt-4">
                          <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                            <FormControlLabel
                              label="Domain Labelling"
                              className="text-dark"
                              control={
                                <Checkbox
                                  checked={
                                    sidebarOptions["domainLabelling"][
                                      "sidebar_domain"
                                    ]
                                  }
                                  onChange={(e) =>
                                    handleChangeDomainLabellingSidebar(e)
                                  }
                                />
                              }
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                ml: 3,
                              }}
                            >
                              {/* <FormControlLabel
                                label="Sidebar domain"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    name="sidebar_domain"
                                    checked={
                                      sidebarOptions["domainLabelling"][
                                        "sidebar_domain"
                                      ]
                                    }
                                    onChange={(e) =>
                                      handleChangeDomainLabellingSidebar(e)
                                    }
                                  />
                                }
                              /> */}

                              <FormControlLabel
                                label="Add domain"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    id="add_domain"
                                    name="add_domain"
                                    disabled={
                                      !sidebarOptions["domainLabelling"][
                                        "sidebar_domain"
                                      ]
                                    }
                                    checked={
                                      sidebarOptions["domainLabelling"][
                                        "add_domain"
                                      ] &&
                                      sidebarOptions["domainLabelling"][
                                        "sidebar_domain"
                                      ]
                                    }
                                    onChange={(e) =>
                                      handleChangeDomainLabellingAdd(e)
                                    }
                                  />
                                }
                              />
                            </Box>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-4" lg="12">
                          <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                            <FormControlLabel
                              label="App Club"
                              className="text-dark"
                              control={
                                <Checkbox
                                  name="sidebar_dashboard"
                                  checked={
                                    sidebarOptions["dashboard"][
                                      "sidebar_dashboard"
                                    ]
                                  }
                                  onChange={(e) => handleChangeDashboard(e)}
                                />
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="mt-4">
                          <div className="selectBox customColor6 rounded-2 py-3 px-sm-3">
                            <FormControlLabel
                              label="Support"
                              className="text-dark"
                              control={
                                <Checkbox
                                  name="sidebar_support"
                                  checked={
                                    sidebarOptions["support"]["sidebar_support"]
                                  }
                                  onChange={(e) => handleChangeSupport(e)}
                                />
                              }
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                ml: 3,
                              }}
                            >
                              {/* <FormControlLabel
                                label="Sidebar support"
                                className="text-dark"
                                control={
                                  <Checkbox
                                    name="sidebar_support"
                                    checked={
                                      sidebarOptions["support"][
                                        "sidebar_support"
                                      ]
                                    }
                                    onChange={(e) => handleChangeSupport(e)}
                                  />
                                }
                              /> */}
                            </Box>
                          </div>
                          {errors.permission_string &&
                          touched.permission_string ? (
                            <p className={classes["form_error"]}>
                              {errors.permission_string}
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter className="text-center btn-set">
                      <Button color="info" type="submit">
                        {loadingEdit ? "Processing..." : " Edit Subuser"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SubUserEditForm;
