import * as Yup from "yup";

export const SubUserPasswordUpdateSchema = Yup.object({
  new_password: Yup.string()
    .required("Please enter your password")
    .min(6, "Password must contain 6 or more characters"),

  confirm_new_password: Yup.string()
    .required("Please reenter your password")
    .min(6, "Password must contain 6 or more characters")
    .oneOf(
      [Yup.ref("new_password"), null],
      "New password and confirm password does not match"
    ),
});
