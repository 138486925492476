import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosCreate } from "../../src/axios";

// General function for show activity log list and search functionality //
async function getActivityLogDetails(activityArgs, rejectWithValue) {
  const { activityUrl, searchOptions, page, rowsPerPage, alertShow, history } =
    activityArgs;

  try {
    let url;
    switch (searchOptions) {
      case "":
        url = `${activityUrl}?show=${rowsPerPage}&page=${page + 1}`;
        break;

      default:
        let key_array = [];
        let value_array = [];
        const newSearch = {
          start_date: searchOptions.start_date,
          end_date: searchOptions.end_date,
        };
        Object.keys(newSearch).forEach((key) => {
          if (newSearch[key] !== "") {
            key_array.push(key);
            value_array.push(newSearch[key]);
          }
        });
        let key_string = "";
        let value_string = "";
        for (var i = 0; i < key_array?.length; i++) {
          key_string += '"' + key_array[i] + '"';
          key_string += ",";
          value_string += '"' + value_array[i] + '"';
          value_string += ",";
        }
        key_string = key_string.replace(/,+$/, "");
        value_string = value_string.replace(/,+$/, "");
        url = `${activityUrl}?where_clause={"where_fields":[${key_string}],"where_values":[${value_string}]}&show=${rowsPerPage}&page=${
          page + 1
        }`;
        break;
    }
    const response = await axiosCreate.get(url);
    return [
      {
        acitivityLogList: response.data.results.results.data,
        totalCount: response.data.results.results.count,
        currentPage: response.data.results?.results?.current_page,
        lastPage: response.data.results?.results?.last_page,
      },
    ];
  } catch (error) {
    if (error.response?.data.message === "Invalid Token") {
      localStorage.removeItem("access_token");
      localStorage.removeItem("userData");
      history.push("/login");
    }
    alertShow(error.response.data.message, "warning");
    return rejectWithValue({
      error: error.response.data,
      status: error.response.status,
    });
  }
}
// General function for show activity log list and search functionality //

// Thunk actions for activity log list & search functionality //
export const fetchActivityDetails = createAsyncThunk(
  "feature/fetchActivityDetails",
  async (activityArgs, { rejectWithValue }) => {
    return getActivityLogDetails(activityArgs, rejectWithValue);
  }
);
// Thunk actions for activity log list & search functionality //

// Function for creating slice //
export const activityLogSlice = createSlice({
  name: "activityLog",
  initialState: {
    activityLogList: [],
    activityLogListLoaded: "idle",
    error: null,
  },
  reducers: {
    emptySearch: (state, action) => {
      action.payload.alertShow(action.payload.message, "warning");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityDetails.pending, (state, action) => {
        state.activityLogListLoaded = "loading";
      })
      .addCase(fetchActivityDetails.fulfilled, (state, action) => {
        state.activityLogListLoaded = "succeeded";
        state.activityLogList = action.payload;
      })
      .addCase(fetchActivityDetails.rejected, (state, action) => {
        state.activityLogListLoaded = "rejected";
        state.error = action.payload;
      });
  },
});
// Function for creating slice //

// Export slice data //
export const activityLogActions = activityLogSlice.actions;
export const getActivityLogList = (state) => state.activityLog.activityLogList;
export const getActivityLogLoaded = (state) =>
  state.activityLog.activityLogListLoaded;
// Export slice data //
