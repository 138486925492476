/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import ReferenceDataContext from "AvatarContext";
import React, { useContext } from "react";

import { Button, CustomInput, FormGroup, Input } from "reactstrap";
import className from "../../assets/css/FixedPlugin.module.css";
const FixedPlugin = () => {
  const {classes,sidebarMini,setSidebarMini,activeColor,setActiveColor,setClasses}=useContext(ReferenceDataContext)
  // const [classes, setClasses] = React.useState("dropdown");
  const [darkMode, setDarkMode] = React.useState(false);
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
   };
   const handleActiveClick = (color) => {
    setActiveColor(color);
  };
 
  const handleActiveMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle("white-content");
  };
  const handleClose=() =>{
    setClasses(false)
  }
  return (
    <div  className={`fixed-plugin ${className['fixed_plugin_font']} ${className['settings_popup']}`}>
      <div className={!classes?className['fixed_plugin_hide']:className['fixed_plugin_show']}>
        {/* <a
          href="#pablo"
          onClick={(e) => {
            e.preventDefault();
            handleClick();
          }}
        >
         i < className="fa fa-cog fa-2x" />
        </a> */}
        <ul className="dropdown-menu show">
        <div className={className['dropdown_menu_div']}>
          <span onClick={handleClose} className={className['fixed_plugin_cross_icon']}>
          <i className="fa-sharp fa-solid fa-xmark"></i>
          </span>
        </div>
          <li className="header-title darkText">SIDEBAR BACKGROUND</li>
          <li className="adjustments-line">
            <div className="badge-colors text-center">
              <span
                className={
                  activeColor === "primary"
                    ? "badge filter badge-primary active"
                    : "badge filter badge-primary"
                }
                data-color="primary"
                onClick={() => {
                  handleActiveClick("primary");
                }}
              />
              <span
                className={
                  activeColor === "blue"
                    ? "badge filter badge-info active"
                    : "badge filter badge-info"
                }
                data-color="info"
                onClick={() => {
                  handleActiveClick("blue");
                }}
              />
              <span
                className={
                  activeColor === "green"
                    ? "badge filter badge-success active"
                    : "badge filter badge-success"
                }
                data-color="success"
                onClick={() => {
                  handleActiveClick("green");
                }}
              />
              <span
                className={
                  activeColor === "orange"
                    ? "badge filter badge-warning active"
                    : "badge filter badge-warning"
                }
                data-color="warning"
                onClick={() => {
                  handleActiveClick("orange");
                }}
              />
              <span
                className={
                  activeColor === "red"
                    ? "badge filter badge-danger active"
                    : "badge filter badge-danger"
                }
                data-color="danger"
                onClick={() => {
                  handleActiveClick("red");
                }}
              />
            </div>
          </li>
          <li className="header-title darkText">SIDEBAR MINI</li>
          <li className="adjustments-line">
            <div className="togglebutton switch-sidebar-mini d-flex align-items-center justify-content-center">
              <span className="label-switch darkText">OFF</span>
             <FormGroup>
             <CustomInput
                type="switch" 
                id="switch-1"
                name="sidebar_mini"
                onChange={handleMiniClick}
                value={sidebarMini}
                className="mt-n4"
              />
             </FormGroup>
            
              <span className="label-switch ml-n3 darkText">ON</span>
            </div>
          </li>
          {/* <li className="adjustments-line">
            <div className="togglebutton switch-change-color mt-3 d-flex align-items-center justify-content-center">
              <span className="label-switch">LIGHT MODE</span>
              <CustomInput
                type="switch"
                id="switch-2"
                onChange={handleActiveMode}
                value={darkMode}
                className="mt-n4"
              />
              <span className="label-switch ml-n3">DARK MODE</span>
            </div>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default FixedPlugin;
