import * as Yup from "yup";

export const LoginSchema = Yup.object({
  email: Yup.string().trim()
    .email("Invalid Email")
    .required("Please enter your email"),
  password: Yup.string().trim()
    .required("Please enter your password")
    .min(6, 'Password must contain 6 or more characters')
});
