import React, {
  Fragment,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import classes from "../../assets/css/CustomerDetails.module.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Skeleton,
  TablePagination,
  Typography,
} from "@mui/material";
import { AxiosAPiGet } from "AxiosAPi";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { FaSearch } from "react-icons/fa";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

import ReferenceDataContext from "AvatarContext";
// import ReferenceDataContext from "AvatarContext";
// Reducer function for view dialog //
const orderViewReducer = (state, action) => {
  if (action.type === "OPEN_ORDER_DIALOG_VIEW") {
    return {
      orderViewData: action.viewData,
      isViewDialogOpen: Object.keys(action.viewData).length !== 0,
    };
  } else if (action.type === "CLOSE_ORDER_DIALOG_VIEW") {
    return { orderViewData: {}, isViewDialogOpen: false };
  }
};
// Reducer function for view dialog //
function CutsomerDetails() {
  // permissions list based on different roles //
  const { rolePermissions } = useContext(ReferenceDataContext);
  // permissions list based on different roles //
  // use to redirect //
  const history = useHistory();
  // use to redirect //
  // Use to show table div when load //
  const tableRef = useRef(null);
  // Use to show table div when load //
  // Pagination page input //
  const [page, setPage] = useState(0);
  // Pagination page input //
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // input for capturing csv array //
  const [csvData, setCSVData] = useState([]);
  // input for capturing csv array //
  // Input for capturing total customer data count //
  const [dataCount, setDataCount] = useState(0);
  // Input for capturing total customer data count //
  // Input for capturing order Id //
  const [orderId, setOrderId] = useState("");
  // Input for capturing order Id //
  // Input for order status,order type,order total min, order total max //
  const [orderStatus, setOrderStatus] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderTotalMin, setOrderTotalMin] = useState("");
  const [orderTotalMax, setOrderTotalMax] = useState("");
  // Input for order status,order type,order total min, order total max //
  // Input for capturing CSV data count //
  const [csvCount, setCSVCount] = useState("");
  // Input for capturing CSV data count //
  // input for storing total CSV data //
  const [initialCSVData, setInitialCSVData] = useState([]);
  // input for storing total CSV data //
  // input for storing list api url //
  const [searchUrl, setSearchUrl] = useState("");
  // input for storing list api url //
  // input for storing current page value //
  const [currentPage, setCurrentPage] = useState("");
  // input for storing current page value //
  // input for storing total page value //
  const [totalPage, setTotalPage] = useState("");
  // input for storing total page value //
  // Input for client list dropdown open //
  const [openDropdown, setOpenDropdown] = useState(false);
  // Input for client list dropdown open //
  // input for client list filter //
  const [searchValue, setSearchValue] = useState("");
  // input for client list filter //
  // Input for client name //
  const [clientName, setClientName] = useState("");
  // Input for client name //
  // Input for client Id //
  const [clientId, setClientId] = useState("");
  // Input for client Id //
  // Input for set the client name and id together as a string //
  const [clientNameWithId, setClientNameWithId] = useState("");
  // Input for set the client name and id together as a string //
  // Input for client status //
  const [clientStatus, setClientStatus] = useState("Active");
  // Input for client status //
  // Input for set csv file name //
  const [csvFileName, setCsvFileName] = useState("");
  // Input for set csv file name //
  // Input for collapsable rows //
  const [expandedRows, setExpandedRows] = useState([]);
  const [expandState, setExpandState] = useState({});
  // Input for collapsable rows //
  // Input for created date, cancel date, refund date //
  const [createdDate, setCreatedDate] = useState({
    created_start_date: "",
    created_end_date: "",
  });
  const [cancelDate, setCancelDate] = useState({
    cancel_start_date: "",
    cancel_end_date: "",
  });
  const [refundDate, setRefundDate] = useState({
    refund_start_date: "",
    refund_end_date: "",
  });
  // Input for created date, cancel date, refund date //
  // Input for where clause input data //
  const [searchEqualData, setSearchEqualData] = useState({
    "customer.email": "",

    "customer.customerId": "",
    "customer.isActive": "",
    clientId: "",
  });
  // Input for where clause input data //
  //Input for search clause input data //
  const [searchNonEqualData, setSearchNonEqualData] = useState({
    "customer.firstName": "",
    "customer.lastName": "",
    "customer.phone": "",
  });
  //Input for search clause input data //
  // Input for client list array //
  const [clientList, setClientList] = useState([]);
  // Input for client list array //
  // Pagination number of rows per page //
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // Pagination number of rows per page //
  // Input for customer list array //
  const [customerData, setCustomerData] = useState([]);
  // Input for customer list array //
  // Input for date type //
  const [dateType, setDateType] = useState("");
  // Input for date type //
  // Input for existing login user data //
  const userData = JSON.parse(localStorage.getItem("userData"));

  // Input for existing login user data //
  // Loading state //
  const [loadingCustomerDetails, setLoadingCustomerDetails] = useState(false);
  const [loadingCustomerDetailsCSV, setLoadingCustomerDetailsCSV] =
    useState(false);
  // Loading state //
  // inputs for view dialog state handle //
  const [orderViewState, dispatchOrderViewState] = useReducer(
    orderViewReducer,
    {
      orderViewData: {},
      isViewDialogOpen: false,
    }
  );
  let url_custommer =
    searchUrl !== ""
      ? `${searchUrl}show=${rowsPerPage}&page=${page + 1}`
      : `${process.env.REACT_APP_AXIOS_BASE_URL}/customers/list?${
          rolePermissions?.includes("clientList")
            ? ""
            : `where_clause={"where_fields":["clientId"],"where_values":["${userData?.data.clientId}"]}&`
        }show=${rowsPerPage}&page=${page + 1}`;

  let url_customer_csv =
    searchUrl !== ""
      ? `${searchUrl}show=30000`
      : `${process.env.REACT_APP_AXIOS_BASE_URL}/customers/list?${
          rolePermissions?.includes("clientList")
            ? ""
            : `where_clause={"where_fields":["clientId"],"where_values":["${userData?.data.clientId}"]}&`
        }show=30000`;
  let client_list = !rolePermissions?.includes("clientList") ? "client" : "";
  // inputs for view dialog state handle //
  // Input for csv headers //
  let headers = rolePermissions?.includes("clientList")
    ? [
        { label: "Client ID", key: "clientId" },
        { label: "Customer ID", key: "Customer ID" },
        { label: "Order ID", key: "Order ID" },
        { label: "Customer name", key: "Customer name" },
        { label: "Customer Email", key: "Customer Email" },
        { label: "Customer Address", key: "Customer Address" },
        { label: "Customer Phone", key: "Customer Phone" },
        { label: "Customer Status", key: "Customer Status" },
        { label: "Order Total", key: "Order Total" },
        { label: "Status", key: "Status" },
        { label: "Type", key: "Type" },
        { label: "Order Date", key: "Order Date" },
        { label: "Cancel Date", key: "Cancel Date" },
        { label: "Refund Date", key: "Refund Date" },
        { label: "Campaign Id", key: "Campaign Id" },
        { label: "Campaign Name", key: "Campaign Name" },
      ]
    : [
        { label: "Customer ID", key: "Customer ID" },
        { label: "Order ID", key: "Order ID" },
        { label: "Customer name", key: "Customer name" },
        { label: "Customer Email", key: "Customer Email" },
        { label: "Customer Address", key: "Customer Address" },
        { label: "Customer Phone", key: "Customer Phone" },
        { label: "Customer Status", key: "Customer Status" },
        { label: "Order Total", key: "Order Total" },
        { label: "Status", key: "Status" },
        { label: "Type", key: "Type" },
        { label: "Order Date", key: "Order Date" },
        { label: "Cancel Date", key: "Cancel Date" },
        { label: "Refund Date", key: "Refund Date" },
        { label: "Campaign Id", key: "Campaign Id" },
        { label: "Campaign Name", key: "Campaign Name" },
      ];
  // Input for csv headers //
  // Get all the client list

  useEffect(() => {
    let url = "";
    const fetchClientDetails = (url) => {
      AxiosAPiGet(
        url,
        "get",
        (res) => {
          setClientList(
            res.data?.data?.map((name) => {
              let clientData;
              clientData = name?.hasOwnProperty("company_name")
                ? name?.company_name === "" ||
                  name?.company_name === undefined ||
                  name?.company_name === null
                  ? name?.name === "" ||
                    name?.name === undefined ||
                    name?.name === null
                    ? {
                        ...name,
                        client_id: `${name?.clientId}`,
                        clientName: `${name?.first_name} ${name?.last_name}`,
                        clientName_with_id: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                      }
                    : {
                        ...name,
                        client_id: `${name?.clientId}`,
                        clientName: `${name?.name}`,
                        clientName_with_id: `${name?.name} (${name?.clientId})`,
                      }
                  : {
                      ...name,
                      client_id: `${name?.clientId}`,
                      clientName: `${name.company_name}`,
                      clientName_with_id: `${name.company_name} (${name?.clientId})`,
                    }
                : name?.name === "" ||
                  name?.name === undefined ||
                  name?.name === null
                ? {
                    ...name,
                    client_id: `${name?.clientId}`,
                    clientName: `${name?.first_name} ${name?.last_name}`,
                    clientName_with_id: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                  }
                : {
                    ...name,
                    client_id: `${name?.clientId}`,
                    clientName: `${name?.name}`,
                    clientName_with_id: `${name?.name} (${name?.clientId})`,
                  };
              return { clientData };
            })
          );
        },
        history,
        (err) => {
          NotificationAlertFun(err.response.data.message);
          // setLoadingCustomerDetails(false);
        }
      );
    };
    switch (client_list) {
      case "":
        switch (clientStatus) {
          case "":
            url = `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/all`;
            fetchClientDetails(url);
            break;

          default:
            url = `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/all?status=${clientStatus}`;
            fetchClientDetails(url);
            break;
        }
        break;

      default:
        break;
    }
  }, [history, clientStatus, client_list]);
  // Get all the client list
  // Get all the customer data

  useEffect(() => {
    async function fetchCustomersList() {
      setLoadingCustomerDetails(true);
      await AxiosAPiGet(
        url_custommer,
        "get",
        (res) => {
          setLoadingCustomerDetails(false);
          setCustomerData(res.data?.results.results?.data);
          setCurrentPage(res.data?.results?.results?.current_page);
          setTotalPage(res.data?.results?.results?.last_page);
          setDataCount(res.data?.results?.results?.count);
        },
        history,
        (err) => {
          NotificationAlertFun(err.response.data.message);
          setLoadingCustomerDetails(false);
        }
      );
    }
    fetchCustomersList();
  }, [history, url_custommer]);
  // Get all the customer data
  //Get all CSV data

  useEffect(() => {
    async function getInitialCSVData() {
      setLoadingCustomerDetailsCSV(true);
      await AxiosAPiGet(
        url_customer_csv,
        "get",
        (res) => {
          setInitialCSVData(res.data?.results.results?.data);
          setCSVCount(res.data?.results.results?.count);
          setLoadingCustomerDetailsCSV(false);
        },
        history,
        (err) => {
          NotificationAlertFun(err.response.data.message);
          setLoadingCustomerDetailsCSV(false);
        }
      );
    }

    getInitialCSVData();
  }, [history, url_customer_csv]);

  const handleCSVData = () => {
    let d = new Date();
    let dformat =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      d.getDate() +
      "-" +
      d.getHours() +
      "-" +
      d.getMinutes() +
      "-" +
      d.getSeconds();
    setCsvFileName("CutsomerDetails_" + dformat + ".csv");
    let newExportArr = [];
    if (initialCSVData?.length !== 0) {
      initialCSVData.forEach((element) => {
        var address = "";
        var customer_first_name =
          element.customer.firstName === null ||
          element.customer.firstName === ""
            ? ""
            : element.customer.firstName;
        var customer_last_name =
          element.customer.lastName === null || element.customer.lastName === ""
            ? ""
            : element.customer.lastName;
        var space =
          customer_first_name !== "" && customer_last_name !== "" ? " " : "";
        var name = customer_first_name + space + customer_last_name;
        address += element.address.address1
          ? element.address.address1 + " ,"
          : "" + element.address.address2
          ? element.address.address2 + " ,"
          : "" + element.address.city
          ? element.address.city + " ,"
          : "" + element.address.state
          ? element.address.state + " ,"
          : "" + element.address.postalCode
          ? element.address.postalCode + " ,"
          : "" + element.address.country
          ? element.address.country
          : "";
        element.orders.length !== 0
          ? element.orders.forEach((newElement) => {
              rolePermissions?.includes("clientList")
                ? newExportArr.push({
                    clientId: element.clientId,
                    "Customer ID": element.customer.customerId
                      ? element.customer.customerId
                      : "--",
                    "Order ID": newElement.orderId ? newElement.orderId : "--",
                    "Customer name": name !== "" ? name : "--",
                    "Customer Email": element.customer.email
                      ? element.customer.email
                      : "--",
                    "Customer Address": address ? address : "--",
                    "Customer Phone": element.customer.phone
                      ? element.customer.phone
                      : "--",
                    "Customer Status": element.customer.isActive
                      ? element.customer.isActive
                      : "--",
                    "Order Total":
                      newElement.orderTotal !== "" &&
                      newElement.orderTotal != null
                        ? newElement.orderTotal
                        : "--",
                    Status: newElement.orderStatus
                      ? handleOrderStatusValue(newElement)
                      : "--",
                    Type: newElement.orderType ? newElement.orderType : "--",
                    "Order Date": handleDateFormat(newElement.dateCreated),
                    "Cancel Date": newElement.cancelDate
                      ? handleDateFormat(newElement.cancelDate)
                      : "--",
                    "Refund Date": newElement.refundDate
                      ? handleDateFormat(newElement.refundDate)
                      : "--",
                    "Campaign Id": element.campaign.campaignId
                      ? element.campaign.campaignId
                      : "--",
                    "Campaign Name": element.campaign.campaignName
                      ? element.campaign.campaignName
                      : "--",
                  })
                : newExportArr.push({
                    "Customer ID": element.customer.customerId
                      ? element.customer.customerId
                      : "--",
                    "Order ID": newElement.orderId ? newElement.orderId : "--",
                    "Customer name": name !== "" ? name : "--",
                    "Customer Email": element.customer.email
                      ? element.customer.email
                      : "--",
                    "Customer Address": address ? address : "--",
                    "Customer Phone": element.customer.phone
                      ? element.customer.phone
                      : "--",
                    "Customer Status": element.customer.isActive
                      ? element.customer.isActive
                      : "--",
                    "Order Total":
                      newElement.orderTotal !== "" &&
                      newElement.orderTotal != null
                        ? newElement.orderTotal
                        : "--",
                    Status: newElement.orderStatus
                      ? handleOrderStatusValue(newElement)
                      : "--",
                    Type: newElement.orderType ? newElement.orderType : "--",
                    "Order Date": handleDateFormat(newElement.dateCreated),
                    "Cancel Date": newElement.cancelDate
                      ? handleDateFormat(newElement.cancelDate)
                      : "--",
                    "Refund Date": newElement.refundDate
                      ? handleDateFormat(newElement.refundDate)
                      : "--",
                    "Campaign Id": element.campaign.campaignId
                      ? element.campaign.campaignId
                      : "--",
                    "Campaign Name": element.campaign.campaignName
                      ? element.campaign.campaignName
                      : "--",
                  });
              setCSVData(newExportArr);
            })
          : rolePermissions?.includes("clientList")
          ? newExportArr.push({
              clientId: element.clientId,
              "Customer ID": element.customer.customerId
                ? element.customer.customerId
                : "--",
              "Order ID": "--",
              "Customer name": name !== "" ? name : "--",
              "Customer Email": element.customer.email
                ? element.customer.email
                : "--",
              "Customer Address": address ? address : "--",
              "Customer Phone": element.customer.phone
                ? element.customer.phone
                : "--",
              "Customer Status": element.customer.isActive
                ? element.customer.isActive
                : "--",
              "Order Total": "--",
              Status: "--",
              Type: "--",
              "Order Date": "--",
              "Cancel Date": "--",
              "Refund Date": "--",
              "Campaign Id": element.campaign.campaignId
                ? element.campaign.campaignId
                : "--",
              "Campaign Name": element.campaign.campaignName
                ? element.campaign.campaignName
                : "--",
            })
          : newExportArr.push({
              "Customer ID": element.customer.customerId
                ? element.customer.customerId
                : "--",
              "Order ID": "--",
              "Customer name": name !== "" ? name : "--",
              "Customer Email": element.customer.email
                ? element.customer.email
                : "--",
              "Customer Address": address ? address : "--",
              "Customer Phone": element.customer.phone
                ? element.customer.phone
                : "--",
              "Customer Status": element.customer.isActive
                ? element.customer.isActive
                : "--",
              "Order Total": "--",
              Status: "--",
              Type: "--",
              "Order Date": "--",
              "Cancel Date": "--",
              "Refund Date": "--",
              "Campaign Id": element.campaign.campaignId
                ? element.campaign.campaignId
                : "--",
              "Campaign Name": element.campaign.campaignName
                ? element.campaign.campaignName
                : "--",
            });
        setCSVData(newExportArr);
      });
    } else {
      setCSVData([]);
    }
  };

  //Get all CSV data
  // Individual view details functionality //
  const handleViewDetails = (customerDetail, j, order) => {
    dispatchOrderViewState({
      type: "OPEN_ORDER_DIALOG_VIEW",
      viewData: {
        id: j,
        customerData: customerDetail,
        order: order,
      },
    });
  };
  // Individual view details functionality //
  //Calling function for search functionality and error handling //

  // Get individual details when click on view
  const handleSearch = (val) => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
    var created_start_date = "";
    var created_end_date = "";

    var refund_start_date = "";
    var refund_end_date = "";

    var cancel_start_date = "";
    var cancel_end_date = "";

    if (createdDate.created_start_date !== "") {
      created_start_date = format(createdDate.created_start_date, "yyyy-MM-dd");
    }
    if (createdDate.created_end_date !== "") {
      created_end_date = format(createdDate.created_end_date, "yyyy-MM-dd");
    }

    if (refundDate.refund_start_date !== "") {
      refund_start_date = format(refundDate.refund_start_date, "yyyy-MM-dd");
    }
    if (refundDate.refund_end_date !== "") {
      refund_end_date = format(refundDate.refund_end_date, "yyyy-MM-dd");
    }

    if (cancelDate.cancel_start_date !== "") {
      cancel_start_date = format(cancelDate.cancel_start_date, "yyyy-MM-dd");
    }
    if (cancelDate.cancel_end_date !== "") {
      cancel_end_date = format(cancelDate.cancel_end_date, "yyyy-MM-dd");
    }

    if (orderTotalMin !== "" && orderTotalMax !== "") {
      if (Number(orderTotalMin) > Number(orderTotalMax)) {
        NotificationAlertFun("Min value should not be greater than max value");
      }
      if (Number(orderTotalMax) === Number(orderTotalMin)) {
        NotificationAlertFun("Min and max value should not be same");
      }
    }

    if (orderTotalMin !== "" && orderTotalMax === "") {
      NotificationAlertFun("Max value should not be empty");
    }
    if (dateType === "Created date") {
      if (created_start_date === "" && created_end_date === "") {
        NotificationAlertFun("Start and end created date should not be empty");
      }

      if (
        (created_start_date !== "" && created_end_date === "") ||
        (created_start_date === "" && created_end_date !== "")
      ) {
        NotificationAlertFun("End/Start created date should not be empty");
      }

      if (created_start_date !== "" && created_end_date !== "") {
        if (new Date(created_start_date) > new Date(created_end_date)) {
          NotificationAlertFun(
            "Start created date should not be greater than end created date"
          );
        }
      }
    }
    if (dateType === "Refund date") {
      if (refund_start_date === "" && refund_end_date === "") {
      }
      if (
        (refund_start_date !== "" && refund_end_date === "") ||
        (refund_start_date === "" && refund_end_date !== "")
      ) {
        NotificationAlertFun("End/Start refund date should not be empty");
      }

      if (refund_start_date !== "" && refund_end_date !== "") {
        if (new Date(refund_start_date) > new Date(refund_end_date)) {
          NotificationAlertFun(
            "Start refund date should not be greater than end created date"
          );
        }
      }
    }
    if (dateType === "Cancel date") {
      if (cancel_start_date === "" && cancel_end_date === "") {
        NotificationAlertFun("Start and end cancel date should not be empty");
      }
      if (
        (cancel_start_date !== "" && cancel_end_date === "") ||
        (cancel_start_date === "" && cancel_end_date !== "")
      ) {
        NotificationAlertFun("End/Start cancel date should not be empty");
      }

      if (cancel_start_date !== "" && cancel_end_date !== "") {
        if (new Date(cancel_start_date) > new Date(cancel_end_date)) {
          NotificationAlertFun(
            "Start cancel date should not be greater than end created date"
          );
        }
      }
    }

    // if(page>1) {
    //   setPage(0)
    // }
    let search_equal_data = {
      "customer.email": searchEqualData["customer.email"].toLowerCase().trim(),

      "customer.customerId": searchEqualData["customer.customerId"].trim(),
      "customer.isActive": searchEqualData["customer.isActive"],
      clientId: searchEqualData["clientId"],
    };
    let search_not_equal_data = {
      "customer.firstName": searchNonEqualData["customer.firstName"],
      "customer.lastName": searchNonEqualData["customer.lastName"],
      "customer.phone": searchNonEqualData["customer.phone"],
    };
    let key_array_equal = [];
    let value_array_equal = [];
    let key_array_not_equal = [];
    let value_array_not_equal = [];
    Object.keys(search_equal_data).forEach((key) => {
      if (search_equal_data[key] !== "") {
        key_array_equal.push(key);
        value_array_equal.push(search_equal_data[key]);
      }
    });
    Object.keys(search_not_equal_data).forEach((key) => {
      if (search_not_equal_data[key] !== "") {
        key_array_not_equal.push(key);
        value_array_not_equal.push(search_not_equal_data[key]?.trim());
      }
    });

    let key_string_equal = "";
    let value_string_equal = "";
    let key_string_not_equal = "";
    let value_string_not_equal = "";
    for (var i = 0; i < key_array_equal?.length; i++) {
      key_string_equal += '"' + key_array_equal[i] + '"';
      key_string_equal += ",";
      value_string_equal += '"' + value_array_equal[i] + '"';
      value_string_equal += ",";
    }
    for (var j = 0; j < key_array_not_equal?.length; j++) {
      key_string_not_equal += '"' + key_array_not_equal[j] + '"';
      key_string_not_equal += ",";
      value_string_not_equal += '"' + value_array_not_equal[j] + '"';
      value_string_not_equal += ",";
    }

    key_string_equal = key_string_equal.replace(/,+$/, "");
    value_string_equal = value_string_equal.replace(/,+$/, "");
    key_string_not_equal = key_string_not_equal.replace(/,+$/, "");
    value_string_not_equal = value_string_not_equal.replace(/,+$/, "");

    let url_link = `${process.env.REACT_APP_AXIOS_BASE_URL}/customers/list?`;

    let where_clause =
      value_string_equal !== ""
        ? `where_clause={"where_fields":[${key_string_equal}],"where_values":[${value_string_equal}]}&`
        : "";

    let where_clause_client =
      value_string_equal !== ""
        ? `where_clause={"where_fields":[${key_string_equal},"clientId"],"where_values":[${value_string_equal},"${userData?.data.clientId}"]}&`
        : `where_clause={"where_fields":["clientId"],"where_values":["${userData?.data.clientId}"]}&`;
    let search_clause =
      value_string_not_equal !== ""
        ? `search_clause={"search_fields":[${key_string_not_equal}],"search_values":[${value_string_not_equal}]}&`
        : "";
    // Equal data
    let order_id_params = orderId !== "" ? `orders[orderId]=${orderId}&` : "";
    let order_type_params =
      orderType !== "" ? `orders[orderType]=${orderType}&` : "";
    let order_status_params =
      orderStatus !== "" ? `orders[orderStatus]=${orderStatus}&` : "";
    //Not equal data
    let order_total_params =
      orderTotalMin !== "" && orderTotalMax !== ""
        ? Number(orderTotalMin) < Number(orderTotalMax)
          ? `orders[min]=${orderTotalMin}&orders[max]=${orderTotalMax}&`
          : ""
        : "";
    //Order total
    let created_date_params =
      created_start_date !== "" && created_end_date !== ""
        ? `orders[created_start_date]=${created_start_date}&orders[created_end_date]=${created_end_date}&`
        : "";
    //Created date
    let cancel_date_params =
      cancel_start_date !== "" && cancel_end_date !== ""
        ? `orders[cancel_start_date]=${cancel_start_date}&orders[cancel_end_date]=${cancel_end_date}&`
        : "";
    //Cancel date
    let refund_date_params =
      refund_start_date !== "" && refund_end_date !== ""
        ? `orders[refund_start_date]=${refund_start_date}&orders[refund_end_date]=${refund_end_date}&`
        : "";
    //Refund date

    if (rolePermissions?.includes("clientList")) {
      url_link +=
        where_clause +
        search_clause +
        order_id_params +
        order_status_params +
        order_type_params +
        order_total_params +
        created_date_params +
        cancel_date_params +
        refund_date_params;

      setSearchUrl(url_link);
    } else {
      if (userData?.data.clientId) {
        url_link +=
          where_clause_client +
          search_clause +
          order_id_params +
          order_status_params +
          order_type_params +
          order_total_params +
          created_date_params +
          cancel_date_params +
          refund_date_params;

        setSearchUrl(url_link);
      }
      return;
    }
  };

  //Calling function for search functionality and error handling //
  const NotificationAlertFun = (msg) => {
    let options = {};
    options = {
      place: "tr",
      message: msg,
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  // Search filter functionality
  const handleCreatedStartDate = (e) => {
    setCreatedDate({ ...createdDate, created_start_date: e });
  };

  const handleCancelStartDate = (e) => {
    setCancelDate({ ...cancelDate, cancel_start_date: e });
  };
  const handleRefundStartDate = (e) => {
    setRefundDate({ ...refundDate, refund_start_date: e });
  };
  // onChange of start date(creation)
  const handleCreatedEndDate = (e) => {
    setCreatedDate({ ...createdDate, created_end_date: e });
  };
  const handleCancelEndDate = (e) => {
    setCancelDate({ ...cancelDate, cancel_end_date: e });
  };
  const handleRefundEndDate = (e) => {
    setRefundDate({ ...refundDate, refund_end_date: e });
  };
  // onChange of end date(creation)
  // const getSearchCSVData = (url) => {
  //   setLoadingCustomerDetails(true);
  //   AxiosAPiGet(
  //     url,
  //     "get",
  //     async (res) => {
  //       let customerStatusChange =
  //         searchEqualData["customer.isActive"] !== ""
  //           ? res.data?.results.results?.data?.map((item) => {
  //               return {
  //                 ...item,
  //                 customer_status: searchEqualData["customer.isActive"],
  //               };
  //             })
  //           : res.data?.results.results?.data;
  //       setInitialCSVData(customerStatusChange);
  //       setCSVCount(res.data?.results.results?.count);
  //       setLoadingCustomerDetails(false);
  //     },
  //     history,
  //     (err) => {
  //       if (
  //         err.response.data.message === "$and/$or/$nor must be a nonempty array"
  //       ) {
  //         NotificationAlertFun("Filter error");
  //       } else {
  //         NotificationAlertFun(err.response.data.message);
  //       }
  //       setLoadingCustomerDetails(false);
  //     }
  //   );
  // };
  // Get csv data on search
  // Close view functionality
  const handleClose = () => {
    dispatchOrderViewState({ type: "CLOSE_ORDER_DIALOG_VIEW" });
  };
  // Close view functionality //
  // Pagination change page functionality //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
    setExpandedRows([]);
    setExpandState({});
  };
  // Pagination change page functionality
  // Pagination rows per page functionality //
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  // Pagination rows per page functionality //
  // Reset functionality //
  const handleSearchReset = () => {
    setPage(0);
    setSearchEqualData({
      "customer.email": "",
      "customer.customerId": "",
      "customer.isActive": "",
      clientId: "",
    });
    setSearchNonEqualData({
      "customer.firstName": "",
      "customer.lastName": "",
      "customer.phone": "",
    });
    setOrderId("");
    setOrderType("");
    setClientName("");
    setClientId("");
    setClientNameWithId("");
    setOrderStatus("");
    setOrderTotalMin("");
    setOrderTotalMax("");
    setDateType("");
    setClientStatus("Active");
    setCreatedDate({
      created_start_date: "",
      created_end_date: "",
    });
    setCancelDate({
      cancel_start_date: "",
      cancel_end_date: "",
    });
    setRefundDate({
      refund_start_date: "",
      refund_end_date: "",
    });
    setSearchUrl(
      `${process.env.REACT_APP_AXIOS_BASE_URL}/customers/list?${
        rolePermissions?.includes("clientList")
          ? ""
          : `where_clause={"where_fields":["clientId"],"where_values":["${userData?.data.clientId}"]}&`
      }`
    );
  };
  // Reset functionality //
  // onChange function for where clause //
  const handleSearchEqualInput = (e) => {
    setSearchEqualData({ ...searchEqualData, [e.target.name]: e.target.value });
  };
  // onChange function for where clause //
  // onChange function for search clause //
  const handleSearchNonEqualInput = (e) => {
    setSearchNonEqualData({
      ...searchNonEqualData,
      [e.target.name]: e.target.value,
    });
  };
  // onChange function for search clause //
  //Functionality When data length greater //
  const handleExtraData = () => {
    NotificationAlertFun(
      "Unable to export data results over 30K records. Please modify search criteria."
    );
    setLoadingCustomerDetails(false);
  };
  //Functionality When data length greater //
  // Functionality for making date format in mm-dd-yyyy //
  const handleDateFormat = (date) => {
    let dateFull = new Date(date?.slice(0, 10));
    let day = dateFull.getDate();
    let month = dateFull.getMonth() + 1;
    let year = dateFull.getFullYear();
    return (
      (month < 10 ? `0${month}` : month) +
      "-" +
      (day < 10 ? `0${day}` : day) +
      "-" +
      year
    );
  };
  // Functionality for making date format in mm-dd-yyyy //
  // Disable keyboard type for created/cancel/refund start date & end date.
  const handleKeyCreatedStartDate = (e) => {
    e.preventDefault();
  };
  const handleKeyCreatedEndDate = (e) => {
    e.preventDefault();
  };
  const handleKeyCancelStartDate = (e) => {
    e.preventDefault();
  };
  const handleKeyCancelEndDate = (e) => {
    e.preventDefault();
  };
  const handleKeyRefundStartDate = (e) => {
    e.preventDefault();
  };
  const handleKeyRefundEndDate = (e) => {
    e.preventDefault();
  };
  // Disable keyboard type for created/cancel/refund start date & end date.
  // onChange function for order min input field //
  const handleNumberMin = (e) => {
    if (
      e.target.value.includes("e") ||
      e.target.value.includes("+") ||
      e.target.value.includes("-")
    ) {
      e.preventDefault();
    } else {
      setOrderTotalMin(e.target.value);
      setOrderTotalMax("");
    }
  };
  // onChange function for order min input field //
  // onChange date type input field //
  const handleDateType = (e) => {
    if (e.target.value === "Created date") {
      setDateType(e.target.value);
      setCancelDate({
        cancel_start_date: "",
        cancel_end_date: "",
      });
      setRefundDate({
        refund_start_date: "",
        refund_end_date: "",
      });
    }
    if (e.target.value === "Cancel date") {
      setDateType(e.target.value);
      setCreatedDate({
        created_start_date: "",
        created_end_date: "",
      });
      setRefundDate({
        refund_start_date: "",
        refund_end_date: "",
      });
    }
    if (e.target.value === "Refund date") {
      setDateType(e.target.value);
      setCreatedDate({
        created_start_date: "",
        created_end_date: "",
      });
      setCancelDate({
        cancel_start_date: "",
        cancel_end_date: "",
      });
    }

    if (e.target.value === "") {
      setDateType("");
      setCreatedDate({
        created_start_date: "",
        created_end_date: "",
      });
      setCancelDate({
        cancel_start_date: "",
        cancel_end_date: "",
      });
      setRefundDate({
        refund_start_date: "",
        refund_end_date: "",
      });
    }
  };
  // onChange date type input field //
  // Disable "e","E","+" in order min and max field //
  const handleKeyDownOrderMin = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };
  const handleKeyDownOrderMax = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };
  // Disable "e","E","+" in order min and max field //
  // onChange orderStatus input field //
  function handleOrderStatusValue(order) {
    let obj3 = order?.orderStatus !== "" ? order?.orderStatus : "--";
    if (
      (orderType === "" || orderType === "SALE" || orderType === "REBILL") &&
      orderStatus === ""
    ) {
      return obj3;
    } else {
      if (orderType === "REFUND" || orderType === "CANCEL") {
        if (orderStatus === "COMPLETE" || orderStatus === "") {
          obj3 = orderType === "REFUND" ? "Refunded" : "Canceled";
          return obj3;
        }
      } else if (orderType === "SALE" && orderStatus === "COMPLETE") {
        return obj3;
      } else if (orderType === "REBILL" && orderStatus === "COMPLETE") {
        return obj3;
      } else if (orderType === "REBILL" && orderStatus === "CANCELLED") {
        if (order?.isRefunded === 1 && order?.isCanceled === 1) {
          obj3 = "Refunded";
          return obj3;
        } else if (order?.isRefunded === 1 && order?.isCanceled === 0) {
          obj3 = "Refunded";
          return obj3;
        } else if (order?.isRefunded === 0 && order?.isCanceled === 1) {
          obj3 = "Canceled";
          return obj3;
        } else if (order?.isRefunded === 0 && order?.isCanceled === 0) {
          obj3 = "--";
          return obj3;
        }
      }
    }
  }
  // onChange orderStatus input field //
  // Handle special charactors in search field for client name //
  const handleSpecialCharacters = (e) => {
    var keycode = e.which || e.keyCode;

    if (
      (keycode > 8 && keycode < 48 && keycode !== 32) ||
      (keycode > 57 && keycode < 65) ||
      (keycode > 90 && keycode < 97) ||
      keycode > 122
    ) {
      e.preventDefault();
    }
  };
  // Handle special charactors in search field for client name //
  // Disable keyboard type in client name input //
  const handleKeyEvent = (e) => {
    e.preventDefault();
  };
  // Disable keyboard type in client name input //
  // set selected client name //
  const handleClientList = (k, client) => {
    setSearchEqualData({ ...searchEqualData, clientId: client.clientId });
    setClientName(client.clientName);
    setClientNameWithId(client.clientName_with_id);
    setClientId(client.client_id);
    setSearchValue("");
    // setOpenDropdown(false)
  };
  //
  useEffect(() => {
    document
      .getElementById("customer_content")
      .addEventListener("click", () => {
        setOpenDropdown(false);
      });
  }, []);
  // Focusing while opening client name dropdown //
  useEffect(() => {
    if (openDropdown) {
      document.getElementById("client_name_search")?.focus();
    }
  }, [openDropdown]);
  // Focusing while opening client name dropdown //
  // Removing functionality of client name //
  const handleClientNameRemove = () => {
    setSearchEqualData({ ...searchEqualData, clientId: "" });
    setClientName("");
    setClientId("");
    setClientNameWithId("");
  };
  // Removing functionality of client name //

  // onChange for client status //
  const handleClientStatus = (e) => {
    setClientStatus(e.target.value);
    setSearchEqualData({ ...searchEqualData, clientId: "" });
    setClientName("");
    setClientId("");
    setClientNameWithId("");
  };
  // onChange for client status //
  // Skeleton loader //
  const CustomerListLoader = () => {
    return [...Array(rowsPerPage)].map((val, index) => (
      <tr key={index} className={classes["table_row"]}>
        {rolePermissions?.includes("clientList") && (
          <td className="textColor">
            <Skeleton animation="wave" variant="text" />
          </td>
        )}

        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
      </tr>
    ));
  };
  // Skeleton loader //
  // Collapsable row functionality //
  const handleExpandRow = (e, index) => {
    const currentExpandedRows = expandedRows;
    const isRowExpanded = currentExpandedRows.includes(index);
    let obj = {};
    isRowExpanded ? (obj[index] = false) : (obj[index] = true);
    setExpandState(obj);
    const newExpandedRows = isRowExpanded
      ? currentExpandedRows.filter((i) => i !== index)
      : currentExpandedRows.concat(index);

    setExpandedRows(newExpandedRows);
  };
  // Collapsable row functionality //
  return (
    <div className="content" id="customer_content">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="customColor4 p-3 p-sm-4 rounded-2">
        <Form className="selectBox">
          <Accordion
            className="px-sm-3 my-0"
            sx={{
              backgroundColor: "#ced0d1",
            }}
          >
            <AccordionSummary
              expandIcon={
                <i
                  className={`fa-solid fa-chevron-down textColor display-4 ${classes["accordion_exapnd"]}`}
                ></i>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="select-title textColor">
                Filter by Customer Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card className="trnstprnt-bg commonForm">
                <CardBody className="p-0">
                  <Row>
                    <Col lg="4" sm="6">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Customer Id:
                        </Label>
                        <Input
                          className="bg-white"
                          type="text"
                          name="customer.customerId"
                          placeholder="Enter Customer Id"
                          value={searchEqualData?.["customer.customerId"]}
                          onChange={(e) => handleSearchEqualInput(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4" sm="6" className="mt-3 mt-sm-0">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Customer Status:
                        </Label>
                        <Select
                          className={`w-100 bg-white ${classes["customer_status_text"]}`}
                          name="customer.isActive"
                          onChange={(e) => handleSearchEqualInput(e)}
                          value={searchEqualData?.["customer.isActive"]}
                          displayEmpty
                        >
                          <MenuItem value="">Select Customer Status</MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </FormGroup>
                    </Col>
                    <Col lg="4" sm="6" className="mt-3 mt-md-4 mt-lg-0">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Customer First Name:
                        </Label>
                        <Input
                          className="bg-white"
                          name="customer.firstName"
                          type="text"
                          value={searchNonEqualData?.["customer.firstName"]}
                          placeholder="Enter First Name"
                          onChange={(e) => handleSearchNonEqualInput(e)}
                          // onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4" sm="6" className="mt-3 mt-sm-4">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Customer Last Name:
                        </Label>
                        <Input
                          className="bg-white"
                          name="customer.lastName"
                          type="text"
                          value={searchNonEqualData?.["customer.lastName"]}
                          placeholder="Enter Last Name"
                          onChange={(e) => handleSearchNonEqualInput(e)}
                          // onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4" sm="6" className="mt-3 mt-sm-4">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Customer Email:
                        </Label>
                        <Input
                          className="bg-white"
                          name="customer.email"
                          type="email"
                          value={searchEqualData["customer.email"]}
                          placeholder="Enter Email Id"
                          onChange={(e) => handleSearchEqualInput(e)}
                          // onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4" sm="6" className="mt-3 mt-sm-4">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Customer Phone Number:
                        </Label>
                        <Input
                          className="bg-white"
                          placeholder="Enter a Phone Number"
                          type="number"
                          min={0}
                          name="customer.phone"
                          value={searchNonEqualData["customer.phone"]}
                          id="my-input-id"
                          onChange={(e) => handleSearchNonEqualInput(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </AccordionDetails>
          </Accordion>
          <br />
          <Accordion
            className="px-sm-3 my-0"
            sx={{
              backgroundColor: "#ced0d1",
            }}
          >
            <AccordionSummary
              expandIcon={
                <i
                  className={`fa-solid fa-chevron-down textColor display-4 ${classes["accordion_exapnd"]}`}
                ></i>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="select-title textColor">
                Filter by Order Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Card className="trnstprnt-bg commonForm">
                <CardBody className="p-0">
                  {rolePermissions?.includes("clientList") && (
                    <Row>
                      <>
                        <Col lg="6" sm="6">
                          <FormGroup>
                            <Label className="darkText labelText">
                              Client Status:
                            </Label>
                            <Select
                              className={`w-100 bg-white ${classes["client_status_input"]}`}
                              name="status"
                              value={clientStatus}
                              onChange={(e) => handleClientStatus(e)}
                              displayEmpty
                            >
                              <MenuItem value="Active">Active</MenuItem>
                              <MenuItem value="Deactivated">
                                Deactivated
                              </MenuItem>
                              <MenuItem value="Hold">Hold</MenuItem>
                              <MenuItem value="Pause">Pause</MenuItem>
                              <MenuItem value="">All Clients</MenuItem>
                            </Select>
                          </FormGroup>
                        </Col>

                        <Col lg="6" sm="6" className="mt-3 mt-sm-0">
                          <FormGroup>
                            <Label className="darkText labelText">
                              Client Name:
                            </Label>
                            <div className={classes["input_container"]}>
                              <Input
                                type="text"
                                id="client_name_input"
                                className={`${classes["client_name_style"]} bg-white`}
                                name="clientId"
                                autoComplete="off"
                                placeholder={
                                  clientName === "" ? "Search Client Name" : ""
                                }
                                onClick={() => setOpenDropdown(!openDropdown)}
                                // onChange={(e) => setClientName(e.target.value)}
                                onKeyDown={(e) => handleKeyEvent(e)}
                                // value={clientName}
                              />
                              {clientName !== "" && (
                                <button
                                  className={classes["main_button_div"]}
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <p className={classes["client_name_cross"]}>
                                    {clientName} ({clientId})
                                  </p>
                                  <p
                                    onClick={handleClientNameRemove}
                                    className={classes["input_cross_container"]}
                                  >
                                    <i className="fa-solid fa-xmark"></i>
                                  </p>
                                </button>
                              )}
                            </div>

                            <div
                              className={
                                openDropdown
                                  ? `${classes["client_name_list_div"]} ${classes["client_name_list_show"]}`
                                  : `${classes["client_name_list_hide"]}`
                              }
                            >
                              <Input
                                className={`bg-white ${classes["search_input_style"]}`}
                                id="client_name_search"
                                type="search"
                                placeholder="Enter your search"
                                value={searchValue}
                                pattern="^\w\s\(\d\)$"
                                onChange={(e) => setSearchValue(e.target.value)}
                                onClick={() => setOpenDropdown(true)}
                                onKeyDown={(e) => handleSpecialCharacters(e)}
                              />
                              {clientList?.length !== 0 ? (
                                clientList
                                  ?.filter((data) =>
                                    data?.clientData?.clientName_with_id
                                      ?.toLowerCase()
                                      ?.includes(searchValue?.toLowerCase())
                                  )
                                  ?.map(
                                    (client, k) =>
                                      client.clientData?.clientId && (
                                        <p
                                          onClick={() =>
                                            handleClientList(
                                              k,
                                              client?.clientData
                                            )
                                          }
                                          key={k}
                                          className={
                                            clientNameWithId ===
                                            client.clientData
                                              ?.clientName_with_id
                                              ? `${classes["client_name_selected"]} ${classes["client_name_list"]}`
                                              : `${classes["client_name_not_selected"]} ${classes["client_name_list"]}`
                                          }
                                          id={`client_name${k}`}
                                        >
                                          {
                                            client?.clientData
                                              ?.clientName_with_id
                                          }
                                        </p>
                                      )
                                  )
                              ) : (
                                <p className={classes["no_records_style"]}>
                                  No records found
                                </p>
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    </Row>
                  )}
                  <Row>
                    <Col
                      lg="4"
                      sm="4"
                      className={
                        rolePermissions?.includes("customerList")
                          ? "mt-3 mt-sm-4"
                          : ""
                      }
                    >
                      <FormGroup>
                        <Label className="darkText labelText">Order Id:</Label>
                        <Input
                          className="bg-white"
                          type="text"
                          name="orders.orderId"
                          placeholder="Enter Order Id"
                          value={orderId}
                          onChange={(e) => setOrderId(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      lg="4"
                      sm="4"
                      className={
                        rolePermissions?.includes("customerList")
                          ? "mt-3 mt-sm-4"
                          : ""
                      }
                    >
                      <FormGroup>
                        <Label className="darkText labelText">
                          Order Total Min:
                        </Label>
                        <Input
                          className="bg-white"
                          name="orderTotal_min"
                          type="number"
                          step={0.01}
                          min={0}
                          value={orderTotalMin}
                          placeholder="Enter Order Total"
                          onChange={(e) => handleNumberMin(e)}
                          onKeyDown={(e) => handleKeyDownOrderMin(e)}
                          onWheel={(e) => e.target.blur()}
                          // onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      lg="4"
                      sm="4"
                      className={
                        rolePermissions?.includes("customerList")
                          ? "mt-3 mt-sm-4"
                          : ""
                      }
                    >
                      <FormGroup>
                        <Label className="darkText labelText">
                          Order Total Max:
                        </Label>
                        <Input
                          className="bg-white"
                          name="orderTotal_max"
                          type="number"
                          step={0.01}
                          min={0}
                          value={orderTotalMax}
                          placeholder="Enter Order Total"
                          disabled={orderTotalMin ? null : "disabled"}
                          onChange={(e) => setOrderTotalMax(e.target.value)}
                          onKeyDown={(e) => handleKeyDownOrderMax(e)}
                          onWheel={(e) => e.target.blur()}
                          // onBlur={handleBlur}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6" sm="6" className="mt-3 mt-sm-4">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Order Type:
                        </Label>
                        <Select
                          className={`w-100 bg-white ${classes["order_type_input"]}`}
                          name="orders.orderType"
                          value={orderType}
                          onChange={(e) => setOrderType(e.target.value)}
                          displayEmpty
                        >
                          <MenuItem value="">Select Order Type</MenuItem>
                          <MenuItem value="REBILL">Rebill</MenuItem>
                          <MenuItem value="SALE">Sale</MenuItem>
                          <MenuItem value="REFUND">Refund</MenuItem>
                          <MenuItem value="CANCEL">Cancel</MenuItem>
                        </Select>
                      </FormGroup>
                    </Col>
                    <Col lg="6" sm="6" className="mt-3 mt-sm-4">
                      <FormGroup>
                        <Label className="darkText labelText">
                          Order Status
                        </Label>
                        <Select
                          className={`w-100 bg-white ${classes["order_status_input"]}`}
                          name="orders.orderStatus"
                          value={orderStatus}
                          onChange={(e) => setOrderStatus(e.target.value)}
                          displayEmpty
                        >
                          <MenuItem value="">Select Order Status</MenuItem>
                          {(orderType === "SALE" ||
                            orderType === "REBILL" ||
                            orderType === "CANCEL" ||
                            orderType === "REFUND") && (
                            <MenuItem value="COMPLETE">Complete</MenuItem>
                          )}
                          {orderType === "REBILL" && (
                            <MenuItem value="CANCELLED">Cancelled</MenuItem>
                          )}
                        </Select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-3 mt-sm-4">
                      <FormGroup>
                        <Label className="darkText labelText">Date:</Label>
                        <Select
                          className={`w-100 bg-white ${classes["date_type_input"]}`}
                          name="date_type"
                          value={dateType}
                          onChange={(e) => handleDateType(e)}
                          displayEmpty
                        >
                          <MenuItem value="">Select Date Type</MenuItem>
                          <MenuItem value="Created date">Created date</MenuItem>
                          <MenuItem value="Cancel date">Cancel date</MenuItem>
                          <MenuItem value="Refund date">Refund date</MenuItem>
                        </Select>
                      </FormGroup>
                    </Col>
                  </Row>
                  {dateType === "Created date" && (
                    <Row>
                      <Col lg="6" sm="6" className="mt-3 mt-sm-4">
                        <FormGroup>
                          <Label className="darkText labelText">
                            Order Created Start Date:
                          </Label>
                          {/* <Input
                          name="created_start_date"
                          type="date"
                          max={createdDate.created_end_date}
                          value={createdDate.created_start_date}
                          placeholder="Enter Start Date"
                          onChange={(e) => handleCreatedStartDate(e)}
                          onKeyDown={(e) => handleKeyCreatedStartDate(e)}
                        /> */}
                          <DatePicker
                            name="created_start_date"
                            className="form-control bg-white"
                            selected={createdDate.created_start_date}
                            value={createdDate.created_start_date}
                            maxDate={new Date()}
                            onChange={(e) => handleCreatedStartDate(e)}
                            dateFormat="MM-dd-yyyy"
                            placeholderText={"Start Date"}
                            onKeyDown={(e) => handleKeyCreatedStartDate(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6" sm="6" className="mt-3 mt-sm-4">
                        <Label className="darkText labelText">
                          Order Created End Date:
                        </Label>
                        <FormGroup>
                          <DatePicker
                            className="form-control bg-white"
                            name="created_end_date"
                            selected={createdDate.created_end_date}
                            type="date"
                            placeholderText={"End Date"}
                            value={createdDate.created_end_date}
                            minDate={createdDate.created_start_date}
                            maxDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            // onKeyDown={(e) => handleKeyDownEndDate(e)}
                            onChange={(e) => handleCreatedEndDate(e)}
                            onKeyDown={(e) => handleKeyCreatedEndDate(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  {dateType === "Cancel date" && (
                    <Row>
                      <Col lg="6" sm="6" className="mt-3 mt-sm-4">
                        <FormGroup>
                          <Label className="darkText labelText">
                            Order Cancel Start Date:
                          </Label>
                          <DatePicker
                            name="cancel_start_date"
                            className="form-control bg-white"
                            placeholderText={"Start Date"}
                            maxDate={new Date()}
                            selected={cancelDate.cancel_start_date}
                            value={cancelDate.cancel_start_date}
                            onChange={(e) => handleCancelStartDate(e)}
                            dateFormat="MM-dd-yyyy"
                            onKeyDown={(e) => handleKeyCancelStartDate(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6" sm="6" className="mt-3 mt-sm-4">
                        <FormGroup>
                          <Label className="darkText labelText">
                            Order Cancel End Date:
                          </Label>
                          <DatePicker
                            className="form-control bg-white"
                            name="cancel_end_date"
                            type="date"
                            placeholderText={"End Date"}
                            selected={cancelDate.cancel_end_date}
                            value={cancelDate.cancel_end_date}
                            minDate={cancelDate.cancel_start_date}
                            maxDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            // onKeyDown={(e) => handleKeyDownEndDate(e)}
                            onChange={(e) => handleCancelEndDate(e)}
                            onKeyDown={(e) => handleKeyCancelEndDate(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                  {dateType === "Refund date" && (
                    <Row>
                      <Col lg="6" sm="6" className="mt-3 mt-sm-4">
                        <FormGroup>
                          <Label className="darkText labelText">
                            Order Refund Start Date:
                          </Label>
                          <DatePicker
                            name="refund_start_date"
                            className="form-control bg-white"
                            selected={refundDate.refund_start_date}
                            value={refundDate.refund_start_date}
                            onChange={(e) => handleRefundStartDate(e)}
                            dateFormat="MM-dd-yyyy"
                            placeholderText={"Start Date"}
                            maxDate={new Date()}
                            onKeyDown={(e) => handleKeyRefundStartDate(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6" sm="6" className="mt-3 mt-sm-4">
                        <FormGroup>
                          <Label className="darkText labelText">
                            Order Refund End Date:
                          </Label>
                          <DatePicker
                            className="form-control bg-white"
                            name="refund_end_date"
                            type="date"
                            placeholderText={"End Date"}
                            selected={refundDate.refund_end_date}
                            value={refundDate.refund_end_date}
                            minDate={refundDate.refund_start_date}
                            maxDate={new Date()}
                            dateFormat="MM-dd-yyyy"
                            // onKeyDown={(e) => handleKeyDownEndDate(e)}
                            onChange={(e) => handleRefundEndDate(e)}
                            onKeyDown={(e) => handleKeyRefundEndDate(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </AccordionDetails>
          </Accordion>
          <br />
          <Row>
            <Col
              lg="12"
              className={`mb-4 btn-set flex-wrap ${classes["button_col"]}`}
            >
              {!loadingCustomerDetailsCSV &&
                (dataCount > 30000 ? (
                  <Button
                    className={classes["export_button"]}
                    color="info"
                    type="button"
                    disabled={csvCount === 0}
                    onClick={handleExtraData}
                  >
                    <i className="fa-sharp fa-solid fa-file-excel"></i> Export
                    Data
                  </Button>
                ) : (
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    className={
                      csvCount === 0
                        ? `${classes["export_link"]} ${classes["export_link_pointer"]}`
                        : classes["export_link"]
                    }
                    onClick={handleCSVData}
                    target="_blank"
                    filename={csvFileName}
                  >
                    <Button
                      color="info"
                      type="button"
                      disabled={csvCount === 0}
                    >
                      <i className="fa-sharp fa-solid fa-file-excel"></i> Export
                      Data
                    </Button>
                  </CSVLink>
                ))}
              <Button color="info" type="button" onClick={handleSearch}>
                <FaSearch /> Search
              </Button>
              <Button
                color="info"
                type="button"
                className="custom-bg"
                onClick={handleSearchReset}
              >
                <i className="fa-sharp fa-solid fa-rotate-left"></i> Reset
              </Button>
            </Col>
          </Row>
        </Form>
        <div>
          <Row>
            <Col>
              {dataCount !== 0 && (
                <Card className="rounded-2 mb-0">
                  <CardBody className="py-1 pagination-holder">
                    <TablePagination
                      className={classes["pagination_style"]}
                      rowsPerPageOptions={[10, 25, 100]}
                      backIconButtonProps={{
                        style: {
                          color: Number(currentPage) === 1 ? "grey" : "black",
                        },
                      }}
                      nextIconButtonProps={{
                        style: {
                          color:
                            Number(currentPage) === totalPage
                              ? "grey"
                              : "black",
                        },
                      }}
                      component="div"
                      count={dataCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card className="rounded-2 mb-2 my-3">
                <CardBody>
                  <div
                    className={`${classes["table-responsive"]} table-full-width`}
                    ref={tableRef}
                  >
                    <Table className="text-center mb-0">
                      <tbody>
                        <tr>
                          {rolePermissions?.includes("clientList") && (
                            <th className="darkText labelText">ClientId</th>
                          )}
                          {/* <th>Order ID</th> */}
                          <th className="darkText labelText">Customer ID</th>
                          <th className="darkText labelText">Customer Name</th>
                          <th className="darkText labelText">Email</th>
                          <th className="darkText labelText">No. of Orders</th>
                          <th className="darkText labelText">Status</th>
                          <th></th>
                          {/* <th>Action</th> */}
                        </tr>
                        {loadingCustomerDetails ? (
                          CustomerListLoader()
                        ) : customerData?.length !== 0 ? (
                          customerData?.map((customerDetail, i) => {
                            let customerName = `${
                              customerDetail.customer.firstName === null
                                ? ""
                                : customerDetail.customer.firstName
                            } ${
                              customerDetail.customer.lastName === null
                                ? ""
                                : customerDetail.customer.lastName
                            }`;
                            return (
                              <Fragment key={i}>
                                <tr
                                  id={`toggler${i}`}
                                  onClick={(e) => handleExpandRow(e, i)}
                                  // style={{ marginBottom: "1rem",borderBottom:0 }}
                                  className={
                                    !customerDetail.customer.customerId &&
                                    (!customerDetail.customer.firstName ||
                                      !customerDetail.customer.firstName)
                                      ? `${classes["table_row"]} ${classes["disable_click"]}`
                                      : `${classes["table_row"]}`
                                  }
                                >
                                  {rolePermissions?.includes("clientList") && (
                                    <td className="textColor">
                                      {customerDetail.clientId}
                                    </td>
                                  )}
                                  {/* <td>{customerDetail.orders[0]?.orderId}</td> */}
                                  <td className="textColor">
                                    {customerDetail.customer.customerId
                                      ? customerDetail.customer.customerId
                                      : "--"}
                                  </td>
                                  <td className="textColor">
                                    {customerName.trim() !== ""
                                      ? customerName
                                      : "--"}
                                  </td>
                                  <td className="textColor">
                                    {customerDetail.customer.email
                                      ? customerDetail.customer.email?.includes(
                                          "mailto:"
                                        )
                                        ? customerDetail.customer.email?.slice(
                                            7
                                          )
                                        : customerDetail.customer.email
                                      : "--"}
                                  </td>
                                  <td className="textColor">
                                    <button
                                      className={`trnstprnt-bg textColor ${classes["order_length_style"]}`}
                                    >
                                      {customerDetail.orders?.length}
                                    </button>
                                  </td>
                                  <td className="textColor">
                                    {customerDetail?.hasOwnProperty(
                                      "customer_status"
                                    )
                                      ? customerDetail.customer_status
                                      : customerDetail?.customer?.isActive}
                                  </td>
                                  {!customerDetail.customer.customerId &&
                                  (!customerDetail.customer.firstName ||
                                    !customerDetail.customer.firstName) ? (
                                    <td></td>
                                  ) : (
                                    <>
                                      <td
                                        onClick={(e) => handleExpandRow(e, i)}
                                      >
                                        {expandState[i] ? (
                                          <i
                                            className={`textColor chevron-bg fa-solid fa-chevron-down`}
                                          ></i>
                                        ) : (
                                          <i
                                            className={`textColor chevron-bg fa-solid fa-chevron-up`}
                                          ></i>
                                        )}
                                      </td>
                                    </>
                                  )}
                                </tr>
                                {expandedRows.includes(i) ? (
                                  <tr>
                                    <td
                                      colSpan={7}
                                      className={classes["collapsed_table"]}
                                    >
                                      <Card className={`customColor4`}>
                                        <CardBody>
                                          <div
                                            className={`${classes["table-responsive"]} table-full-width ${classes["collpased_body"]}`}
                                          >
                                            <Table className="text-center">
                                              <tbody>
                                                <tr>
                                                  <th className="darkText">
                                                    Order Id
                                                  </th>
                                                  <th className="darkText">
                                                    Status
                                                  </th>
                                                  <th className="darkText">
                                                    Order Total($)
                                                  </th>
                                                  <th className="darkText">
                                                    Type
                                                  </th>
                                                  <th className="darkText">
                                                    Order Date
                                                  </th>
                                                  {rolePermissions?.includes(
                                                    "view"
                                                  ) && (
                                                    <th className="darkText">
                                                      Action
                                                    </th>
                                                  )}
                                                </tr>
                                                {customerDetail?.orders
                                                  ?.length !== 0 ? (
                                                  customerDetail?.orders?.map(
                                                    (order, j) => (
                                                      <tr key={j}>
                                                        <td className="textColor">
                                                          {order.orderId}
                                                        </td>
                                                        <td className="textColor">
                                                          {handleOrderStatusValue(
                                                            order
                                                          )}
                                                        </td>
                                                        <td className="textColor">
                                                          {order.orderTotal !=
                                                            null &&
                                                          order.orderTotal !==
                                                            ""
                                                            ? order.orderTotal
                                                            : "--"}
                                                        </td>
                                                        <td className="textColor">
                                                          {order.orderType}
                                                        </td>
                                                        <td className="textColor">
                                                          {handleDateFormat(
                                                            order.dateCreated
                                                          )}
                                                        </td>
                                                        {rolePermissions?.includes(
                                                          "view"
                                                        ) && (
                                                          <td>
                                                            <Button
                                                              color="info"
                                                              onClick={() =>
                                                                handleViewDetails(
                                                                  customerDetail,
                                                                  j,
                                                                  order
                                                                )
                                                              }
                                                            >
                                                              View
                                                            </Button>
                                                          </td>
                                                        )}
                                                      </tr>
                                                    )
                                                  )
                                                ) : (
                                                  <tr>
                                                    <td
                                                      colSpan={12}
                                                      className={
                                                        classes[
                                                          "no_records_order"
                                                        ]
                                                      }
                                                    >
                                                      No records Found
                                                    </td>
                                                  </tr>
                                                )}
                                              </tbody>
                                            </Table>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </td>
                                  </tr>
                                ) : null}
                              </Fragment>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan={12}
                              className={classes["no_records_order"]}
                            >
                              No records Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              {dataCount !== 0 && (
                <Card className="rounded-2 mb-2">
                  <CardBody className="py-1 pagination-holder">
                    <TablePagination
                      className={classes["pagination_style"]}
                      rowsPerPageOptions={[10, 25, 100]}
                      backIconButtonProps={{
                        style: {
                          color: Number(currentPage) === 1 ? "grey" : "black",
                        },
                      }}
                      nextIconButtonProps={{
                        style: {
                          color:
                            Number(currentPage) === totalPage
                              ? "grey"
                              : "black",
                        },
                      }}
                      component="div"
                      count={dataCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
        </div>
      </div>
      <Dialog
        open={orderViewState.isViewDialogOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
            overflow: "auto",
            overflowX: "hidden",
          },
        }}
      >
        <DialogTitle className={`darkText ${classes["view_icon_cross"]}`}>
          View Details
          <i
            onClick={handleClose}
            className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
          ></i>
        </DialogTitle>
        <DialogContent dividers className="px-0 px-sm-3 px-md-4">
          <div
            className={`popupForm-holder ${classes["view_content_customer"]}`}
          >
            {orderViewState.isViewDialogOpen && (
              <Container>
                <Row>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Order Id:</b>
                      <p className="textColor">
                        {" "}
                        {orderViewState.orderViewData.order.orderId
                          ? orderViewState.orderViewData.order.orderId
                          : "--"}
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Customer Id:</b>
                      <p className="textColor">
                        {" "}
                        {orderViewState.orderViewData.customerData.customer
                          .customerId
                          ? orderViewState.orderViewData.customerData.customer
                              .customerId
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr className={classes["horizontal_line_view"]} />
                <>
                  {" "}
                  <Row>
                    <Col lg="6" md="12">
                      <div className={classes["view_details_content"]}>
                        <b className="darkText">Type:</b>
                        <p className="textColor">
                          {" "}
                          {orderViewState.orderViewData.order.orderType
                            ? orderViewState.orderViewData.order.orderType
                            : "--"}
                        </p>
                      </div>
                    </Col>
                    <Col lg="6" md="12">
                      <div className={classes["view_details_content"]}>
                        <b className="darkText">Status:</b>
                        <p className="textColor">
                          {" "}
                          {orderViewState.orderViewData.order.orderType
                            ? handleOrderStatusValue(
                                orderViewState.orderViewData.order
                              )
                            : "--"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <hr className={classes["horizontal_line_view"]} />
                  <Row>
                    <Col lg="6" md="12">
                      <div className={classes["view_details_content"]}>
                        <b className="darkText">Order Date:</b>
                        <p className="textColor">
                          {orderViewState.orderViewData.order?.dateCreated
                            ? handleDateFormat(
                                orderViewState.orderViewData.order?.dateCreated
                              )
                            : "--"}
                        </p>
                      </div>
                    </Col>
                    {orderViewState.orderViewData.order.orderType ===
                      "CANCEL" && (
                      <Col lg="6" md="12">
                        <div className={classes["view_details_content"]}>
                          <b className="darkText">Cancel Date:</b>
                          <p className="textColor">
                            {orderViewState.orderViewData.order?.cancelDate
                              ? handleDateFormat(
                                  orderViewState.orderViewData.order?.cancelDate
                                )
                              : "--"}
                          </p>
                        </div>
                      </Col>
                    )}
                    {orderViewState.orderViewData.order.orderType ===
                      "REFUND" && (
                      <Col lg="6" md="12">
                        <div className={classes["view_details_content"]}>
                          <b className="darkText">Refund Date:</b>
                          <p className="textColor">
                            {orderViewState.orderViewData.order?.refundDate
                              ? handleDateFormat(
                                  orderViewState.orderViewData.order?.refundDate
                                )
                              : "--"}
                          </p>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <hr className={classes["horizontal_line_view"]} />
                  <Row>
                    <Col lg="12" md="12">
                      <div className={classes["view_details_content"]}>
                        <b className="darkText">Ip Address:</b>
                        <p className="textColor">
                          {orderViewState.orderViewData.customerData.customer
                            .ipAddress
                            ? orderViewState.orderViewData.customerData.customer
                                .ipAddress
                            : "--"}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <hr className={classes["horizontal_line_view"]} />
                </>

                <Row>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">First Name:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData.customer
                          .firstName
                          ? orderViewState.orderViewData.customerData.customer
                              .firstName
                          : "--"}
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Last Name:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData.customer
                          .lastName
                          ? orderViewState.orderViewData.customerData.customer
                              .lastName
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr className={classes["horizontal_line_view"]} />

                <Row>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Phone:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData.customer
                          .phone
                          ? orderViewState.orderViewData.customerData.customer
                              .phone
                          : "--"}
                      </p>
                    </div>
                  </Col>

                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Email:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData.customer
                          .email
                          ? orderViewState.orderViewData.customerData.customer.email?.includes(
                              "mailto:"
                            )
                            ? orderViewState.orderViewData.customerData.customer?.email?.slice(
                                7
                              )
                            : orderViewState.orderViewData.customerData.customer
                                ?.email
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>

                <hr className={classes["horizontal_line_view"]} />
                <Row>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Address1:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData?.address
                          ?.address1
                          ? orderViewState.orderViewData.customerData?.address
                              ?.address1
                          : "--"}
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Address2:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData?.address
                          ?.address2
                          ? orderViewState.orderViewData.customerData?.address
                              ?.address2
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr className={classes["horizontal_line_view"]} />
                <Row>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">City:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData?.address
                          ?.city
                          ? orderViewState.orderViewData.customerData?.address
                              ?.city
                          : "--"}
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">State:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData?.address
                          ?.state
                          ? orderViewState.orderViewData.customerData?.address
                              ?.state
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr className={classes["horizontal_line_view"]} />
                <Row>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Zip Code:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData?.address
                          ?.postalCode
                          ? orderViewState.orderViewData.customerData?.address
                              ?.postalCode
                          : "--"}
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Country:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData?.address
                          ?.country
                          ? orderViewState.orderViewData.customerData?.address
                              ?.country
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr className={classes["horizontal_line_view"]} />
                <Row>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Campaign Id:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData?.campaign
                          ?.campaignId
                          ? orderViewState.orderViewData.customerData?.campaign
                              ?.campaignId
                          : "--"}
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Campaign Name:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData?.campaign
                          ?.campaignName
                          ? orderViewState.orderViewData.customerData?.campaign
                              ?.campaignName
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
                <hr className={classes["horizontal_line_view"]} />
                <Row>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Order Total:</b>
                      <p className="textColor">
                        {" "}
                        {orderViewState.orderViewData.order?.orderTotal !==
                          "" &&
                        orderViewState.orderViewData.order?.orderTotal != null
                          ? orderViewState.orderViewData.order?.orderTotal
                          : "--"}
                      </p>
                    </div>
                  </Col>
                  <Col lg="6" md="12">
                    <div className={classes["view_details_content"]}>
                      <b className="darkText">Customer Status:</b>
                      <p className="textColor">
                        {orderViewState.orderViewData.customerData
                          ?.customer_status
                          ? orderViewState.orderViewData.customerData
                              ?.customer_status
                          : orderViewState.orderViewData.customerData?.customer
                              ?.isActive
                          ? orderViewState.orderViewData.customerData?.customer
                              ?.isActive
                          : "--"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CutsomerDetails;
