import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { AxiosAPiPost } from "AxiosAPi";
import React, { useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom";
import classes from "../../assets/css/SubUserDeleteForm.module.css";
function SubUserDeleteForm({
  onAlertShow,
  onCloseDeleteDialog,
  getSubUsersList,
  openDelete,
  deleteId,
}) {
  // Use to redirect to client list page after form submission //
  const history = useHistory();
  // Use to redirect to client list page after form submission //
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // Loading state //
  const [loadingDelete, setLoadingDelete] = useState(false);
  // Loading state //
  // Function for close dialog //
  const handleClose = () => {
    onCloseDeleteDialog();
  };
  // Function for close dialog //
  // Function for form submission of delete sub-user //
  const handleDelete = async () => {
    const id = {
      id: deleteId,
    };
    setLoadingDelete(true);
    AxiosAPiPost(
      `${process.env.REACT_APP_AXIOS_BASE_URL}/subusers/delete`,
      "post",
      id,
      (res) => {
        if (res.data.status === true) {
          setLoadingDelete(false);
          onCloseDeleteDialog();
          getSubUsersList();
          onAlertShow(res.data.message, "success");
        }
      },
      history,
      (err) => {
        if (err.response.data.message === "Invalid Token") {
          localStorage.clear();
          history.push("/login");
        }
        onAlertShow(err.response.data.errors[0].msg, "warning");
        setLoadingDelete(false);
      }
    );
  };
  // Function for form submission of delete sub-user //
  return (
    <div>
      <Dialog
        open={openDelete}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
          },
        }}
      >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this sub-user?
        </DialogTitle>
        <DialogActions>
          <button
            className={classes["btn_success"]}
            type="button"
            onClick={handleDelete}
          >
            {loadingDelete ? "Processing..." : "Yes"}
          </button>
          <button
            className={classes["btn_error"]}
            type="button"
            onClick={handleClose}
            autoFocus
          >
            No
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SubUserDeleteForm;
