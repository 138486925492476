import * as Yup from "yup";
// const userData = JSON.parse(localStorage.getItem("userData"));
export const SubUserSchema = Yup.object({
  first_name: Yup.string().required("First name can not be blank"),
  last_name: Yup.string().required("Last name can not be blank"),
  email: Yup.string().email("Invalid Email").required("Email can not be blank"),
  password: Yup.string()
    .min(6, "Password must contain at least 6 characters")
    .required("Password can not be blank"),
  phone: Yup.string()
    .min(10, "Phone no is invalid")
    .required("Phone number can not be blank"),
  permission_string: Yup.string().required(
    "Please choose at least one permission"
  ),
});

export const SuperAdminSubUserSchema = Yup.object({
  first_name: Yup.string().required("First name can not be blank"),
  last_name: Yup.string().required("Last name can not be blank"),
  email: Yup.string().email("Invalid Email").required("Email can not be blank"),
  password: Yup.string()
    .min(6, "Password must contain at least 6 characters")
    .required("Password can not be blank"),
  phone: Yup.string()
    .min(10, "Phone no is invalid")
    .required("Phone number can not be blank"),
  permission_string: Yup.string().required(
    "Please choose at least one permission"
  ),
  clientType: Yup.string().required("Client type field can not be blank"),
  clientId: Yup.string().required("Client name field can not be blank"),
});
