import React from 'react'
import { Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom'

function LoginPrivateRoute({ componentPrivateLogin: ComponentPrivateLogin, userRole, ...rest }) {
    const token=localStorage.getItem("access_token")
  return (
    <Route
      {...rest}
      render={(props) =>
        !token ? (
          <ComponentPrivateLogin {...props} />
        ) : (
          <Redirect
            to='/admin'
          />
        )
      }
    />
  )
}

export default LoginPrivateRoute