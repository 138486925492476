import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { UpdatePasswordSchema } from "utils/schemas/UpdatePasswordSchema";
import classes from "../../assets/css/UpdatePassword.module.css";
import { AxiosAPiGet } from "AxiosAPi";
import { AxiosAPiPost } from "AxiosAPi";
// import ReferenceDataContext from "AvatarContext";
const initialValues = {
  old_password: "",
  new_password: "",
  confirm_new_password: "",
};
function UpdatePassword() {
  // Show notification popup //
  const notificationAlertRef = React.useRef(null);
  // Show notification popup //
  // const {activeColor}=useContext(ReferenceDataContext)
  // Use to redirect //
  const history = useHistory();
  // Use to redirect //
  // Loading state //
  const [loading, setLoading] = useState(false);
  // Loading state //
  // set flag for dialog box //
  const [openDialog, setOpenDialog] = useState(false);
  // set flag for dialog box //
  // set flag for showing password //
  const [passwordShown, setPasswordShown] = useState(false);
  // set flag for showing password //
  // Functionality for toggle password //
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  // Functionality for toggle password //
  // submit form and error validation //
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: UpdatePasswordSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        AxiosAPiPost(
          `${process.env.REACT_APP_AXIOS_BASE_URL}/auth/change-password`,
          "post",
          {
            old_password: values?.old_password,
            new_password: values?.new_password,
            confirm_new_password: values?.confirm_new_password,
          },
          (res) => {
            if (res.data.status === true) {
              let options = {};
              options = {
                place: "tr",
                message: res.data.message,
                type: "success",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7,
              };
              notificationAlertRef.current.notificationAlert(options);
              setLoading(false);
            } else {
              setLoading(false);
              let options = {};
              options = {
                place: "tr",
                message: res.data.message,
                type: "warning",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 7,
              };
              notificationAlertRef.current.notificationAlert(options);
            }
          },
          history,
          (err) => {
            let options = {};
            options = {
              place: "tr",
              message: err.response.data.message,
              type: "warning",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 3,
            };
            notificationAlertRef.current.notificationAlert(options);
          }
        );
        action.resetForm();
      },
    });
  // submit form and error validation //
  // Logged out functionality //
  const handleLogout = () => {
    AxiosAPiGet(
      `${process.env.REACT_APP_AXIOS_BASE_URL}/auth/logout`,
      "get",
      (res) => {
        if (res.data.status === true) {
          localStorage.removeItem("userData");
          localStorage.removeItem("access_token");
          history.push("/login");
        }
      },
      history,
      (err) => {
        let options = {};
        options = {
          place: "tr",
          message: err.response.data.message,
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
      }
    );
  };
  // Logged out functionality //
  // Handle dialog box functionality //
  const handleLoggedIn = () => {
    setOpenDialog(!openDialog);
  };
  // Handle dialog box functionality //
  return (
    <div className="content">
      <NotificationAlert ref={notificationAlertRef} />
      <Row>
        <Col md="12">
          <div className="customColor4 p-3 p-sm-4 rounded-2">
            <Form onSubmit={handleSubmit}>
              <Card className="customColor6 rounded-2 commonForm py-3 px-sm-3 mb-0">
                <p className="select-title textColor pt-1 pt-md-2 px-3">
                  Update Password{" "}
                  <i
                    onClick={togglePassword}
                    className={
                      passwordShown
                        ? `fas fa-eye ${classes["password_toggle"]}`
                        : `fas fa-eye-slash ${classes["password_toggle"]}`
                    }
                  ></i>
                </p>

                <CardBody>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label className="darkText labelText">
                          Current password *{" "}
                        </label>
                        <Input
                          id="old_password"
                          className="bg-white"
                          type={passwordShown ? "text" : "password"}
                          placeholder="Enter your current password"
                          value={values.old_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.old_password && touched.old_password ? (
                          <p className={classes["form_error"]}>
                            {errors.old_password}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="mt-3 mt-sm-4">
                      <FormGroup>
                        <label className="darkText labelText">
                          New password *
                        </label>
                        <Input
                          id="new_password"
                          className="bg-white"
                          type={passwordShown ? "text" : "password"}
                          placeholder="Enter new password"
                          value={values.new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.new_password && touched.new_password ? (
                          <p className={classes["form_error"]}>
                            {errors.new_password}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="mt-3 mt-sm-4">
                      <FormGroup>
                        <label className="darkText labelText">
                          Confirm new password *
                        </label>
                        <Input
                          id="confirm_new_password"
                          className="bg-white"
                          type={passwordShown ? "text" : "password"}
                          placeholder="Re enter new password"
                          value={values.confirm_new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.confirm_new_password &&
                        touched.confirm_new_password ? (
                          <p className={classes["form_error"]}>
                            {errors.confirm_new_password}
                          </p>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="btn-set pt-0">
                  <Button
                    // className="btn-fill"
                    color="info"
                    type="submit"
                    disabled={
                      !values.old_password ||
                      !values.new_password ||
                      !values.confirm_new_password
                    }
                  >
                    {loading ? "Processing..." : "Update"}
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </div>
        </Col>
      </Row>
      <Dialog
        open={openDialog}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
            padding: "30px",
            textAlign: "center",
          },
        }}
      >
        <DialogContent>
          <DialogContentText>
            <p className="darkText labelText">
              Password updated successfully!!!
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="btn-set">
          <Button color="info" onClick={handleLoggedIn}>
            Stay Logged In
          </Button>
          <Button color="info" onClick={handleLogout}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UpdatePassword;
