/*!

=========================================================
* Black Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Pagination, Skeleton, Stack } from "@mui/material";
import { AxiosAPiGet } from "AxiosAPi";
import React, { useEffect, useState } from "react";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// reactstrap components
import { Alert, Row, Col } from "reactstrap";
import classes from "../../assets/css/NotificationCenter.module.css";
const Notifications = () => {
     //    Use to redirect //
  const history = useHistory(null);
     //    Use to redirect //
      // Show notification popup //
  const notificationAlertRef = React.useRef(null);
   // Show notification popup //
   //Loading state //
  const [loading, setLoading] = useState(false);
    //Loading state //

    // Notification list array //
  const [notificationList, setNotificationList] = useState([]);
     // Notification list array //
      // Input for capturing total notification data count //
  const [dataCount, setDataCount] = useState("");
       // Pagination page input //
  const [page, setPage] = React.useState(1);
     // Pagination page input //
       // Handle page change in pagination //
  const handleChange = (event, value) => {
    setPage(value);
  };
    // Handle page change in pagination //

       //  Loading initial data using useEffect //

  useEffect(() => {
    setLoading(true);
    AxiosAPiGet(
      `${process.env.REACT_APP_AXIOS_BASE_URL}/notifications/list?page=${page}&show=10`,
      "get",
      (res) => {
        setDataCount(res?.data.results?.results?.last_page);
        setNotificationList(res?.data.results?.results?.data);
        setLoading(false);
      },
      history,
      (err) => {
        let options = {};
        options = {
          place: "tr",
          message: err.response.data.message,
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
        setLoading(false);
      }
    );
  }, [history, page]);
   //  Loading initial data using useEffect //

   // Date format function //
  const handleReceivedDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  };

   // Date format function //
   //Skeleton loader //
  const NotificationListLoader = () => {
    return [...Array(10)].map((val, index) => (
      <Skeleton
        className={classes["skeleton_notification_height"]}
        animation="wave"
        variant="text"
        key={index}
      />
    ));
  };
     //Skeleton loader //
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="content">
        <Row
          className={notificationList?.length === 0 ? classes["data_center"]:""}
        >
          {loading ? (
            <Col>{NotificationListLoader()}</Col>
          ) : notificationList.length !== 0 ? (
            <Col md="12">
              {notificationList?.map((notification, i) => (
                <Alert color="info" key={i} fade={false}>
                  <span className={classes["notification_style"]}>
                    {notification?.notification}
                  </span>
                  <hr className="border-white" />
                  <span className="d-flex justify-content-end">
                    Notification received:{" "}
                    {handleReceivedDate(notification?.createdAt)}
                  </span>
                </Alert>
              ))}
              <Stack spacing={2} className={classes["pagination_position"]}>
                <Pagination
                  count={dataCount}
                  page={page}
                  onChange={handleChange}
                  color="info"
                />
              </Stack>
            </Col>
          ) : (
            <p className={classes["no_data_font"]}>No notifications yet!</p>
          )}
        </Row>
      </div>
    </>
  );
};

export default Notifications;
