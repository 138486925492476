import * as Yup from "yup";
const clientType = JSON.parse(localStorage.getItem("userData"))?.data
  ?.client_type;
export const PaymentSchema = Yup.object().shape(
  {
    llc_name: Yup.string().required("LLC Name can not be blank"),
    llc_address: Yup.string().required("LLC Address can not be blank"),
    invoice_email: Yup.string()
      .ensure()
      .when([], {
        is: () => clientType === "api",
        then: Yup.string()
          .email("Please enter a valid email")
          .required("Invoice email can not be blank"),
        otherwise: Yup.string()
          .notRequired()
          .email("Please enter a valid email"),
      }),

    bank_name: Yup.string()
      .ensure()
      .when([], {
        is: () => clientType === "shopify",
        then: Yup.string()
          .required("Bank name can not be blank")
          .matches(/^[a-zA-Z0-9 ]*$/, "Please enter a valid bank name"),
        otherwise: Yup.string()
          .notRequired()
          .matches(/^[a-zA-Z0-9 ]*$/, "Please enter a valid bank name"),
      }),
    account_number: Yup.string()
      .ensure()
      .when([], {
        is: () => clientType === "shopify",
        then: Yup.string().required("Account number can not be blank")
          .test("len", "Account number should be 10 digits long.", (val) => {
            if (val === undefined || val?.includes("X")) {
              return true;
            }
            return val.length === 10;
          })
          ,
        otherwise: Yup.string().notRequired(),
      }),

    wire_routing_number: Yup.string()
      .ensure()
      .when("ach_routing_number", {
        is: (ach_routing_number) =>
          (!ach_routing_number || ach_routing_number.length === 0) &&
          clientType === "shopify",
        then: Yup.string()
          .test(
            "len",
            "Wire routing number should be 10 digits long.",
            (val) => {
              if (val.length === 0 || val?.includes("X")) {
                return true;
              }
              return val.length === 10;
            }
          )
          .required("Wire routing number can not be blank"),
        otherwise: Yup.string()
          .notRequired()
          .test(
            "len",
            "Wire routing number should be 10 digits long.",
            (val) => {
              if (val.length === 0 || val?.includes("X")) {
                return true;
              }
              return val.length === 10;
            }
          ),
      }),
    ach_routing_number: Yup.string()
      .ensure()
      .when("wire_routing_number", {
        is: (wire_routing_number) =>
          (!wire_routing_number || wire_routing_number.length === 0) &&
          clientType === "shopify",
        then: Yup.string()
          .test(
            "len",
            "ACH routing number should be 10 digits long.",
            (val) => {
              if (val.length === 0 || val?.includes("X")) {
                return true;
              }
              return val.length === 10;
            }
          )
          .required("ACH routing number can not be blank"),
        otherwise: Yup.string()
          .notRequired()
          .test(
            "len",
            "ACH routing number should be 10 digits long.",
            (val) => {
              if (val.length === 0 || val?.includes("X")) {
                return true;
              }
              return val.length === 10;
            }
          ),
      }),
  },
  [
    [
      "wire_routing_number",
      "ach_routing_number",
      "invoice_email",
      "bank_name",
      "account_number",
    ],
  ]
);
