import * as Yup from "yup";
export const SubUserEditSchema = Yup.object({
  first_name: Yup.string().required("First name can not be blank"),
  last_name: Yup.string().required("Last name can not be blank"),
  email: Yup.string().email("Invalid Email").required("Email can not be blank"),
  status: Yup.string().required("Status can not be blank"),
  phone: Yup.string()
    .min(10, "Phone no is invalid")
    .required("Phone number can not be blank"),
  permission_string: Yup.string().required(
    "Please choose at least one permission"
  ),
});
