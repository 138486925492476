
import React from "react";
import { Route, Redirect } from "react-router-dom";

function AdminPrivateRoute({ componentPrivateAdmin: ComponentPrivateAdmin, userRole }) {
  const token=localStorage.getItem("access_token")
  return (
    <Route
      render={(props) =>
        token ? (
          <ComponentPrivateAdmin {...props} userRole={userRole} />
        ) : (
          <Redirect
            to={{pathname:"/login"}}
          />
        )
      }
    />
  );
}

export default AdminPrivateRoute;
