import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CircularProgress, Skeleton } from "@mui/material";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Table,
  Row,
  Col,
  CardTitle,
  Label,
} from "reactstrap";

import classes from "../../assets/css/AdminDashboard.module.css";
import { FaArrowCircleUp, FaSearch } from "react-icons/fa";
import { AxiosAPiGet } from "AxiosAPi";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { RiBillLine } from "react-icons/ri";
import { TbReceiptRefund } from "react-icons/tb";
import ReactDatePicker from "react-datepicker";
import { format } from "date-fns";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "../../assets/css/AdminDashboard.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import ReferenceDataContext from "AvatarContext";

function AdminDashboard() {
  // user object //
  const userData = JSON.parse(localStorage.getItem("userData"));
  // user object //
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // permissions list based on different roles //
  const { rolePermissions } = useContext(ReferenceDataContext);
  // permissions list based on different roles //
  //    Use to redirect //
  const history = useHistory();
  //    Use to redirect //
  // input for store dashboard data //
  const [dashboardData, setDashboardData] = useState([]);
  // input for store dashboard data //
  // input for loading state //
  const [loading, setLoading] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingChart, setLoadingChart] = useState(false);
  // input for loading state //
  // input for store graph data as a whole //
  const [graphData, setGraphData] = useState(null);
  // input for store graph data as a whole //
  // input for client status //
  const [clientStatus, setClientStatus] = useState("Active");
  // input for client status //
  //input for storing number of months data i.e graphs are showing //
  const [monthData, setMonthData] = useState([]);
  //input for storing number of months data i.e graphs are showing //
  // input for storing boolean values while changig client status from filter section //
  const [clientStatusChange, setClientStatusChange] = useState(false);
  // input for storing boolean values while changig client status from filter section //
  // input for storing trial sales data from graph //
  const [trialSalesData, setTrialSalesDate] = useState([]);
  // input for storing trial sales data from graph //
  //input for storing active customer count //
  const [activeCustomerCount, setActiveCustomerCount] = useState(null);
  //input for storing active customer count //
  //input for storing inactive customer count //
  const [inactiveCustomerCount, setInactiveCustomerCount] = useState(null);
  //input for storing inactive customer count //
  // input for storing successful rebills data from graph //
  const [rebillSuccessData, setRebillSuccessDate] = useState([]);
  // input for storing successful rebills data from graph //
  // input for storing failed rebills data from graph //
  const [rebillFailedData, setRebillFailedDate] = useState([]);
  // input for storing failed rebills data from graph //
  // input for storing attempted rebills data from graph //
  const [rebillAttemptedData, setRebillAttemptedDate] = useState([]);
  // input for storing attempted rebills data from graph //
  // input for changing start date //
  const [startDate, setStartDate] = useState("");
  // input for changing start date //
  const [endDate, setEndDate] = useState("");
  // input for changing start date //
  // input for storing boolean values to show up arrow icon while scrolling to bottom //
  const [showButton, setShowButton] = useState(false);
  // input for storing boolean values to show up arrow icon while scrolling to bottom //
  // input for storing total number of data //
  const [totalUsers, setTotalUsers] = useState();
  // input for storing total number of data //
  // input for storing filtered data //
  const [dashboardFilteredData, setDashboardFilteredData] = useState([]);
  // input for storing filtered data //
  // input for storing current page value //
  const [currentPage, setCurrentPage] = useState(0);
  // input for storing current page value //
  // page limit input //
  const PAGE_LIMIT = 4;
  // page limit input //
  // chart api url //
  const chart_url = rolePermissions?.includes("chart")
    ? `${process.env.REACT_APP_AXIOS_BASE_URL}/dashboards/chart`
    : "";
  // chart api url //
  // input for changing dashboard list api url //
  const [dashboardUrl, setDashboardUrl] = useState("");
  // input for changing dashboard list api url //

  let dashboard_api =
    dashboardUrl !== ""
      ? rolePermissions.includes("search")
        ? dashboardUrl
        : ""
      : "";
  // useEffect for changing dashboard list api url based on different roles //
  useEffect(() => {
    switch (userData?.data.roles[0]) {
      case "client":
        setDashboardUrl(
          process.env.REACT_APP_AXIOS_BASE_URL +
            "/dashboards/list?show=" +
            PAGE_LIMIT +
            "&client_type=" +
            "&page="
        );
        break;
      case "sub_client":
        setDashboardUrl(
          process.env.REACT_APP_AXIOS_BASE_URL +
            "/dashboards/list?show=" +
            PAGE_LIMIT +
            "&client_type=" +
            "&page="
        );
        break;
      case "super_admin":
        setDashboardUrl(
          process.env.REACT_APP_AXIOS_BASE_URL +
            "/dashboards/list?show=" +
            PAGE_LIMIT +
            "&client_type=" +
            "&status=Active" +
            "&page="
        );
        break;
      case "developer":
        setDashboardUrl(
          process.env.REACT_APP_AXIOS_BASE_URL +
            "/dashboards/list?show=" +
            PAGE_LIMIT +
            "&client_type=" +
            "&status=Active" +
            "&page="
        );
        break;
      case "admin":
        setDashboardUrl(
          process.env.REACT_APP_AXIOS_BASE_URL +
            "/dashboards/list?show=" +
            PAGE_LIMIT +
            "&client_type=" +
            "&status=Active" +
            "&page="
        );
        break;
      default:
        break;
    }
  }, [userData?.data.roles]);
  // useEffect for changing dashboard list api url based on different roles //
  //Get all the chart data//
  useEffect(() => {
    if (chart_url !== "") {
      setLoadingChart(true);
      AxiosAPiGet(
        chart_url,
        "get",
        (res) => {
          setGraphData(res.data.data);
          setActiveCustomerCount(res.data.activeCustomerCount);
          setInactiveCustomerCount(res.data.inactiveCustomerCount);

          setLoadingChart(false);
        },
        history,
        (err) => {
          let options = {};
          options = {
            place: "tr",
            message: err.response.data.message,
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef.current.notificationAlert(options);
          setLoading(false);
        }
      );
    } else {
      return;
    }
    return () => {
      setLoadingChart(false);
    };
  }, [history, chart_url]);
  ChartJS.register(ArcElement, Tooltip, Legend, {
    id: "noData",
    afterDatasetsDraw: (chart, args, plugins) => {
      const {
        ctx,
        chartArea: { top, left, width, height },
      } = chart;
      ctx.save();
      ctx.fillStyle = "rgba(102,102,102,0.3)";
      ctx.fillRect(left, top, width, height);
      ctx.font = "bold 20px sans-serif";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.fillText("No data available*", left + width / 2, top + height / 2);
    },
  });
  //Get all the chart data//

  //Get all the dashboard data//

  const getDashboardData = () => {
    if (dashboardUrl !== "") {
      if (rolePermissions.includes("search")) {
        let pageNo = Math.ceil(dashboardData.length / PAGE_LIMIT) + 1;
        setDashboardFilteredData([]);
        dashboardListApi(`${dashboardUrl}${pageNo}`);
      } else {
        dashboardListApiClient(`${dashboardUrl}${1}`);
      }
    }
  };
  useEffect(() => {
    let pageNo = 1;
    if (dashboardUrl !== "") {
      if (rolePermissions.includes("search")) {
        setLoading(true);
        setDashboardFilteredData([]);
        AxiosAPiGet(
          `${dashboardUrl}${pageNo}`,
          "get",
          (res) => {
            setDashboardData(res.data.data);
            setLoading(false);
            setTotalUsers(res.data.total_users);
            setCurrentPage(res.data.current_page);
          },
          history,
          (err) => {
            setLoading(false);
          }
        );
      } else {
        setLoadingClient(true);
        AxiosAPiGet(
          `${dashboardUrl}${pageNo}`,
          "get",
          (res) => {
            setDashboardData(res.data.data);
            setLoadingClient(false);
            setTotalUsers(res.data.total_users);
          },
          history,
          (err) => {
            setLoadingClient(false);
          }
        );
      }
    } else {
      return;
    }
    return () => {
      setLoading(false);
    };
  }, [dashboardUrl, history, rolePermissions]);

  const dashboardListApi = (url) => {
    AxiosAPiGet(
      url,
      "get",
      (res) => {
        const apiRes = res.data.data;
        const mergeData = [...dashboardData, ...apiRes];
        setDashboardData(mergeData);
        setCurrentPage(res.data.current_page);
        setLoading(false);
        setTotalUsers(res.data.total_users);
      },
      history,
      (err) => {
        setLoading(false);
      }
    );
  };
  const dashboardListApiClient = (url) => {
    setLoadingClient(true);
    AxiosAPiGet(
      url,
      "get",
      (res) => {
        setDashboardData(res.data.data);
        setLoadingClient(false);
        setTotalUsers(res.data.total_users);
      },
      history,
      (err) => {
        setLoadingClient(false);
      }
    );
  };

  //Get all the dashboard data//

  // General function for dashboard list api call for client and sub-client //
  const generalFunctionForDashboardSearchFirst = (
    start_date,
    end_date,
    url,
    pageNo
  ) => {
    dateFunctions(start_date, end_date);
    if (start_date === "" && end_date === "") {
      url =
        process.env.REACT_APP_AXIOS_BASE_URL +
        "/dashboards/list?show=" +
        PAGE_LIMIT +
        "&client_type=" +
        "&page=" +
        pageNo;
      getApiClient(url);
    }
    if (start_date !== "" && end_date !== "") {
      url = `${process.env.REACT_APP_AXIOS_BASE_URL}/dashboards/list?start_date=${start_date}&end_date=${end_date}&client_type=&show=${PAGE_LIMIT}&page=${pageNo}`;
      getApiClient(url);
    }
  };
  // General function for dashboard list api call for client and sub-client //
  // General function for dashboard list api call for Super Admin, developer //
  const generalFunctionForDashboardSearchSecond = (
    start_date,
    end_date,
    url,
    pageNo,
    clientStatus
  ) => {
    dateFunctions(start_date, end_date);
    if (start_date !== "" && end_date !== "" && clientStatus !== "") {
      url = `${process.env.REACT_APP_AXIOS_BASE_URL}/dashboards/list?start_date=${start_date}&end_date=${end_date}&client_type=&status=${clientStatus}&show=${PAGE_LIMIT}&page=${pageNo}`;
      getApi(url);
    }

    if (start_date === "" && end_date === "" && clientStatus === "") {
      url =
        process.env.REACT_APP_AXIOS_BASE_URL +
        "/dashboards/list?show=" +
        PAGE_LIMIT +
        "&client_type=" +
        "&page=" +
        pageNo;
      getApi(url);
    }
    if (start_date === "" && end_date === "" && clientStatus !== "") {
      url =
        process.env.REACT_APP_AXIOS_BASE_URL +
        "/dashboards/list?show=" +
        PAGE_LIMIT +
        "&client_type=" +
        "&status=" +
        clientStatus +
        "&page=" +
        pageNo;
      getApi(url);
    }

    if (start_date !== "" && end_date !== "" && clientStatus === "") {
      url = `${process.env.REACT_APP_AXIOS_BASE_URL}/dashboards/list?start_date=${start_date}&end_date=${end_date}&client_type=&show=${PAGE_LIMIT}&page=${pageNo}`;
      getApi(url);
    }
  };

  const getNextApi = useCallback(
    (url) => {
      setDashboardData([]);
      AxiosAPiGet(
        url,
        "get",
        (res) => {
          const apiRes = res.data.data;

          const mergeData = [...dashboardFilteredData, ...apiRes];
          setDashboardData([]);

          setDashboardFilteredData(mergeData);
          setTotalUsers(res.data.total_users);
          setCurrentPage(res.data.current_page);
          setLoading(false);
        },
        history,
        (err) => {
          setLoading(false);
        }
      );
    },
    [dashboardFilteredData, history]
  );
  const generalFunctionForDashboardSearchSecondNext = useCallback(
    (start_date, end_date, url, pageNo, clientStatus) => {
      dateFunctions(start_date, end_date);
      if (start_date !== "" && end_date !== "" && clientStatus !== "") {
        url = `${process.env.REACT_APP_AXIOS_BASE_URL}/dashboards/list?start_date=${start_date}&end_date=${end_date}&client_type=&status=${clientStatus}&show=${PAGE_LIMIT}&page=${pageNo}`;
        getNextApi(url);
      }

      if (start_date === "" && end_date === "" && clientStatus === "") {
        url =
          process.env.REACT_APP_AXIOS_BASE_URL +
          "/dashboards/list?show=" +
          PAGE_LIMIT +
          "&client_type=" +
          "&page=" +
          pageNo;
        getNextApi(url);
      }
      if (start_date === "" && end_date === "" && clientStatus !== "") {
        url =
          process.env.REACT_APP_AXIOS_BASE_URL +
          "/dashboards/list?show=" +
          PAGE_LIMIT +
          "&client_type=" +
          "&status=" +
          clientStatus +
          "&page=" +
          pageNo;
        getNextApi(url);
      }

      if (start_date !== "" && end_date !== "" && clientStatus === "") {
        url = `${process.env.REACT_APP_AXIOS_BASE_URL}/dashboards/list?start_date=${start_date}&end_date=${end_date}&client_type=&show=${PAGE_LIMIT}&page=${pageNo}`;
        getNextApi(url);
      }
    },
    [getNextApi]
  );
  // General function for dashboard list api call for Super Admin, developer //
  //Get all the dashboard data based on filter//
  const getDashboardFilteredData = () => {
    let pageNo = 1;
    var start_date = "";
    var end_date = "";
    if (startDate !== "") {
      start_date = format(startDate, "yyyy-MM-dd");
    }
    if (endDate !== "") {
      end_date = format(endDate, "yyyy-MM-dd");
    }
    let url = "";
    switch (userData?.data.roles[0]) {
      case "client":
        generalFunctionForDashboardSearchFirst(
          start_date,
          end_date,
          url,
          pageNo
        );
        break;
      case "sub_client":
        generalFunctionForDashboardSearchFirst(
          start_date,
          end_date,
          url,
          pageNo
        );
        break;

      default:
        generalFunctionForDashboardSearchSecond(
          start_date,
          end_date,
          url,
          pageNo,
          clientStatus
        );
        break;
    }
  };
  const dateFunctions = (start_date, end_date) => {
    if (start_date === "" && end_date !== "") {
      let options = {};
      options = {
        place: "tr",
        message: "Start date should not be empty",
        type: "warning",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 3,
      };
      notificationAlertRef.current.notificationAlert(options);
    }
    if (start_date !== "" && end_date === "") {
      let options = {};
      options = {
        place: "tr",
        message: "End date should not be empty",
        type: "warning",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 3,
      };
      notificationAlertRef.current.notificationAlert(options);
    }
    if (new Date(start_date) > new Date(end_date)) {
      let options = {};
      options = {
        place: "tr",
        message: "Start date should not be greater than end date",
        type: "warning",
        icon: "tim-icons icon-bell-55",
        autoDismiss: 3,
      };
      notificationAlertRef.current.notificationAlert(options);
    }
  };
  const getApi = (url) => {
    setDashboardData([]);
    setDashboardFilteredData([]);
    setLoading(true);
    AxiosAPiGet(
      url,
      "get",
      (res) => {
        const apiRes = res.data.data;
        setDashboardData([]);

        setDashboardFilteredData(apiRes);
        setTotalUsers(res.data.total_users);
        setCurrentPage(res.data.current_page);
        setLoading(false);
      },
      history,
      (err) => {
        setLoading(false);
      }
    );
  };
  const getApiClient = (url) => {
    setLoadingClient(true);
    AxiosAPiGet(
      url,
      "get",
      (res) => {
        const apiRes = res.data.data;
        setDashboardData(apiRes);
        setTotalUsers(res.data.total_users);
        setLoadingClient(false);
      },
      history,
      (err) => {
        setLoadingClient(false);
      }
    );
  };

  //Get all the dashboard data based on filter//

  //Search functionality based on inputs//
  const handleSearch = async () => {
    getDashboardFilteredData();
  };

  //Search functionality based on inputs//

  // This function will scroll the window to the top
  const scrollToTop = () => {
    const element = document.getElementById("section-0");
    element.scrollIntoView({ behavior: "auto" });
  };

  // const handleScroll = (e) => {
  //   if (e.target.scrollTop < 2) {
  //     setShowButton(false);
  //   } else {
  //     setShowButton(true);
  //   }
  // };
  // Disable keyboard input fron input type date//
  const handleKeyDownStartDate = (e) => {
    e.preventDefault();
  };

  const handleKeyDownEndDate = (e) => {
    e.preventDefault();
  };

  // Disable keyboard input fron input type date//

  //Reset dashboard data to its initial state//
  const resetSearch = (val) => {
    setStartDate("");
    setEndDate("");
    // setClientType(val);
    setClientStatus("Active");
    setClientStatusChange(false);
    setLoading(true);
    getDashboardData();
  };

  const resetSearchClient = (val) => {
    setStartDate("");
    setEndDate("");
    // setClientType(val);
    setClientStatus("Active");
    setClientStatusChange(false);
    setLoadingClient(true);
    getDashboardData();
  };

  //Reset dashboard data to its initial state//

  //Change chart data in frontend according to ui //

  useEffect(() => {
    if (graphData?.length > 0) {
      let array1 = graphData?.map(
        (data) =>
          `${data.month?.slice(0, 3)?.toUpperCase()}'${data.year
            .toString()
            ?.slice(-2)}`
      );
      setMonthData(array1);
      let trial_sales_array = [];
      let rebill_success_array = [];
      let rebill_fail_array = [];
      let attempt_rebill_array = [];
      trial_sales_array = graphData?.map((data) => data?.trial_sales);
      rebill_success_array = graphData?.map((data) => data?.rebill_success);
      rebill_fail_array = graphData?.map((data) => data?.rebill_fail);
      attempt_rebill_array = graphData?.map((data) => data?.rebill);

      setTrialSalesDate(trial_sales_array);
      setRebillSuccessDate(rebill_success_array);
      setRebillFailedDate(rebill_fail_array);
      setRebillAttemptedDate(attempt_rebill_array);
    }
  }, [graphData]);
  //Change chart data in frontend according to ui //
  //Pie Chart Data ///
  // const noData={
  //   id: "noData",

  //  }
  let trialSalesDataEmpty = trialSalesData?.every((val) => {
    return val === 0;
  });
  let chart_1_2_3_options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      noData: trialSalesData.length === 0 || trialSalesDataEmpty ? true : false,
    },

    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest",
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.yLabel;
        },
      },
    },
    responsive: true,
    scales: {
      x: {
        grid: {
          color: "#1F8EF1",
          zeroLineColor: "transparent",
          drawTicks: false,
        },
        ticks: {
          font: {
            size: 10,
          },
          padding: 10,
          color: "#1F8EF1",
        },
      },
      y: {
        grid: {
          drawTicks: false,
          borderDash: [8, 4],
          color: "#1F8EF1",
          // color: "rgba(253,93,147,0.1)",
          zeroLineColor: "transparent",
        },
        ticks: {
          padding: 10,
          color: "#1F8EF1",
        },
      },
    },
  };
  //Chart options//
  //Attemped Rebils//

  let chartExample1 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors
      let trialSalesDataempty = trialSalesData?.every((val) => {
        return val === 0;
      });

      return {
        labels:
          trialSalesData.length === 0 || trialSalesDataempty ? [] : monthData,
        datasets: [
          {
            label: "New Trial Sales",
            fill: true,
            animation: false,
            backgroundColor: "transparent",
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: trialSalesData,
            spanGaps: false,
          },
        ],
      };
    },

    options: chart_1_2_3_options,
  };
  //Attemped Rebils end//
  //Successfull rebill chart//

  //Successfull rebill chart//
  //Failed rebill chart//

  //Failed rebill chart//
  //Attempted rebill chart//
  let attemptedRebillDataEmpty = rebillAttemptedData?.every((val) => {
    return val === 0;
  });
  let chartExample5 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(72,72,176,0.1)");
      gradientStroke.addColorStop(0.4, "rgba(72,72,176,0.0)");
      gradientStroke.addColorStop(0, "rgba(119,52,169,0)"); //purple colors

      return {
        labels:
          rebillAttemptedData.length === 0 || attemptedRebillDataEmpty
            ? []
            : monthData,
        datasets: [
          {
            label: "Attempted Rebills",
            fill: true,
            animation: false,
            backgroundColor: "transparent",
            hoverBackgroundColor: gradientStroke,
            borderColor: "#000000",
            borderWidth: 1,
            borderDash: [],
            borderDashOffset: 0.0,
            data: rebillAttemptedData,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        noData:
          rebillAttemptedData.length === 0 || attemptedRebillDataEmpty
            ? true
            : false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        x: {
          grid: {
            drawBorder: false,
            borderDash: [8, 4],
            color: "grey",
            // color: "rgba(253,93,147,0.1)",
            zeroLineColor: "transparent",
            drawTicks: false,
          },
          ticks: {
            font: {
              size: 10,
            },
            padding: 10,
            color: "black",
          },
        },
        y: {
          grid: {
            borderDash: [8, 4],
            color: "grey",
            zeroLineColor: "transparent",
            drawTicks: false,
          },
          ticks: {
            padding: 10,
            color: "black",
          },
        },
      },
    },
  };

  //Attempted rebill chart//
  // Successfull Rebils
  let successRebillDataEmpty = rebillSuccessData?.every((val) => {
    return val === 0;
  });
  const chartExample6 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");
      var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(0,135,191,0.2)");
      gradientStroke.addColorStop(0.8, "rgba(0,135,191,0.1)");
      gradientStroke.addColorStop(0, "rgba(0,84,119,0)"); //blue colors

      return {
        labels:
          rebillSuccessData.length === 0 || successRebillDataEmpty
            ? []
            : monthData,
        datasets: [
          {
            label: "Successful Rebills",
            fill: true,
            backgroundColor: "#22ba8a",
            // borderColor: "#2380f7",
            // borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            minBarLength: 5,
            pointBackgroundColor: "#2380f7",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#2380f7",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: rebillSuccessData,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        noData:
          rebillSuccessData.length === 0 || successRebillDataEmpty
            ? true
            : false,
      },

      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        x: {
          grid: {
            borderDash: [8, 4],
            color: "#00C687",
            // color: "rgba(253,93,147,0.1)",
            zeroLineColor: "transparent",
            drawTicks: false,
          },
          ticks: {
            font: {
              size: 10,
            },
            padding: 10,
            color: "#00C687",
          },
        },
        y: {
          grid: {
            borderDash: [8, 4],
            color: "#00C687",
            // color: "rgba(253,93,147,0.1)",
            zeroLineColor: "transparent",
            drawTicks: false,
          },
          ticks: {
            padding: 10,
            color: "#00C687",
          },
        },
      },
    },
  };
  // Successfull Rebils end
  // Failed Rebils

  let failedRebillDataEmpty = rebillFailedData?.every((val) => {
    return val === 0;
  });
  const chartExample7 = {
    data: (canvas) => {
      let ctx = canvas.getContext("2d");
      var gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(253,93,147,0.8)");
      gradientStroke.addColorStop(0, "rgba(253,93,147,0)"); //blue colors
      return {
        labels:
          rebillFailedData.length === 0 || failedRebillDataEmpty
            ? []
            : monthData,
        datasets: [
          {
            label: "Failed Rebills",
            fill: true,
            backgroundColor: "#f2acbe",
            hoverBackgroundColor: gradientStroke,
            // borderColor: "#ff5991",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            data: rebillFailedData,
            tension: 0.4,
          },
        ],
      };
    },
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          labels: {
            // This more specific font property overrides the global property
          },
        },
        noData:
          rebillFailedData.length === 0 || failedRebillDataEmpty ? true : false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        x: {
          grid: {
            borderDash: [8, 4],
            color: "#ff5991",
            // color: "rgba(253,93,147,0.1)",
            zeroLineColor: "transparent",
            drawTicks: false,
          },
          ticks: {
            font: {
              size: 10,
            },
            color: "#ff5991",
            padding: 10,
          },
        },
        y: {
          grid: {
            borderDash: [8, 4],
            color: "#ff5991",
            // color: "rgba(253,93,147,0.1)",
            zeroLineColor: "transparent",
            drawTicks: false,
          },
          ticks: {
            color: "#ff5991",
            padding: 10,
          },
        },
      },
    },
  };

  // Failed Rebils end
  const chartExample9 = {
    data: {
      labels: [`Active Customers`, `Inactive Customers`],
      datasets: [
        {
          label:
            activeCustomerCount === 0 || inactiveCustomerCount === 0
              ? []
              : [`Active Customers`, `Inactive Customers`],
          pointRadius: 0,
          pointHoverRadius: 0,
          backgroundColor: ["#a060fb", "#d8bdfd"],
          borderWidth: 0,
          data: [activeCustomerCount, inactiveCustomerCount],
        },
      ],
    },
    options: {
      cutoutPercentage: 70,
      plugins: {
        legend: {
          display: false,
        },
        noData: false,
      },
      animations: false,

      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
    },
  };
  // Customer Pie chart end

  // Customer Pie chart end

  //Onchange for start date and end date//
  const handleStartDate = (e) => {
    setStartDate(e);
  };
  const handleEndDate = (e) => {
    setEndDate(e);
  };
  //Onchange for start date and end date//
  // Onchange for client status //
  const handleClientStatus = (e) => {
    setClientStatusChange(true);
    setClientStatus(e.target.value);
  };
  // Onchange for client status //
  // Fetch filtered data on scroll //
  const fetchFilteredData = useCallback(() => {
    let pageNo = Math.ceil(dashboardFilteredData.length / PAGE_LIMIT) + 1;
    let url = "";
    var start_date = "";
    var end_date = "";
    if (startDate !== "") {
      start_date = format(startDate, "yyyy-MM-dd");
    }
    if (endDate !== "") {
      end_date = format(endDate, "yyyy-MM-dd");
    }
    switch (userData?.data.roles[0]) {
      case "super_admin":
        generalFunctionForDashboardSearchSecondNext(
          start_date,
          end_date,
          url,
          pageNo,
          clientStatus
        );

        break;
      case "developer":
        generalFunctionForDashboardSearchSecondNext(
          start_date,
          end_date,
          url,
          pageNo,
          clientStatus
        );
        break;
      case "admin":
        generalFunctionForDashboardSearchSecondNext(
          start_date,
          end_date,
          url,
          pageNo,
          clientStatus
        );
        break;
      default:
        break;
    }
  }, [
    clientStatus,
    dashboardFilteredData,
    generalFunctionForDashboardSearchSecondNext,
    endDate,
    startDate,
    userData?.data.roles,
  ]);
  // Fetch filtered data on scroll //
  // Fetch data on scroll //
  const fetchData = useCallback(() => {
    switch (dashboard_api) {
      case "":
        break;

      default:
        let pageNo = Math.ceil(dashboardData.length / PAGE_LIMIT) + 1;
        setDashboardFilteredData([]);
        AxiosAPiGet(
          `${dashboard_api}${pageNo}`,
          "get",
          (res) => {
            const apiRes = res.data.data;
            const mergeData = [...dashboardData, ...apiRes];
            setDashboardData(mergeData);
            setLoading(false);
            setTotalUsers(res.data.total_users);
            setCurrentPage(res.data.current_page);
          },
          history,
          (err) => {
            setLoading(false);
          }
        );
        break;
    }
  }, [dashboardData, dashboard_api, history]);
  // Fetch data on scroll //
  // useEffect for load data on scroll //
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight
        ? window.innerHeight
        : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;
      if (document.documentElement.scrollTop < 2) {
        setShowButton(false);
      } else {
        setShowButton(true);
      }
      if (windowBottom >= docHeight - 1) {
        if (dashboardData.length !== 0 && totalUsers !== dashboardData.length) {
          fetchData();
        } else if (
          dashboardFilteredData.length !== 0 &&
          totalUsers !== dashboardFilteredData.length
        ) {
          fetchFilteredData();
        }
        //fetch new data (api call)and append to existing list
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    fetchData,
    dashboardData,
    totalUsers,
    dashboardFilteredData,
    fetchFilteredData,
  ]);
  // useEffect for load data on scroll //
  // Loading skeleton //
  const DashboardLoader = () => {
    return (
      <div id="section-2">
        <div className="row mt-5 style-4">
          {[...Array(4)].map((val, index) => {
            return (
              <Col
                className="text-center"
                lg="6"
                sm="6"
                key={index}
                id={`section-${index}`}
              >
                <Card
                  className={
                    rolePermissions?.includes("searchClient")
                      ? `text-left ${classes["admin_board"]}`
                      : "text-left customColor4 rounded-2 pt-4 superAdmin-card"
                  }
                >
                  <CardHeader className={classes["customer_name"]}>
                    {/* <h4 className="title d-inline mb-3">
                        {userData?.data?.roles[0] === "client"
                          ? "Data Statistics"
                          : `Customer (${dataClient?.name})`}
                      </h4> */}
                    <Skeleton
                      animation="wave"
                      variant="text"
                      width="40%"
                      className={classes["skeleton_header"]}
                    />
                  </CardHeader>
                  <CardBody className="">
                    <div className="table table-full-width">
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                              <p className="title">New Trial Sales</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="title">Attempted Rebills</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="title">Successful Rebills</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="title">Failed Rebills</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="title">Successful Rebill %</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="title">Active Customers</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="title">Revenue</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="title">Cancellations</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="title">Refunds</p>
                            </td>
                            <td className="td-actions text-right">
                              <Skeleton animation="wave" variant="text" />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </div>
      </div>
    );
  };

  const ChartLoader = () => {
    return (
      <div>
        <div className="my-2 my-md-4">
          <h1 className="darkText main-title">Customers</h1>
          <Row>
            <Col md="6" className="mb-4">
              <Card className={"card-chart card-skeleton border-rds mb-0"}>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  className="pie-chart-card-skeleton"
                />
              </Card>
            </Col>
            <Col md="6" className="mb-4">
              <Card
                className={
                  activeCustomerCount === 0
                    ? `card-chart ${classes["no_data_bg_customer"]} customColor5  border-rds mb-0`
                    : "card-chart customColor5 border-rds mb-0"
                }
              >
                <CardBody
                  className={
                    activeCustomerCount === 0 ? `${classes["blur_data"]}` : ""
                  }
                >
                  <div className="chart-area px-sm-4 py-2">
                    <div className="d-flex flex-wrap align-items-center justify-content-center actInactive-holder">
                      <div className="actInactCustomer2 activeCustomer2">
                        Active <span>Customers</span>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          className={classes["skeleton_activeInactval"]}
                        />
                      </div>
                      <div className="actInactCustomer2 inactiveCustomer2">
                        Inactive <span>Customers</span>
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          className={classes["skeleton_activeInactval"]}
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs="12">
            <h1 className="darkText main-title">New trial sales</h1>
            <Skeleton
              animation="wave"
              variant="rounded"
              className={classes["trial_sales_skeleton"]}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <h3 className="darkText sub-title">Successful Rebills</h3>
            <Skeleton
              animation="wave"
              variant="rounded"
              className={classes["successful_rebill_skeleton"]}
            />
          </Col>
          <Col lg="4">
            <h3 className="darkText sub-title">Failed Rebills</h3>
            <Skeleton
              animation="wave"
              variant="rounded"
              className={classes["failed_rebill_skeleton"]}
            />
          </Col>
          <Col lg="4">
            <h3 className="darkText sub-title">Attempted Rebills</h3>
            <Skeleton
              animation="wave"
              variant="rounded"
              className={classes["attempted_rebill_skeleton"]}
            />
          </Col>
        </Row>
      </div>
    );
  };
  const DashboardLoaderClient = () => {
    return (
      <div>
        <div className="row flex-wrap mt-4 mt-md-5">
          <Col lg="3" md="6" sm="6" xs="12">
            <Card className="card-stats">
              <CardBody className="p-0">
                <div className="d-flex align-items-center">
                  <div className="new-icon custom-bg text-center">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <div className="numbers">
                    <p className="card-category">Successful Rebills %</p>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes["dashboard_skeleton_val_text"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6" xs="12">
            <Card className="card-stats">
              <CardBody className="p-0">
                <div className="d-flex align-items-center">
                  <div className="new-icon custom-bg text-center">
                    <i className="fa-solid fa-arrow-trend-up"></i>
                  </div>
                  <div className="numbers">
                    <p className="card-category">Revenue</p>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes["dashboard_skeleton_val_text"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6" xs="12">
            <Card className="card-stats">
              <CardBody className="p-0">
                <div className="d-flex align-items-center">
                  <div className="new-icon custom-bg text-center">
                    <i className="fa-solid fa-xmark"></i>
                  </div>
                  <div className="numbers">
                    <p className="card-category">Cancellations</p>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes["dashboard_skeleton_val_text"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6" xs="12">
            <Card className="card-stats">
              <CardBody className="p-0">
                <div className="d-flex align-items-center">
                  <div
                    className={`new-icon custom-bg text-center ${classes["refunds_icon"]}`}
                  >
                    <TbReceiptRefund />
                  </div>
                  <div className="numbers">
                    <p className="card-category">Refunds</p>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes["dashboard_skeleton_val_text"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
        <div className="row flex-wrap">
          <Col lg="3" md="6" sm="6" xs="12">
            <Card className="card-stats">
              <CardBody className="p-0">
                <div className="d-flex align-items-center">
                  <div
                    className={`new-icon custom-bg text-center ${classes["trial_sales_icon"]}`}
                  >
                    {/* <i className="tim-icons icon-chat-33" /> */}
                    <i className="fa-sharp fa-solid fa-chart-simple"></i>
                  </div>
                  <div className="numbers">
                    <p className="card-category">Trial Sales</p>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes["dashboard_skeleton_val_text"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6" xs="12">
            <Card className="card-stats">
              <CardBody className="p-0">
                <div className="d-flex align-items-center">
                  <div className="new-icon custom-bg text-center">
                    <i className="fa-solid fa-check"></i>
                  </div>
                  <div className="numbers">
                    <p className="card-category">Successful Rebills</p>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes["dashboard_skeleton_val_text"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6" xs="12">
            <Card className="card-stats">
              <CardBody className="p-0">
                <div className="d-flex align-items-center">
                  <div className="new-icon custom-bg text-center">
                    <i className="fa-solid fa-exclamation"></i>
                  </div>
                  <div className="numbers">
                    <p className="card-category">Failed Rebills</p>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes["dashboard_skeleton_val_text"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="3" md="6" sm="6" xs="12">
            <Card className="card-stats">
              <CardBody className="p-0">
                <div className="d-flex align-items-center">
                  <div
                    className={`new-icon custom-bg text-center ${classes["attempted_rebill_icon"]}`}
                  >
                    <RiBillLine />
                  </div>
                  <div className="numbers">
                    <p className="card-category">Attempted Rebills</p>
                    <Skeleton
                      animation="wave"
                      variant="text"
                      className={classes["dashboard_skeleton_val_text"]}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </div>
      </div>
    );
  };
  // Loading skeleton //
  return (
    <div className="content dashboard-page">
      {rolePermissions?.includes("chart") && (
        <>
          {loadingChart ? (
            ChartLoader()
          ) : (
            <div>
              <div className="my-2 my-md-4">
                <h1 className="darkText main-title">Customers</h1>
                <Row>
                  <Col md="6" className="mb-4">
                    <Card
                      className={
                        activeCustomerCount === 0
                          ? `card-chart ${classes["no_data_bg_customer"]} customColor5  border-rds mb-0`
                          : "card-chart customColor5 border-rds mb-0"
                      }
                    >
                      <CardBody
                        className={
                          activeCustomerCount === 0
                            ? `${classes["blur_data"]}`
                            : ""
                        }
                      >
                        <div className="chart-area px-3 px-sm-4 py-2">
                          <div className="d-flex align-items-center">
                            <Col xs="6" className="px-0 px-sm-3">
                              <div className="actInactCustomer activeCustomer d-flex align-items-center">
                                <b></b>
                                <span>Active Customers</span>
                              </div>
                              <div className="actInactCustomer inactiveCustomer d-flex align-items-center">
                                <b></b>
                                <span>Inactive Customers</span>
                              </div>
                            </Col>
                            <Col xs="6" className="px-0 px-sm-3">
                              <div className="circle-chart">
                                <Doughnut
                                  data={chartExample9.data}
                                  options={chartExample9.options}
                                />
                                <div className="circle-chart-text">
                                  {activeCustomerCount === 0
                                    ? "--"
                                    : `${Math.round(
                                        (Number(activeCustomerCount) * 100) /
                                          (Number(activeCustomerCount) +
                                            Number(inactiveCustomerCount))
                                      )}`}
                                  <span>
                                    {activeCustomerCount === 0 ? "" : "%Active"}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6" className="mb-4">
                    <Card
                      className={
                        activeCustomerCount === 0
                          ? `card-chart ${classes["no_data_bg_customer"]} customColor5  border-rds mb-0`
                          : "card-chart customColor5 border-rds mb-0"
                      }
                    >
                      <CardBody
                        className={
                          activeCustomerCount === 0
                            ? `${classes["blur_data"]}`
                            : ""
                        }
                      >
                        <div className="chart-area px-sm-4 py-2">
                          <div className="d-flex flex-wrap align-items-center justify-content-center actInactive-holder">
                            <div className="actInactCustomer2 activeCustomer2">
                              Active <span>Customers</span>
                              <b>{activeCustomerCount}</b>
                            </div>
                            <div className="actInactCustomer2 inactiveCustomer2">
                              Inactive <span>Customers</span>
                              <b>{inactiveCustomerCount}</b>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs="12">
                  <h1 className="darkText main-title">New trial sales</h1>
                  <Card
                    className={
                      trialSalesData.length === 0 || trialSalesDataEmpty
                        ? `card-chart ${classes["no_data_bg"]} border-rds`
                        : `card-chart custom-bg border-rds`
                    }
                  >
                    {/* <CardHeader>
                <Row>
                 
                  <Col lg="12" sm="6">
                    <ButtonGroup
                      className="btn-group-toggle float-right"
                      data-toggle="buttons"
                    >
                      <Button
                        color="info"
                        id="0"
                        size="sm"
                        tag="label"
                        className={classNames("btn-simple", {
                          active: bigChartData === "data1"
                        })}
                        onClick={() => setBgChartData("data1")}
                      >
                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block"> 
                          Revenue
                        </span>
                        <span className="d-block d-sm-none">
                          <i className="tim-icons icon-single-02" />
                        </span>
                      </Button>
               
                    </ButtonGroup>
                  </Col>
                </Row>
              </CardHeader> */}
                    <CardBody>
                      <div className="chart-area px-4 py-2">
                        <Line
                          className="chart_success"
                          data={chartExample1["data"]}
                          options={chartExample1.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <h3 className="darkText sub-title">Successful Rebills</h3>
                  <Card
                    className={
                      rebillSuccessData.length === 0 || successRebillDataEmpty
                        ? `card-chart ${classes["no_data_bg"]} border-rds`
                        : `card-chart customColor2 border-rds`
                    }
                  >
                    {/* <CardHeader>
                <h5 className="card-category">Daily Sales</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-info" />{" "}
                  3,500€
                </CardTitle>
              </CardHeader> */}
                    <CardBody>
                      <div className="chart-area px-4 py-2">
                        <Bar
                          className="chart_success"
                          data={chartExample6.data}
                          options={chartExample6.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <h3 className="darkText sub-title">Failed Rebills</h3>
                  <Card
                    className={
                      rebillFailedData.length === 0 || failedRebillDataEmpty
                        ? `card-chart ${classes["no_data_bg"]} border-rds`
                        : `card-chart customColor3 border-rds`
                    }
                  >
                    {/* <CardHeader>
                <h5 className="card-category">Daily Sales</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-info" />{" "}
                  3,500€
                </CardTitle>
              </CardHeader> */}
                    <CardBody>
                      <div className="chart-area px-4 py-2">
                        <Line
                          className="chart_success"
                          data={chartExample7.data}
                          options={chartExample7.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <h3 className="darkText sub-title">Attempted Rebills</h3>
                  <Card
                    className={
                      rebillAttemptedData.length === 0 ||
                      attemptedRebillDataEmpty
                        ? `card-chart ${classes["no_data_bg"]} border-rds`
                        : `card-chart customColor4 border-rds`
                    }
                  >
                    {/* <CardHeader>
                <h5 className="card-category">Daily Sales</h5>
                <CardTitle tag="h3">
                  <i className="tim-icons icon-delivery-fast text-info" />{" "}
                  3,500€
                </CardTitle>
              </CardHeader> */}
                    <CardBody>
                      <div className="chart-area px-4 py-2">
                        <Line
                          className="chart_success"
                          data={chartExample5.data}
                          options={chartExample5.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className={userData?.data.roles[0] !== "client" ? "" : ""}>
        <Form>
          <div className="row align-items-end commonForm flex-wrap">
            <Col lg={userData?.data.roles[0] !== "client" ? "4" : "4"} sm="6">
              <FormGroup className="d-flex flex-column">
                <Label className="textColor">Start date</Label>
                <ReactDatePicker
                  name="created_start_date"
                  className={
                    userData?.data.roles[0] !== "client"
                      ? "form-control"
                      : "form-control"
                  }
                  selected={startDate}
                  value={startDate}
                  maxDate={new Date()}
                  onChange={(e) => handleStartDate(e)}
                  dateFormat="MM-dd-yyyy"
                  placeholderText={"Start Date"}
                  onKeyDown={(e) => handleKeyDownStartDate(e)}
                />
              </FormGroup>
            </Col>
            <Col lg={userData?.data.roles[0] !== "client" ? "4" : "4"} sm="6">
              <FormGroup className="d-flex flex-column">
                <Label className="textColor">End date</Label>

                <ReactDatePicker
                  className={
                    userData?.data.roles[0] !== "client"
                      ? "form-control"
                      : "form-control"
                  }
                  name="cancel_end_date"
                  type="date"
                  placeholderText={"End Date"}
                  selected={endDate}
                  value={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  dateFormat="MM-dd-yyyy"
                  // onKeyDown={(e) => handleKeyDownEndDate(e)}
                  onChange={(e) => handleEndDate(e)}
                  onKeyDown={(e) => handleKeyDownEndDate(e)}
                />
              </FormGroup>
            </Col>
            {rolePermissions?.includes("search") && (
              <Col lg="4" sm="12">
                <FormGroup>
                  <Label className="textColor">Client Status</Label>
                  <Select
                    className={
                      rolePermissions?.includes("search")
                        ? `w-100 ${classes["client_status_input"]}`
                        : "w-100"
                    }
                    value={clientStatus}
                    onChange={(e) => handleClientStatus(e)}
                    displayEmpty
                  >
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Deactivated">Deactivated</MenuItem>
                    <MenuItem value="Hold">Hold</MenuItem>
                    <MenuItem value="Pause">Pause</MenuItem>
                    <MenuItem value="">All Clients</MenuItem>
                  </Select>
                </FormGroup>
              </Col>
            )}
            <Col
              sm={rolePermissions?.includes("searchClient") ? "12" : "12"}
              lg={rolePermissions?.includes("searchClient") ? "4" : "12"}
              className={`mb-2 ${classes["search_filter"]} btn-set`}
            >
              <Button
                color="info"
                onClick={handleSearch}
                type="button"
                className={classes["search_btn"]}
                disabled={!startDate && !endDate && !clientStatusChange}
              >
                <FaSearch /> Search
              </Button>
              {rolePermissions?.includes("search") ? (
                <Button
                  className="custom-bg"
                  color="info"
                  onClick={() => resetSearch("")}
                >
                  <i className="fa-sharp fa-solid fa-rotate-left"></i> Reset
                </Button>
              ) : (
                <Button
                  className="custom-bg"
                  color="info"
                  onClick={() => resetSearchClient("")}
                >
                  <i className="fa-sharp fa-solid fa-rotate-left"></i> Reset
                </Button>
              )}
            </Col>
          </div>
        </Form>
        {rolePermissions?.includes("searchClient") ? (
          loadingClient ? (
            DashboardLoaderClient()
          ) : (
            <>
              {dashboardData?.length !== 0 &&
                dashboardData?.map((dataClient, i) => {
                  let trial_sales =
                    dataClient?.order_data?.order?.length > 0
                      ? `${dataClient?.order_data?.order[0]?.trial_sales}`
                      : "--";
                  let rebill =
                    dataClient?.order_data?.order?.length > 0
                      ? `${dataClient?.order_data?.order[0]?.rebill}`
                      : "--";
                  let failureRebill =
                    dataClient?.order_data?.order?.length > 0
                      ? `${dataClient?.order_data?.order[0]?.rebill_fail}`
                      : "--";
                  let rebillSuccess =
                    dataClient?.order_data?.order?.length > 0
                      ? `${dataClient?.order_data?.order[0]?.rebill_success}`
                      : "--";
                  let successRebillPercentage =
                    dataClient?.order_data?.order?.length > 0
                      ? isNaN(
                          (dataClient?.order_data?.order[0]?.rebill_success /
                            dataClient?.order_data?.order[0]?.rebill) *
                            100
                        )
                        ? "--"
                        : `${(
                            (dataClient?.order_data?.order[0]?.rebill_success /
                              dataClient?.order_data?.order[0]?.rebill) *
                            100
                          ).toFixed(2)}`
                      : "--";
                  let revenue =
                    dataClient?.client_type === "api"
                      ? dataClient?.order_data?.order?.length > 0
                        ? `${(
                            dataClient?.active_customers *
                            Number(dataClient?.lead_price)
                          ).toFixed(2)}`
                        : "--"
                      : dataClient?.order_data?.order?.length > 0
                      ? `${(
                          dataClient?.order_data?.order[0]?.rebill_success *
                          Number(dataClient?.lead_price)
                        ).toFixed(2)}`
                      : "--";
                  let cancel =
                    dataClient?.order_data?.order?.length > 0
                      ? `${dataClient?.order_data?.order[0]?.cancel}`
                      : "--";
                  let refund =
                    dataClient?.order_data?.order?.length > 0
                      ? `${dataClient?.order_data?.order[0]?.refund}`
                      : "--";
                  return (
                    <div key={i}>
                      <div className="row flex-wrap mt-4 mt-md-5">
                        <Col lg="3" md="6" sm="6" xs="12">
                          <Card
                            className={
                              successRebillPercentage === "--" ||
                              Number(successRebillPercentage) === 0
                                ? `card-stats ${classes["no_data_bg"]} border-rds ${classes["card_no_data"]}`
                                : "card-stats"
                            }
                          >
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center">
                                <div className="new-icon custom-bg text-center">
                                  <i className="fa-solid fa-check"></i>
                                </div>
                                <div className="numbers">
                                  <p className="card-category">
                                    Successful Rebills %
                                  </p>
                                  <CardTitle
                                    tag="h3"
                                    className={
                                      successRebillPercentage === "--" ||
                                      Number(successRebillPercentage) === 0
                                        ? `darkText ${classes["no_data_title"]}`
                                        : "darkText"
                                    }
                                  >
                                    {successRebillPercentage === "--" ||
                                    Number(successRebillPercentage) === 0
                                      ? "No Data Available*"
                                      : Number(
                                          successRebillPercentage
                                        ).toLocaleString("en-US")}
                                  </CardTitle>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6" xs="12">
                          <Card
                            className={
                              revenue === "--" || Number(revenue) === 0
                                ? `card-stats ${classes["no_data_bg"]} border-rds ${classes["card_no_data"]}`
                                : "card-stats"
                            }
                          >
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center">
                                <div className="new-icon custom-bg text-center">
                                  <i className="fa-solid fa-arrow-trend-up"></i>
                                </div>
                                <div className="numbers">
                                  <p className="card-category">Revenue</p>
                                  <CardTitle
                                    tag="h3"
                                    className={
                                      revenue === "--" || Number(revenue) === 0
                                        ? `darkText ${classes["no_data_title"]}`
                                        : "darkText"
                                    }
                                  >
                                    {revenue === "--" || Number(revenue) === 0
                                      ? "No Data Available*"
                                      : `$${Number(revenue)?.toLocaleString(
                                          "en-US"
                                        )}`}
                                  </CardTitle>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6" xs="12">
                          <Card
                            className={
                              cancel === "--" || Number(cancel) === 0
                                ? `card-stats ${classes["no_data_bg"]} border-rds ${classes["card_no_data"]}`
                                : "card-stats"
                            }
                          >
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center">
                                <div className="new-icon custom-bg text-center">
                                  <i className="fa-solid fa-xmark"></i>
                                </div>
                                <div className="numbers">
                                  <p className="card-category">Cancellations</p>
                                  <CardTitle
                                    tag="h3"
                                    className={
                                      cancel === "--" || Number(cancel) === 0
                                        ? `darkText ${classes["no_data_title"]}`
                                        : "darkText"
                                    }
                                  >
                                    {cancel === "--" || Number(cancel) === 0
                                      ? "No Data Available*"
                                      : Number(cancel).toLocaleString("en-US")}
                                  </CardTitle>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6" xs="12">
                          <Card
                            className={
                              refund === "--" || Number(refund) === 0
                                ? `card-stats ${classes["no_data_bg"]} border-rds ${classes["card_no_data"]}`
                                : "card-stats"
                            }
                          >
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center">
                                <div
                                  className={`new-icon custom-bg text-center ${classes["refunds_icon"]}`}
                                >
                                  <TbReceiptRefund />
                                </div>
                                <div className="numbers">
                                  <p className="card-category">Refunds</p>
                                  <CardTitle
                                    tag="h3"
                                    className={
                                      refund === "--" || Number(refund) === 0
                                        ? `darkText ${classes["no_data_title"]}`
                                        : "darkText"
                                    }
                                  >
                                    {refund === "--" || Number(refund) === 0
                                      ? "No Data Available*"
                                      : Number(refund).toLocaleString("en-US")}
                                  </CardTitle>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </div>
                      <div className="row flex-wrap">
                        <Col lg="3" md="6" sm="6" xs="12">
                          <Card
                            className={
                              trial_sales === "--" || Number(trial_sales) === 0
                                ? `card-stats ${classes["no_data_bg"]} border-rds ${classes["card_no_data"]}`
                                : "card-stats"
                            }
                          >
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center">
                                <div
                                  className={`new-icon custom-bg text-center ${classes["trial_sales_icon"]}`}
                                >
                                  {/* <i className="tim-icons icon-chat-33" /> */}
                                  <i className="fa-sharp fa-solid fa-chart-simple"></i>
                                </div>
                                <div className="numbers">
                                  <p className="card-category">Trial Sales</p>
                                  <CardTitle
                                    tag="h3"
                                    className={
                                      trial_sales === "--" ||
                                      Number(trial_sales) === 0
                                        ? `darkText ${classes["no_data_title"]}`
                                        : "darkText"
                                    }
                                  >
                                    {trial_sales === "--" ||
                                    Number(trial_sales) === 0
                                      ? "No Data Available*"
                                      : Number(trial_sales).toLocaleString(
                                          "en-US"
                                        )}
                                  </CardTitle>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6" xs="12">
                          <Card
                            className={
                              rebillSuccess === "--" ||
                              Number(rebillSuccess) === 0
                                ? `card-stats ${classes["no_data_bg"]} border-rds ${classes["card_no_data"]}`
                                : "card-stats"
                            }
                          >
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center">
                                <div className="new-icon custom-bg text-center">
                                  <i className="fa-solid fa-check"></i>
                                </div>
                                <div className="numbers">
                                  <p className="card-category">
                                    Successful Rebills
                                  </p>
                                  <CardTitle
                                    tag="h3"
                                    className={
                                      rebillSuccess === "--" ||
                                      Number(rebillSuccess) === 0
                                        ? `darkText ${classes["no_data_title"]}`
                                        : "darkText"
                                    }
                                  >
                                    {rebillSuccess === "--" ||
                                    Number(rebillSuccess) === 0
                                      ? "No Data Available*"
                                      : Number(rebillSuccess).toLocaleString(
                                          "en-US"
                                        )}
                                  </CardTitle>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6" xs="12">
                          <Card
                            className={
                              failureRebill === "--" ||
                              Number(failureRebill) === 0
                                ? `card-stats ${classes["no_data_bg"]} border-rds ${classes["card_no_data"]}`
                                : "card-stats"
                            }
                          >
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center">
                                <div className="new-icon custom-bg text-center">
                                  <i className="fa-solid fa-exclamation"></i>
                                </div>
                                <div className="numbers">
                                  <p className="card-category">
                                    Failed Rebills
                                  </p>
                                  <CardTitle
                                    tag="h3"
                                    className={
                                      failureRebill === "--" ||
                                      Number(failureRebill) === 0
                                        ? `darkText ${classes["no_data_title"]}`
                                        : "darkText"
                                    }
                                  >
                                    {failureRebill === "--" ||
                                    Number(failureRebill) === 0
                                      ? "No Data Available*"
                                      : Number(failureRebill).toLocaleString(
                                          "en-US"
                                        )}
                                  </CardTitle>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="3" md="6" sm="6" xs="12">
                          <Card
                            className={
                              rebill === "--" || Number(rebill) === 0
                                ? `card-stats ${classes["no_data_bg"]} border-rds ${classes["card_no_data"]}`
                                : "card-stats"
                            }
                          >
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center">
                                <div
                                  className={`new-icon custom-bg text-center ${classes["attempted_rebill_icon"]}`}
                                >
                                  <RiBillLine />
                                </div>
                                <div className="numbers">
                                  <p className="card-category">
                                    Attempted Rebills
                                  </p>
                                  <CardTitle
                                    tag="h3"
                                    className={
                                      rebill === "--" || Number(rebill) === 0
                                        ? `darkText ${classes["no_data_title"]}`
                                        : "darkText"
                                    }
                                  >
                                    {rebill === "--" || Number(rebill) === 0
                                      ? "No Data Available*"
                                      : Number(rebill).toLocaleString("en-US")}
                                  </CardTitle>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </div>
                    </div>
                  );
                })}

              <footer className={classes["footer_position"]}>
                <p className={classes["footer_color"]}>
                  *Please configure the proper webhooks to gain more insights
                  through your Bawlio Dashboard.
                </p>
              </footer>
            </>
          )
        ) : loading ? (
          DashboardLoader()
        ) : (
          <div id="section-2">
            {dashboardFilteredData?.length !== 0 ? (
              <div
                className={
                  dashboardFilteredData?.length !== 0
                    ? "row mt-5 style-4"
                    : "row mt-5 style-4 d-flex justify-content-center"
                }
              >
                {dashboardFilteredData?.length !== 0 ? (
                  dashboardFilteredData?.map((dataClient, i) => {
                    let trial_sales =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.trial_sales}`
                        : "--";
                    let rebill =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.rebill}`
                        : "--";
                    let successRebill =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.rebill_success}`
                        : "--";
                    let failureRebill =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.rebill_fail}`
                        : "--";
                    let successRebillPercentage =
                      dataClient?.order_data?.order?.length > 0
                        ? isNaN(
                            (dataClient?.order_data?.order[0]?.rebill_success /
                              dataClient?.order_data?.order[0]?.rebill) *
                              100
                          )
                          ? "--"
                          : `${(
                              (dataClient?.order_data?.order[0]
                                ?.rebill_success /
                                dataClient?.order_data?.order[0]?.rebill) *
                              100
                            ).toFixed(2)}`
                        : "--";
                    let revenue =
                      dataClient?.client_type === "api"
                        ? dataClient?.order_data?.order?.length > 0
                          ? `${(
                              dataClient?.active_customers *
                              Number(dataClient?.lead_price)
                            ).toFixed(2)}`
                          : "--"
                        : dataClient?.order_data?.order?.length > 0
                        ? `${(
                            dataClient?.order_data?.order[0]?.rebill_success *
                            Number(dataClient?.lead_price)
                          ).toFixed(2)}`
                        : "--";
                    let cancel =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.cancel}`
                        : "--";
                    let refund =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.refund}`
                        : "--";
                    let active_customers = dataClient?.active_customers
                      ? dataClient?.active_customers
                      : "--";
                    return (
                      <Col
                        className="text-center"
                        lg="6"
                        sm="6"
                        key={i}
                        id={`section-${i}`}
                      >
                        <Card
                          className={
                            rolePermissions?.includes("searchClient")
                              ? `text-left ${classes["admin_board"]}`
                              : "text-left customColor4 rounded-2 pt-4 superAdmin-card"
                          }
                        >
                          <CardHeader className={classes["customer_name"]}>
                            {/* <h4 className="title d-inline mb-3">
                              {userData?.data?.roles[0] === "client"
                                ? "Data Statistics"
                                : `Customer (${dataClient?.name})`}
                            </h4> */}
                            {rolePermissions?.includes("searchClient") ? (
                              <h4 className="title d-inline mb-3">
                                Data Statistics
                              </h4>
                            ) : (
                              <h4>
                                <b>
                                  <em>
                                    {dataClient?.company_name
                                      ? dataClient?.company_name
                                      : "--"}
                                  </em>
                                </b>
                              </h4>
                            )}
                          </CardHeader>
                          <CardBody className="">
                            <div className="table table-full-width">
                              <Table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p className="title">New Trial Sales</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip170482171"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {trial_sales === "--"
                                            ? "--"
                                            : Number(
                                                trial_sales
                                              ).toLocaleString("en-US")}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Attempted Rebills</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip720626938"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {rebill === "--"
                                            ? "--"
                                            : Number(rebill).toLocaleString(
                                                "en-US"
                                              )}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">
                                        Successful Rebills
                                      </p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip598446371"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {successRebill === "--"
                                            ? "--"
                                            : Number(
                                                successRebill
                                              ).toLocaleString("en-US")}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Failed Rebills</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip797367748"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {failureRebill === "--"
                                            ? "--"
                                            : Number(
                                                failureRebill
                                              ).toLocaleString("en-US")}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <p className="title">Total</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>{dataClient?.total_customers}</p>
                                      </Button>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>
                                      <p className="title">
                                        Successful Rebill %
                                      </p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {successRebillPercentage === "--"
                                            ? "--"
                                            : Number(
                                                successRebillPercentage
                                              ).toLocaleString("en-US")}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Active Customers</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {active_customers === "--"
                                            ? "--"
                                            : active_customers?.toLocaleString(
                                                "en-US"
                                              )}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Revenue</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {revenue === "--"
                                            ? "--"
                                            : `$${Number(
                                                revenue
                                              )?.toLocaleString("en-US")}`}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Cancellations</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {cancel === "--"
                                            ? "--"
                                            : Number(cancel).toLocaleString(
                                                "en-US"
                                              )}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Refunds</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {refund === "--"
                                            ? "--"
                                            : Number(refund).toLocaleString(
                                                "en-US"
                                              )}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <p className="title">Charge Backs</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>$0</p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Charge Back Fee</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>$0</p>
                                      </Button>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <div className="text-dark">No data found</div>
                )}
              </div>
            ) : (
              <div
                className={
                  dashboardData?.length !== 0
                    ? "row mt-5 style-4"
                    : "row mt-5 style-4 d-flex justify-content-center"
                }
              >
                {" "}
                {dashboardData?.length !== 0 ? (
                  dashboardData?.map((dataClient, i) => {
                    let trial_sales =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.trial_sales}`
                        : "--";
                    let rebill =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.rebill}`
                        : "--";
                    let successRebill =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.rebill_success}`
                        : "--";
                    let failureRebill =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.rebill_fail}`
                        : "--";
                    let successRebillPercentage =
                      dataClient?.order_data?.order?.length > 0
                        ? isNaN(
                            (dataClient?.order_data?.order[0]?.rebill_success /
                              dataClient?.order_data?.order[0]?.rebill) *
                              100
                          )
                          ? "--"
                          : `${(
                              (dataClient?.order_data?.order[0]
                                ?.rebill_success /
                                dataClient?.order_data?.order[0]?.rebill) *
                              100
                            ).toFixed(2)}`
                        : "--";
                    let revenue =
                      dataClient?.client_type === "api"
                        ? dataClient?.order_data?.order?.length > 0
                          ? `${(
                              dataClient?.active_customers *
                              Number(dataClient?.lead_price)
                            ).toFixed(2)}`
                          : "--"
                        : dataClient?.order_data?.order?.length > 0
                        ? `${(
                            dataClient?.order_data?.order[0]?.rebill_success *
                            Number(dataClient?.lead_price)
                          ).toFixed(2)}`
                        : "--";
                    let cancel =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.cancel}`
                        : "--";
                    let refund =
                      dataClient?.order_data?.order?.length > 0
                        ? `${dataClient?.order_data?.order[0]?.refund}`
                        : "--";
                    let active_customers = dataClient?.active_customers
                      ? dataClient?.active_customers
                      : "--";
                    return (
                      <Col
                        className="text-center"
                        lg="6"
                        sm="6"
                        key={i}
                        id={`section-${i}`}
                      >
                        <Card
                          className={
                            rolePermissions?.includes("searchClient")
                              ? `text-left ${classes["admin_board"]}`
                              : "text-left customColor4 rounded-2 pt-4 superAdmin-card"
                          }
                        >
                          <CardHeader className={classes["customer_name"]}>
                            {/* <h4 className="title d-inline mb-3">
                              {userData?.data?.roles[0] === "client"
                                ? "Data Statistics"
                                : `Customer (${dataClient?.name})`}
                            </h4> */}
                            {rolePermissions?.includes("searchClient") ? (
                              <h4 className="title d-inline mb-3">
                                Data Statistics
                              </h4>
                            ) : (
                              <h4>
                                <b>
                                  <em>
                                    {dataClient?.company_name
                                      ? dataClient?.company_name
                                      : "--"}
                                  </em>
                                </b>
                              </h4>
                            )}
                          </CardHeader>
                          <CardBody className="">
                            <div className="table table-full-width">
                              <Table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <p className="title">New Trial Sales</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip170482171"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {trial_sales === "--"
                                            ? "--"
                                            : Number(
                                                trial_sales
                                              ).toLocaleString("en-US")}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Attempted Rebills</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip720626938"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {rebill === "--"
                                            ? "--"
                                            : Number(rebill).toLocaleString(
                                                "en-US"
                                              )}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">
                                        Successful Rebills
                                      </p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip598446371"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {successRebill === "--"
                                            ? "--"
                                            : Number(
                                                successRebill
                                              ).toLocaleString("en-US")}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Failed Rebills</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip797367748"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {failureRebill === "--"
                                            ? "--"
                                            : Number(
                                                failureRebill
                                              ).toLocaleString("en-US")}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">
                                        Successful Rebill %
                                      </p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {successRebillPercentage === "--"
                                            ? "--"
                                            : Number(
                                                successRebillPercentage
                                              ).toLocaleString("en-US")}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Active Customers</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {active_customers === "--"
                                            ? "--"
                                            : active_customers?.toLocaleString(
                                                "en-US"
                                              )}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Revenue</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {revenue === "--"
                                            ? "--"
                                            : `$${Number(
                                                revenue
                                              )?.toLocaleString("en-US")}`}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Cancellations</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {cancel === "--"
                                            ? "--"
                                            : Number(cancel).toLocaleString(
                                                "en-US"
                                              )}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Refunds</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>
                                          {refund === "--"
                                            ? "--"
                                            : Number(refund).toLocaleString(
                                                "en-US"
                                              )}
                                        </p>
                                      </Button>
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>
                                      <p className="title">Charge Backs</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>$0</p>
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <p className="title">Charge Back Fee</p>
                                    </td>
                                    <td className="td-actions text-right">
                                      <Button
                                        color="link"
                                        id="tooltip147107903"
                                        title=""
                                        type="button"
                                      >
                                        <p>$0</p>
                                      </Button>
                                    </td>
                                  </tr> */}
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })
                ) : (
                  <div className="text-dark">No data found</div>
                )}
              </div>
            )}
          </div>
        )}
        {totalUsers !==
          (dashboardData.length !== 0
            ? dashboardData.length
            : dashboardFilteredData.length) &&
          currentPage !== 0 && (
            <div className="text-center">
              <CircularProgress />
            </div>
          )}
      </div>

      {showButton && (
        <button className="back-to-top" onClick={scrollToTop}>
          <FaArrowCircleUp />
        </button>
      )}
    </div>
  );
}

export default AdminDashboard;
