import { Dialog, DialogContent, FormGroup } from "@mui/material";
import React, { useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import classes from "../../assets/css/SubUserPasswordUpdate.module.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import { AxiosAPiPost } from "AxiosAPi";
import { useHistory } from "react-router-dom";
import { SubUserPasswordUpdateSchema } from "utils/schemas/SubUserPasswordUpdateSchema";
function SubUserPasswordUpdateForm({
  onAlertShow,
  onCloseUpdatePasswordDialog,
  getSubUsersList,
  openPasswordUpdate,
  updateId,
}) {
  // Use to redirect to client list page after form submission //
  const history = useHistory();
  // Use to redirect to client list page after form submission //
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // Loading state //
  const [loading, setLoading] = useState(false);
  // Loading state //
  // Initial values //
  const initialValues = {
    new_password: "",
    confirm_new_password: "",
  };
  // Initial values //
  // Sub-user password update form submission and error handling //
  const { values, errors, touched, handleChange, handleSubmit, handleBlur } =
    useFormik({
      initialValues,
      validationSchema: SubUserPasswordUpdateSchema,
      onSubmit: async (values, action) => {
        const data = {
          id: updateId,
          new_password: values.new_password,
          confirm_new_password: values.confirm_new_password,
        };
        setLoading(true);
        AxiosAPiPost(
          `${process.env.REACT_APP_AXIOS_BASE_URL}/subusers/updateLoginDetails`,
          "post",
          data,
          (res) => {
            if (res.data.status === true) {
              onAlertShow(res.data.message, "success");
              setLoading(false);
              getSubUsersList();
              onCloseUpdatePasswordDialog();
              action.resetForm();
            } else {
              AlertShow(res.data.message, "warning");
              setLoading(false);
            }
          },
          history,
          (err) => {
            AlertShow(err.response.data.message, "warning");
            setLoading(false);
          }
        );
      },
    });
  // Sub-user password update form submission and error handling //
  // Function for close dialog //
  const handleClose = () => {
    onCloseUpdatePasswordDialog();
  };
  // Function for close dialog //
  // Generalize function for notification alert //
  const AlertShow = (message, type) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // Generalize function for notification alert //
  return (
    <div>
      <Dialog
        open={openPasswordUpdate}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
          },
        }}
      >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <DialogContent dividers className="px-0 px-sm-3 px-md-4">
          <div className={`popupForm-holder ${classes["dialog_content"]}`}>
            <Row>
              <Col md="12">
                <Form
                  id="RegisterValidation"
                  onSubmit={handleSubmit}
                  className="commonForm popupForm"
                >
                  <Card className="customColor4">
                    <CardHeader>
                      <CardTitle
                        tag="h4"
                        className={`darkText labelText mb-0 ${classes["view_icon_cross"]}`}
                      >
                        Update Password Form{" "}
                        <i
                          onClick={handleClose}
                          className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
                        ></i>
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">New Password *</label>
                        <Input
                          className="bg-white"
                          name="new_password"
                          placeholder="Enter new password"
                          type="password"
                          value={values.new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.new_password && touched.new_password ? (
                          <p className={classes["form_error"]}>
                            {errors.new_password}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Confirm New Password *
                        </label>
                        <Input
                          className="bg-white"
                          name="confirm_new_password"
                          placeholder="Re enter new password"
                          type="password"
                          value={values.confirm_new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.confirm_new_password &&
                        touched.confirm_new_password ? (
                          <p className={classes["form_error"]}>
                            {errors.confirm_new_password}
                          </p>
                        ) : null}
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="text-center btn-set">
                      <Button color="info">
                        {loading ? "Processing..." : "Update"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default SubUserPasswordUpdateForm;
