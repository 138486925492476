import * as Yup from "yup";

export const NotificationSchema = Yup.object().shape(
  {
    role: Yup.string().required("Notification role field can not be blank"),
    client_type: Yup.string()
      .ensure()
      .when("role", {
        is: (role) => role === "client",
        then: Yup.string().required("Client type field can not be blank"),
        otherwise: Yup.string().notRequired(),
      }),
    user_string: Yup.string()
      .ensure()
      .when(["role", "client_type"], {
        is: (role, client_type) => role === "client" && client_type === "",
        then: Yup.string().required("Please choose a client type first"),
        otherwise: Yup.string().required(
          "Notification list field can not be blank"
        ),
      }),
    notification: Yup.string().required(
      "Notification content field can not be blank"
    ),
  },
  [["client_type", "user_string"]]
);
