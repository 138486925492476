import { useFormik } from "formik";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import classes from "../../assets/css/UserProfile.module.css";
import NotificationAlert from "react-notification-alert";
import { UpdateProfileSchema } from "utils/schemas/UpdateProfileSchema";
import { AxiosAPiPost } from "AxiosAPi";
import { AxiosAPiGet } from "AxiosAPi";
import { Skeleton, Switch } from "@mui/material";
import MaskedInput from "react-text-mask";
import ReferenceDataContext from "AvatarContext";
// import ReferenceDataContext from "AvatarContext";
const User = () => {
  // permissions list based on different roles & store avatar //
  const { setAvatarUrl, rolePermissions } = useContext(ReferenceDataContext);
  // permissions list based on different roles & store avatar //
  // Notification popup
  const notificationAlertRef = useRef(null);
  // Notification popup
  // Input for handle clicking action on avatar //
  const inputRef = useRef(null);
  // Input for handle clicking action on avatar //
  // Use to redirect //
  const history = useHistory();
  // Use to redirect //
  // Input for take avatar url //
  const [fileUploadUrl, setFileUploadUrl] = useState(
    require("../../assets/img/user-avtar.png")
  );
  // Input for take avatar url //
  // Loading state //
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    enable_invoice_email: false,
    clientId: "",
    company_name: "",
    roles: "",
    account_name: "",
  });
  // Loading state //
  // Initalize input fields //
  const initialValues = {
    first_name: profileDetails.first_name,
    last_name: profileDetails.last_name,
    email: profileDetails.email,
    phone: profileDetails.phone,
    files: null,
    enable_invoice_email: profileDetails.enable_invoice_email,
  };
  // Initalize input fields //
  // Form data submission and validation //
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UpdateProfileSchema,
    onSubmit: (values, action) => {
      var formData = new FormData();
      formData.append("files", values.files);
      setLoadingButton(true);
      AxiosAPiPost(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/auth/update-profile`,
        "post",
        values,
        (response) => {
          if (response.data.status === true) {
            let options = {};
            options = {
              place: "tr",
              message: response.data.message,
              type: "success",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 3,
            };

            notificationAlertRef.current.notificationAlert(options);
            setLoadingButton(false);
            fetchProfileDetails();
          } else {
            let options = {};
            options = {
              place: "tr",
              message: response.data.message,
              type: "warning",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 3,
            };
            notificationAlertRef.current.notificationAlert(options);
            setLoadingButton(false);
          }
        }
      );
    },
  });
  // Form data submission and validation //
  const fetchProfileDetails = useCallback(() => {
    setLoading(true);
    AxiosAPiGet(
      `${process.env.REACT_APP_AXIOS_BASE_URL}/auth/profile`,
      "get",
      (res) => {
        setLoading(false);
        setFieldValue("first_name", res.data?.first_name);
        setFieldValue("last_name", res.data?.last_name);
        setFieldValue("email", res.data?.email);
        setFieldValue("phone", res.data?.phone);
        setFieldValue("enable_invoice_email", res.data?.enable_invoice_email);
        setProfileDetails({
          first_name: res.data?.first_name,
          last_name: res.data?.last_name,
          email: res.data?.email,
          phone: res.data?.phone,
          enable_invoice_email: res.data?.enable_invoice_email,
          clientId: res.data?.clientId,
          company_name: res.data?.company_name,
          roles: res.data?.roles[0],
          account_name: res.data?.name,
        });
        if (
          res.data.avatar !==
          "http://localhost:3001/api/uploads/user/default.png"
        ) {
          setFileUploadUrl(res.data?.avatar);
          setAvatarUrl(res.data?.avatar);
        }
      },
      history,
      (err) => {
        let options = {};
        options = {
          place: "tr",
          message: err.response.data.message,
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
        setLoading(false);
      }
    );
  }, [history, setFieldValue, setAvatarUrl]);
  useEffect(() => {
    fetchProfileDetails();
  }, [fetchProfileDetails]);
  // Avatar click action functionality //
  const handleClick = () => {
    inputRef.current.click();
  };
  // Avatar click action functionality //
  // Change avatar image functionality //

  const handleFileChange = (e) => {
    if (e.target.files) {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/gif"
      ) {
        if (e.target.files[0].size <= 2097152) {
          setFieldValue("files", e.target.files[0]);
          setFileUploadUrl(URL.createObjectURL(e.target.files[0]));
        } else {
          let options = {};
          options = {
            place: "tr",
            message: "The file is too large, Maximum allowed file size is 2MB",
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef.current.notificationAlert(options);
        }
      } else {
        let options = {};
        options = {
          place: "tr",
          message: `Sorry, ${e.target.files[0].name} could not be uploaded,Only files with extension jpeg, jpg, png, gif are allowed`,
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
      }
    }
  };
  // Change avatar image functionality //
  // Skeleton loader //
  const UserProfileLoader = () => {
    return (
      <div className="customColor4 p-3 p-sm-4 rounded-2">
        <Row>
          <Col md="8">
            <Card className="customColor6 rounded-2 commonForm py-3 px-sm-3 mb-0">
              <p className="select-title textColor pt-1 pt-md-2 px-3">
                Profile
              </p>
              <CardBody>
                <Row>
                  {rolePermissions?.includes("viewProfile") && (
                    <Col className="pr-md-1 mb-4" sm="8">
                      <label className="darkText labelText">Account Name</label>

                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        className={classes["acc_name_skeleton"]}
                      />
                    </Col>
                  )}
                  {rolePermissions?.includes("viewProfile") && (
                    <Col className="pr-md-1 mb-4" sm="4">
                      <label className="darkText labelText">Client Id</label>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        className={classes["clientId_skeleton"]}
                      />
                    </Col>
                  )}

                  <Col className="pr-md-1" sm="6">
                    <FormGroup>
                      <label className="darkText labelText">First Name</label>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        className={classes["first_name_skeleton"]}
                      />
                    </FormGroup>
                  </Col>
                  <Col className="pl-md-1 mt-3 mt-sm-0" sm="6">
                    <FormGroup>
                      <label className="darkText labelText">Last Name</label>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        className={classes["last_name_skeleton"]}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3 mt-md-4">
                    <FormGroup>
                      <label className="darkText labelText">
                        Email address
                      </label>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        className={classes["email_skeleton"]}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-md-1 mt-3 mt-md-4" sm="6">
                    <FormGroup>
                      <label className="darkText labelText">Phone number</label>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        className={classes["phone_number_skeleton"]}
                      />
                    </FormGroup>
                  </Col>
                  {rolePermissions?.includes("viewProfile") && (
                    <Col className="pl-md-1 mt-3 mt-md-4" sm="6">
                      <label className="darkText labelText">Company Name</label>
                      <Skeleton
                        animation="wave"
                        variant="rounded"
                        className={classes["company_name_skeleton"]}
                      />
                    </Col>
                  )}
                </Row>
                {!rolePermissions?.includes("viewProfile") && (
                  <Row>
                    <Col className="pr-md-1 mt-3 mt-md-4" sm="6">
                      <FormGroup className="d-flex flex-column">
                        <label className="darkText labelText">
                          Receive Invoice Email
                        </label>

                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          className={classes["invoice_email_skeleton"]}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </CardBody>
              {!rolePermissions?.includes("viewProfile") && (
                <CardFooter className="btn-set pt-0">
                  <Button
                    // className="btn-fill"
                    color="info"
                    type="submit"
                    disabled={true}
                  >
                    {loadingButton ? "Processing..." : "Update"}
                  </Button>
                </CardFooter>
              )}
            </Card>
          </Col>
          <Col md="4" className="mt-4 mt-md-0">
            <Card className="card-user customColor6 rounded-2 p-3 mb-0">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <span
                    className={
                      !rolePermissions?.includes("viewProfile")
                        ? `${classes.profilepic} ${classes["pointer_show"]}`
                        : `${classes.profilepic} ${classes["pointer_hide"]}`
                    }
                  >
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      className={classes["skeleton_avatar"]}
                    />
                  </span>
                  <p className="select-title">
                    <Skeleton animation="wave" variant="text" />
                  </p>
                  <p className="description textColor">
                    <Skeleton animation="wave" variant="text" />
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
  function rolesFunction() {
    let roles_value;
    switch (profileDetails.roles) {
      case "super_admin":
        roles_value = <p className="description textColor">Super admin</p>;
        break;
      case "developer":
        roles_value = <p className="description textColor">Developer</p>;
        break;
      case "client":
        roles_value = <p className="description textColor">Client</p>;
        break;
      case "sub_client":
        roles_value = <p className="description textColor">Sub client</p>;
        break;
      default:
        roles_value = <p className="description textColor">Admin</p>;
        break;
    }
    return roles_value;
  }
  // Skeleton loader //
  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        {loading ? (
          UserProfileLoader()
        ) : (
          <div className="customColor4 p-3 p-sm-4 rounded-2">
            <Form
              onSubmit={handleSubmit}
              id="RegisterValidation"
              className="commonForm popupForm"
            >
              <Row>
                <Col md="8">
                  <Card className="customColor6 rounded-2 commonForm py-3 px-sm-3 mb-0">
                    <p className="select-title textColor pt-1 pt-md-2 px-3">
                      Profile
                    </p>
                    <CardBody>
                      <Row>
                        {rolePermissions?.includes("viewProfile") && (
                          <Col className="pr-md-1 mb-4" sm="8">
                            <label className="darkText labelText">
                              Account Name
                            </label>
                            <Input
                              id="account_name"
                              className={`bg-white ${classes["account_name_style"]}`}
                              value={profileDetails.account_name}
                              type="text"
                              readOnly
                            />
                          </Col>
                        )}
                        {rolePermissions?.includes("viewProfile") && (
                          <Col className="pr-md-1 mb-4" sm="4">
                            <label className="darkText labelText">
                              Client Id
                            </label>
                            <Input
                              id="client_id"
                              className={`bg-white ${classes["client_id_style"]}`}
                              value={profileDetails.clientId}
                              type="text"
                              readOnly
                            />
                          </Col>
                        )}

                        <Col className="pr-md-1" sm="6">
                          <FormGroup>
                            <label className="darkText labelText">
                              First Name
                            </label>
                            <Input
                              id="first_name"
                              value={values.first_name}
                              type="text"
                              className={`bg-white ${classes["first_name_style"]}`}
                              readOnly={rolePermissions?.includes(
                                "viewProfile"
                              )}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {errors.first_name && touched.first_name ? (
                            <p className={classes["form_error"]}>
                              {errors.first_name}
                            </p>
                          ) : null}
                        </Col>
                        <Col className="pl-md-1 mt-3 mt-sm-0" sm="6">
                          <FormGroup>
                            <label className="darkText labelText">
                              Last Name
                            </label>
                            <Input
                              id="last_name"
                              value={values.last_name}
                              type="text"
                              className={`bg-white &{classes['last_name_style']}`}
                              readOnly={rolePermissions?.includes(
                                "viewProfile"
                              )}
                              onChange={handleChange}
                            />
                          </FormGroup>
                          {errors.last_name && touched.last_name ? (
                            <p className={classes["form_error"]}>
                              {errors.last_name}
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="mt-3 mt-md-4">
                          <FormGroup>
                            <label className="darkText labelText">
                              Email address
                            </label>
                            <Input
                              id="email"
                              className={`bg-white ${classes["email_style"]}`}
                              placeholder=""
                              type="email"
                              value={values.email}
                              readOnly
                              onBlur={handleBlur}
                            />
                          </FormGroup>
                          {errors.email && touched.email ? (
                            <p className={classes["form_error"]}>
                              {errors.email}
                            </p>
                          ) : null}
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pr-md-1 mt-3 mt-md-4" sm="6">
                          <FormGroup>
                            <label className="darkText labelText">
                              Phone number
                            </label>
                            <MaskedInput
                              mask={[
                                "(",
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                ")",
                                " ",
                                /\d/,
                                /\d/,
                                /\d/,
                                "-",
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                              ]}
                              className={`form-control bg-white ${classes["phone_number_style"]}`}
                              placeholder="Enter a phone number"
                              guide={true}
                              type="text"
                              readOnly={rolePermissions?.includes(
                                "viewProfile"
                              )}
                              value={values.phone}
                              id="my-input-id"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setFieldValue(
                                  "phone",
                                  e.target.value.replace(/[^A-Z0-9]/gi, "")
                                );
                              }}
                            />
                          </FormGroup>
                          {errors.phone && touched.phone ? (
                            <p className={classes["form_error"]}>
                              {errors.phone}
                            </p>
                          ) : null}
                        </Col>
                        {rolePermissions?.includes("viewProfile") && (
                          <Col className="pl-md-1 mt-3 mt-md-4" sm="6">
                            <label className="darkText labelText">
                              Company Name
                            </label>
                            <Input
                              id="company_name"
                              type="text"
                              readOnly
                              className={`bg-white ${classes["company_name_style"]}`}
                              value={profileDetails.company_name}
                            />
                          </Col>
                        )}
                      </Row>
                      {!rolePermissions?.includes("viewProfile") && (
                        <Row>
                          <Col className="pr-md-1 mt-3 mt-md-4" sm="6">
                            <FormGroup className="d-flex flex-column">
                              <label className="darkText labelText">
                                Receive Invoice Email
                              </label>
                              <Switch
                                onChange={(e) => {
                                  setFieldValue(
                                    "enable_invoice_email",
                                    e.target.checked
                                  );
                                }}
                                checked={values.enable_invoice_email}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                    {!rolePermissions?.includes("viewProfile") && (
                      <CardFooter className="btn-set pt-0">
                        <Button
                          // className="btn-fill"
                          color="info"
                          type="submit"
                          disabled={
                            (profileDetails.first_name === values.first_name ||
                              !values.first_name) &&
                            (profileDetails.last_name === values.last_name ||
                              !values.last_name) &&
                            (profileDetails.email === values.email ||
                              !values.email) &&
                            (profileDetails.phone === values.phone ||
                              !values.phone) &&
                            values.files === null &&
                            profileDetails.enable_invoice_email ===
                              values.enable_invoice_email
                          }
                        >
                          {loadingButton ? "Processing..." : "Update"}
                        </Button>
                      </CardFooter>
                    )}
                  </Card>
                </Col>
                <Col md="4" className="mt-4 mt-md-0">
                  <Card className="card-user customColor6 rounded-2 p-3 mb-0">
                    <CardBody>
                      <CardText />
                      <div className="author">
                        <div className="block block-one" />
                        <div className="block block-two" />
                        <div className="block block-three" />
                        <div className="block block-four" />
                        <input
                          type="file"
                          className={classes["upload_avatar_style"]}
                          ref={inputRef}
                          name="files"
                          id="files"
                          accept="image/png, image/jpeg image/gif"
                          onChange={(e) => {
                            handleFileChange(e);
                          }}
                        />
                        <span
                          className={
                            !rolePermissions?.includes("viewProfile")
                              ? `${classes.profilepic} ${classes["pointer_show"]}`
                              : `${classes.profilepic} ${classes["pointer_hide"]}`
                          }
                          onClick={handleClick}
                        >
                          <img
                            alt=""
                            className={`avatar ${classes["profilepic__image"]}`}
                            src={fileUploadUrl}
                          />
                          <div className={classes["profilepic__content"]}>
                            <span className={classes["profilepic__icon"]}>
                              <i className="fas fa-camera"></i>
                            </span>
                          </div>
                        </span>
                        <p className="select-title">
                          {profileDetails.first_name} {profileDetails.last_name}
                        </p>
                        {profileDetails.roles !== "" ? rolesFunction() : <></>}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Form>
          </div>
        )}
      </div>
    </>
  );
};

export default User;
