import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  Table,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import { FaEdit, FaKey, FaSearch, FaTrash } from "react-icons/fa";

import {
  MenuItem,
  Select,
  Skeleton,
  TablePagination,
  Tooltip,
} from "@mui/material";
import ClientForm from "./ClientForm";
import EditClientForm from "./EditClientForm";
import EditLoginUpdateForm from "./EditLoginUpdateForm";
import { useHistory } from "react-router-dom";
import { AxiosAPiGet } from "../../AxiosAPi";
import NotificationAlert from "react-notification-alert";
import { useReducer } from "react";
import ClientDeleteForm from "./ClientDeleteForm";
import classes from "../../assets/css/ClientList.module.css";
// import ReferenceDataContext from "AvatarContext";
// Reducer function for edit,login update,delete dialog //
const dialogEditReducer = (state, action) => {
  if (action.type === "OPEN_EDIT_DIALOG") {
    return { editId: action.editId, isOpenEdit: action.editId !== "" };
  } else if (action.type === "CLOSE_EDIT_DIALOG") {
    return { editId: "", isOpenEdit: false };
  }
};
const dialogPasswordReducer = (state, action) => {
  if (action.type === "OPEN_LOGIN_UPDATE_DIALOG") {
    return { loginId: action.loginId, isOpenLogin: action.loginId !== "" };
  } else if (action.type === "CLOSE_LOGIN_UPDATE_DIALOG") {
    return { loginId: "", isOpenLogin: false };
  }
};
const dialogDeleteClientReducer = (state, action) => {
  if (action.type === "DELETE_CLIENT_DIALOG") {
    return {
      deletedId: action.deletedId,
      isOpenDeletDialog: action.deletedId !== "",
    };
  }
  return { deletedId: "", isOpenDeletDialog: false };
};
// Reducer function for edit,login update,delete dialog //
function ClientList() {
  // const {activeColor}=useContext(ReferenceDataContext)
  //    Use to redirect //
  const history = useHistory();
  //    Use to redirect //
  // Use to show table div when load //
  const tableRef = useRef(null);
  // Use to show table div when load //

  // Pagination page input //
  const [page, setPage] = useState(0);
  // Pagination page input //

  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //

  // Input for opening and closing add new client dialog //
  const [open, setOpen] = useState(false);
  // Input for opening and closing add new client dialog //
  // Input for capturing total client data count //
  const [dataCount, setDataCount] = useState(0);
  // Input for capturing total client data count //

  // Input for capturing loading state //
  const [loading, setLoading] = useState(false);
  // Input for capturing loading state //

  // Input for capturing client list array //
  const [clientData, setClientData] = useState([]);
  // Input for capturing client list array //

  // Pagination number of rows per page //
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // Pagination number of rows per page //

  // Input for email //
  // Input for email //

  // Filter inputs //
  const [searchData, setSearchData] = useState({
    clientId: "",
    email: "",
    client_type: "",
    status: "Active",
  });
  // Filter inputs //
  // input for storing current page value //
  const [currentPage, setCurrentPage] = useState("");
  // input for storing current page value //
  // input for storing last page value //
  const [lastPage, setLastPage] = useState("");
  // input for storing last page value //
  // input for storing boolean value while changing client status //
  const [clientStatusChange, setClientStatusChange] = useState(false);
  // input for storing boolean value while changing client status //
  // inputs for edit,login update, delete dialog state handle //
  const [editDialogState, dispatchEditDialogState] = useReducer(
    dialogEditReducer,
    {
      editId: "",
      isOpenEdit: false,
    }
  );
  const [passwordDialogState, dispatchPasswordDialogState] = useReducer(
    dialogPasswordReducer,
    {
      loginId: "",
      isOpenLogin: false,
    }
  );
  const [deleteClientState, dispatchDeleteClientState] = useReducer(
    dialogDeleteClientReducer,
    {
      deletedId: "",
      isOpenDeletDialog: false,
    }
  );
  // inputs for edit,login update, delete dialog state handle //
  // input for showing list api url //
  const [searchUrl, setSearchUrl] = useState(
    'where_clause={"where_fields":["roles","status","is_test_account","deleted"],"where_values":["client","Active","false","false"]}'
  );
  // input for showing list api url //
  //    Loading initial data using useEffect //
  const getAllClientList = useCallback(() => {
    setLoading(true);
    AxiosAPiGet(
      `${
        process.env.REACT_APP_AXIOS_BASE_URL
      }/clients/list?${searchUrl}&show=${rowsPerPage}&page=${page + 1}`,
      "get",
      (res) => {
        setClientData(res.data?.results?.results?.data);
        setDataCount(res.data?.results?.results?.count);
        setCurrentPage(res.data?.results?.results?.current_page);
        setLastPage(res.data?.results?.results?.last_page);
        setLoading(false);
      },
      history,
      (err) => {
        let options = {};
        options = {
          place: "tr",
          message: err.response.data.message,
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
        setLoading(false);
      }
    );
  }, [history, searchUrl, page, rowsPerPage]);
  useEffect(() => {
    getAllClientList();
  }, [getAllClientList]);
  //    Loading initial data using useEffect //

  //Pagination page change and search functionality//

  const handleSearch = () => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
    let key_array = [];
    let value_array = [];
    const updatedSearch = {
      clientId: searchData.clientId.trim(),
      email: searchData.email.trim().toLowerCase(),
      client_type: searchData.client_type,
      status: searchData.status,
    };
    Object.keys(updatedSearch).forEach((key) => {
      if (updatedSearch[key] !== "") {
        key_array.push(key);
        value_array.push(updatedSearch[key]?.trim());
      }
    });
    let key_string = "";
    let value_string = "";
    for (var i = 0; i < key_array?.length; i++) {
      key_string += '"' + key_array[i] + '"';
      key_string += ",";
      value_string += '"' + value_array[i] + '"';
      value_string += ",";
    }

    key_string = key_string.replace(/,+$/, "");
    value_string = value_string.replace(/,+$/, "");
    if (updatedSearch.status === "") {
      let url =
        key_string !== ""
          ? `where_clause={"where_fields":["roles",${key_string},"is_test_account","deleted"],"where_values":["client",${value_string},"false","false"]}`
          : 'where_clause={"where_fields":["roles","is_test_account","deleted"],"where_values":["client","false","false"]}';
      setSearchUrl(url);
    } else {
      setSearchUrl(
        `where_clause={"where_fields":["roles",${key_string},"is_test_account","deleted"],"where_values":["client",${value_string},"false","false"]}`
      );
    }
  };

  //Pagination page change and search functionality//

  // Handle page change in pagination //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };

  // Handle page change in pagination //

  //Rows per page in pagination //

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  //Rows per page in pagination //

  // Function for opening edit dialog //
  const fetchClientDetails = (id) => {
    dispatchEditDialogState({ type: "OPEN_EDIT_DIALOG", editId: id });
  };
  // Function for opening edit dialog //

  // Function for opening update password client dialog //
  const fetchLoginUpdateDetails = (id) => {
    dispatchPasswordDialogState({
      type: "OPEN_LOGIN_UPDATE_DIALOG",
      loginId: id,
    });
  };

  // Function for opening update password client dialog //

  // Reset filter search //

  const resetSearch = () => {
    setPage(0);
    setSearchData({
      clientId: "",
      email: "",
      client_type: "",
      status: "Active",
    });
    setSearchUrl(
      'where_clause={"where_fields":["roles","status","is_test_account","deleted"],"where_values":["client","Active","false","false"]}'
    );
  };
  // Reset filter search //
  // Onchange for filter inputs //
  const handleSearchInput = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
    if (e.target.name === "status") {
      setClientStatusChange(true);
    }
  };
  // Onchange for filter inputs //
  // Skeleton loader //
  const ClientListLoader = () => {
    return [...Array(rowsPerPage)].map((val, index) => (
      <tr key={index}>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
      </tr>
    ));
  };
  // Skeleton loader //
  // Generalize function for notification alert //
  const AlertShow = (message, type) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // Generalize function for notification alert //
  // Close dialog functionality //
  const closeEditDialog = () => {
    dispatchEditDialogState({ type: "CLOSE_EDIT_DIALOG" });
  };
  const closeLoginUpdateDialog = () => {
    dispatchPasswordDialogState({ type: "CLOSE_LOGIN_UPDATE_DIALOG" });
  };
  const closeClientAddDialog = () => {
    setOpen(false);
  };
  const closeClientDeleteDialog = () => {
    dispatchDeleteClientState({ type: "CLOSE_CLIENT_DIALOG" });
  };
  // Close dialog functionality //
  // function for handling white spaces //
  const handleWhiteSpaces = (e) => {
    var keycode = e.which || e.keyCode;
    if (keycode === 32) {
      e.preventDefault();
    }
  };
  // function for handling white spaces //
  // function for fetch ID while open delete client dialog //
  const fetchDeleteClientDetails = (id) => {
    dispatchDeleteClientState({ type: "DELETE_CLIENT_DIALOG", deletedId: id });
  };
  // function for fetch ID while open delete client dialog //
  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="customColor4 p-3 p-sm-4 rounded-2">
        <Form className="selectBox customColor6 rounded-2 py-3 px-sm-3 mb-4">
          <Card className="trnstprnt-bg commonForm mb-0">
            <p className="select-title textColor pt-1 pt-md-2 mb-3 px-3">
              Client Details
            </p>
            <CardBody>
              <Row>
                <Col lg="3" sm="6">
                  <FormGroup>
                    <Label className="darkText labelText">Client Status:</Label>
                    <Select
                      className={`from-control w-100 bg-white ${classes["client_status_input"]}`}
                      name="status"
                      value={searchData.status}
                      onChange={(e) => handleSearchInput(e)}
                      displayEmpty
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Deactivated">Deactivated</MenuItem>
                      <MenuItem value="Hold">Hold</MenuItem>
                      <MenuItem value="Pause">Pause</MenuItem>
                      <MenuItem value="">All Clients</MenuItem>
                    </Select>
                  </FormGroup>
                </Col>
                <Col lg="3" sm="6" className="mt-3 mt-sm-0">
                  <FormGroup>
                    <Label className="darkText labelText">Client Id:</Label>
                    <Input
                      className="from-control bg-white"
                      name="clientId"
                      type="text"
                      placeholder="Enter client Id"
                      value={searchData.clientId}
                      onChange={(e) => handleSearchInput(e)}
                      onKeyDown={(e) => handleWhiteSpaces(e)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="3" sm="6" className="mt-3 mt-lg-0">
                  <FormGroup>
                    <Label className="darkText labelText">Client Email:</Label>
                    <Input
                      className="from-control bg-white"
                      name="email"
                      type="text"
                      placeholder="Enter client Email"
                      value={searchData.email}
                      onChange={(e) => handleSearchInput(e)}
                      onKeyDown={(e) => handleWhiteSpaces(e)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="3" sm="6" className="mt-3 mt-lg-0">
                  <FormGroup>
                    <Label className="darkText labelText">Client Type:</Label>
                    <Select
                      className={`w-100 from-control bg-white ${classes["client_type_input"]}`}
                      name="client_type"
                      value={searchData.client_type}
                      onChange={(e) => handleSearchInput(e)}
                      displayEmpty
                    >
                      <MenuItem value="">--All Client type--</MenuItem>
                      <MenuItem value="api">API</MenuItem>
                      <MenuItem value="shopify">Shopify</MenuItem>
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="8"></Col>
                <Col lg="4" className={`btn-set ${classes["filter_buttons"]}`}>
                  <Button
                    color="info"
                    type="button"
                    disabled={
                      !searchData.clientId &&
                      !searchData.email &&
                      !clientStatusChange &&
                      !searchData.client_type
                    }
                    onClick={handleSearch}
                  >
                    <FaSearch /> Search
                  </Button>
                  <Button
                    type="button"
                    className="custom-bg"
                    color="info"
                    onClick={resetSearch}
                  >
                    <i className="fa-sharp fa-solid fa-rotate-left"></i> Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
        <Form action="#">
          <Row className="d-flex justify-content-end align-self-end">
            <Col lg="12" sm="12" className="text-right btn-set mb-4">
              <Button color="info" type="button" onClick={() => setOpen(true)}>
                + Add Client
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            {dataCount !== 0 && (
              <Card className="rounded-2 mb-2">
                <CardBody className="py-1 pagination-holder">
                  <TablePagination
                    className={classes["pagination_style"]}
                    rowsPerPageOptions={[10, 25, 100]}
                    backIconButtonProps={{
                      style: { color: currentPage === "1" ? "grey" : "black" },
                    }}
                    nextIconButtonProps={{
                      style: {
                        color:
                          Number(currentPage) === lastPage ? "grey" : "black",
                      },
                    }}
                    component="div"
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="rounded-2 my-3">
              <CardBody>
                <div
                  className={`${classes["table-responsive"]} table-full-width`}
                  ref={tableRef}
                >
                  <Table className="text-center mb-0">
                    <tbody>
                      <tr>
                        <th className="darkText labelText">Client ID</th>
                        <th className="darkText labelText">Client Type</th>
                        <th className="darkText labelText">Main Contact</th>
                        <th className="darkText labelText">Company Name</th>
                        <th className="darkText labelText">Email</th>
                        <th className="darkText labelText">Phone</th>
                        <th className="darkText labelText">Status</th>
                        <th className="darkText labelText">Action</th>
                      </tr>
                      {loading ? (
                        ClientListLoader()
                      ) : clientData?.length !== 0 ? (
                        clientData?.map((data, i) => (
                          <tr key={i}>
                            <td className="textColor">
                              {data?.clientId ? data?.clientId : i + 1}
                            </td>
                            {data.client_type === "api" ? (
                              <td className="textColor">API</td>
                            ) : (
                              <td className="textColor">Shopify</td>
                            )}

                            <td className="textColor">{data?.name}</td>
                            <td className="textColor">
                              {data?.company_name ? data?.company_name : "--"}
                            </td>
                            <td className="textColor">{data?.email}</td>
                            <td className="textColor">{data?.phone}</td>
                            <td className="textColor">{data?.status}</td>
                            <td className="">
                              <p className={classes["icon_alignment"]}>
                                <Tooltip title="Edit" placement="top">
                                  <button
                                    type="button"
                                    onClick={() => fetchClientDetails(data.id)}
                                    className={`${classes["edit_icon"]}`}
                                  >
                                    <FaEdit />
                                  </button>
                                </Tooltip>
                                <Tooltip title="Delete" placement="top">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      fetchDeleteClientDetails(data.id)
                                    }
                                    className={`${classes["edit_icon"]}`}
                                  >
                                    <FaTrash />
                                  </button>
                                </Tooltip>
                                <Tooltip title="Set Password" placement="top">
                                  <button
                                    type="button"
                                    onClick={() =>
                                      fetchLoginUpdateDetails(data.id)
                                    }
                                    className={`${classes["edit_icon"]}`}
                                  >
                                    <FaKey />
                                  </button>
                                </Tooltip>
                              </p>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={12}
                            className={classes["no_records_title"]}
                          >
                            No records Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {dataCount !== 0 && (
              <Card className="rounded-2 mb-2">
                <CardBody className="py-1 pagination-holder">
                  <TablePagination
                    className={`${classes["pagination_style"]}`}
                    rowsPerPageOptions={[10, 25, 100]}
                    backIconButtonProps={{
                      style: { color: currentPage === "1" ? "grey" : "black" },
                    }}
                    nextIconButtonProps={{
                      style: {
                        color:
                          Number(currentPage) === lastPage ? "grey" : "black",
                      },
                    }}
                    component="div"
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>
      {open && (
        <ClientForm
          open={open}
          onAlertShow={AlertShow}
          getAllClientList={getAllClientList}
          onCloseClientAddDialog={closeClientAddDialog}
        />
      )}
      {editDialogState.isOpenEdit && (
        <EditClientForm
          onAlertShow={AlertShow}
          onCloseEditDialog={closeEditDialog}
          getAllClientList={getAllClientList}
          openEdit={editDialogState.isOpenEdit}
          editedClientId={editDialogState.editId}
        />
      )}
      {passwordDialogState.isOpenLogin && (
        <EditLoginUpdateForm
          onAlertShow={AlertShow}
          getAllClientList={getAllClientList}
          loginId={passwordDialogState.loginId}
          onCloseLoginUpdateDialog={closeLoginUpdateDialog}
          openLoginUpdateForm={passwordDialogState.isOpenLogin}
        />
      )}
      {deleteClientState.deletedId && (
        <ClientDeleteForm
          onAlertShow={AlertShow}
          getAllClientList={getAllClientList}
          deletedId={deleteClientState.deletedId}
          onCloseClientDeleteDialog={closeClientDeleteDialog}
          isOpenDelete={deleteClientState.isOpenDeletDialog}
        />
      )}
    </div>
  );
}

export default ClientList;
