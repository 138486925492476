import React, { useState, useRef } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import classes from "../../assets/css/ClientForm.module.css";
import { Dialog, DialogContent, MenuItem, Select } from "@mui/material";
import MaskedInput from "react-text-mask";
import { AxiosAPiPost } from "AxiosAPi";
import NotificationAlert from "react-notification-alert";
import { useFormik } from "formik";
import { ClientFormSchema } from "utils/schemas/ClientFormSchema";
function ClientForm({
  open,
  onAlertShow,
  getAllClientList,
  onCloseClientAddDialog,
}) {
  // Use to redirect to client list page after form submission //
  const history = useHistory();
  // Use to redirect to client list page after form submission //
  // Show notification popup //
  const notificationAlertRef = useRef(null);

  // Show notification popup //

  // Use for loading condition handling //
  const [loading, setLoading] = useState(false);
  // Use for loading condition handling //
  // form data inputs //
  const initialValues = {
    clientId: Date.now().toString().substring(0, 9),
    client_type: "",
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company_name: "",
    status: "",
    lead_price: "",
  };
  // form data inputs //
  // Submit client form and form validation,error handling //
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: ClientFormSchema,
    onSubmit: (values, action) => {
      values.name = values.first_name + " " + values.last_name;
      const data = {
        clientId: values.clientId,
        client_type: values.client_type,
        name: values.name,
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email.trim(),
        phone: values.phone,
        company_name: values.company_name,
        status: values.status,
        lead_price: values.lead_price,
      };
      setLoading(true);
      AxiosAPiPost(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/save`,
        "post",
        data,
        (response) => {
          if (response.data.status === true) {
            onAlertShow(response.data.message, "success");
            getAllClientList();
            onCloseClientAddDialog();
          } else {
            ClientFormNotificationError(response.data.message);
            setLoading(false);
          }
        },
        history,
        (err) => {
          if (err.response.data.status === false) {
            setLoading(false);
            if (err.response.status === 422) {
              if (err.response.data.errors.clientId) {
                ClientFormNotificationError(
                  err.response.data.errors.clientId.message
                );
              }
              if (err.response.data.errors.email) {
                ClientFormNotificationError(
                  err.response.data.errors.email.message
                );
              }
              if (err.response.data.errors.lead_price) {
                ClientFormNotificationError(
                  err.response.data.errors.lead_price.message
                );
              }
            } else {
              ClientFormNotificationError(err.response.data.message);
            }
          }
        }
      );
      action.resetForm();
    },
  });
  // Submit client form and form validation,error handling //
  // Close dialog box //
  const handleClose = () => {
    onCloseClientAddDialog();
  };
  // Close dialog box //
  // Generalize function for notification alert //
  const ClientFormNotificationError = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // Generalize function for notification alert //
  // Function for limiting number field //
  const handleKeyDownOrderMin = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };
  // Function for limiting number field //
  return (
    <div className={classes["client_form_content"]}>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
          },
        }}
      >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>

        <DialogContent dividers className="px-0 px-sm-3 px-md-4">
          <div className={`popupForm-holder ${classes["dialog_content"]}`}>
            <Row>
              <Col md="12">
                <Form
                  id="RegisterValidation"
                  onSubmit={handleSubmit}
                  className="commonForm popupForm"
                >
                  <Card className="customColor4">
                    <CardHeader>
                      <CardTitle
                        className={`darkText labelText mb-0 ${classes["view_icon_cross"]}`}
                        tag="h4"
                      >
                        Add Client Form
                        <i
                          onClick={handleClose}
                          className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
                        ></i>
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className="has-label">
                        <label className="darkText">
                          Client Id <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="clientId"
                          type="text"
                          min={1}
                          className={`bg-white`}
                          readOnly
                          value={values.clientId}
                          placeholder="Enter your client id"
                        />
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Client Type <span className="text-danger">*</span>
                        </label>
                        <Select
                          name="client_type"
                          className={`w-100 bg-white ${classes["status_dropdown"]}`}
                          value={values.client_type}
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value="">Choose client type</MenuItem>
                          <MenuItem value="api">API</MenuItem>
                          <MenuItem value="shopify">Shopify</MenuItem>
                        </Select>
                        {errors.client_type && touched.client_type ? (
                          <p className={classes["form_error"]}>
                            {errors.client_type}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="first_name"
                          placeholder="Enter your first name"
                          type="text"
                          className="bg-white"
                          value={values.first_name}
                          onChange={handleChange}
                        />
                        {errors.first_name && touched.first_name ? (
                          <p className={classes["form_error"]}>
                            {errors.first_name}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="last_name"
                          placeholder="Enter your last name"
                          type="text"
                          className="bg-white"
                          value={values.last_name}
                          onChange={handleChange}
                        />
                        {errors.last_name && touched.last_name ? (
                          <p className={classes["form_error"]}>
                            {errors.last_name}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="email"
                          placeholder="Enter your email address"
                          type="email"
                          className="bg-white"
                          value={values.email}
                          onChange={handleChange}
                        />
                        {errors.email && touched.email ? (
                          <p className={classes["form_error"]}>
                            {errors.email}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Phone Number <span className="text-danger">*</span>
                        </label>
                        <MaskedInput
                          mask={[
                            "(",
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          className="form-control bg-white"
                          placeholder="Enter a phone number"
                          name="phone"
                          guide={true}
                          id="my-input-id"
                          value={values.phone}
                          onChange={(e) => {
                            setFieldValue(
                              "phone",
                              e.target.value.replace(/[^A-Z0-9]/gi, "")
                            );
                          }}
                          onBlur={handleBlur}
                        />

                        {errors.phone && touched.phone ? (
                          <p className={classes["form_error"]}>
                            {errors.phone}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Company Name <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="company_name"
                          placeholder="Enter Company Name"
                          type="text"
                          className="bg-white"
                          value={values.company_name}
                          onChange={handleChange}
                        />
                        {errors.company_name && touched.company_name ? (
                          <p className={classes["form_error"]}>
                            {errors.company_name}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Lead Price ($) <span className="text-danger">*</span>
                        </label>
                        <Input
                          name="lead_price"
                          type="number"
                          className="bg-white"
                          step={0.01}
                          min={0}
                          placeholder="Lead Price"
                          value={values.lead_price}
                          onChange={handleChange}
                          onWheel={(e) => e.target.blur()}
                          onKeyDown={(e) => handleKeyDownOrderMin(e)}
                        />

                        {errors.lead_price && touched.lead_price ? (
                          <p className={classes["form_error"]}>
                            {errors.lead_price}
                          </p>
                        ) : null}
                      </FormGroup>

                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Status <span className="text-danger">*</span>
                        </label>
                        <Select
                          name="status"
                          className={`w-100 bg-white ${classes["status_dropdown"]}`}
                          value={values.status}
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value="">Choose your Status</MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Pause">Pause</MenuItem>
                          <MenuItem value="Hold">Hold</MenuItem>
                          <MenuItem value="Deactivated">Deactivated</MenuItem>
                        </Select>
                        {errors.status && touched.status ? (
                          <p className={classes["form_error"]}>
                            {errors.status}
                          </p>
                        ) : null}
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="text-center btn-set">
                      <Button color="info" type="submit">
                        {loading ? "Processing..." : "Add Client"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ClientForm;
