import {
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TablePagination,
  Tooltip,
} from "@mui/material";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { FaEdit, FaKey, FaSearch } from "react-icons/fa";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import classes from "../../assets/css/ClientList.module.css";
import { useHistory } from "react-router-dom";
import ReferenceDataContext from "AvatarContext";
import { AxiosAPiGet } from "AxiosAPi";
import MemberForm from "./MemberForm";
import MemberLoginUpdateForm from "./MemberLoginUpdateForm";
import EditMemberForm from "./EditMemberForm";
import { AxiosAPiPost } from "AxiosAPi";
const editMemberReducer = (state, action) => {
  if (action.type === "OPEN_EDIT_DIALOG") {
    return {
      editMemberData: action.editData,
      isOpenEdit: action.editData.id !== "",
    };
  } else if (action.type === "CLOSE_EDIT_DIALOG") {
    return { editMemberData: {}, isOpenEdit: false };
  }
};
const loginUpdateReducer = (state, action) => {
  if (action.type === "OPEN_LOGIN_UPDATE_DIALOG") {
    return {
      loginUpdateId: action.loginId,
      isLoginUpdate: action.loginId !== "",
    };
  } else if (action.type === "CLOSE_LOGIN_UPDATE_DIALOG") {
    return { loginUpdateId: "", isLoginUpdate: false };
  }
};
function MemberList() {
  // permissions list based on different roles //
  const { rolePermissions } = useContext(ReferenceDataContext);
  // permissions list based on different roles //
  //    Use to redirect //
  const history = useHistory();
  //    Use to redirect //
  // Use to show table div when load //
  const tableRef = useRef(null);
  // Use to show table div when load //
  // Pagination page input //
  const [page, setPage] = useState(0);

  // Pagination page input //
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // inputs for checked items //
  const [checkedItems, setCheckedItems] = useState({});
  // inputs for checked items //
  // Input for opening and closing add new client dialog //
  const [open, setOpen] = useState(false);
  // Input for opening and closing add new client dialog //
  // Input for capturing total member data count //
  const [dataCount, setDataCount] = useState(0);
  // Input for capturing total member data count //
  // Loading state //
  const [loading, setLoading] = useState(false);
  // Loading state //
  // List array //
  const [clientData, setClientData] = useState([]);
  // List array //
  // Pagination number of rows per page //
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // Pagination number of rows per page //
  // input for filter data //
  const [searchData, setSearchData] = useState({
    clientId: "",
    email: "",
    status: "Active",
    role: "",
  });
  // input for filter data //
  const [memberStatusChange, setMemberStatusChange] = useState(false);
  const [editMemberState, dispatchEditMemberState] = useReducer(
    editMemberReducer,
    {
      editMemberData: {},
      isOpenEdit: false,
    }
  );
  const [loginUpdateState, dispatchLoginUpdateState] = useReducer(
    loginUpdateReducer,
    {
      loginUpdateId: "",
      isLoginUpdate: false,
    }
  );
  const [searchUrl, setSearchUrl] = useState(
    'where_clause={"where_fields":["status","is_test_account"],"where_values":["Active","false"]}'
  );
  const [currentPage, setCurrentPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  //    Loading initial data using useEffect //

  const getAllMembersList = useCallback(() => {
    setLoading(true);
    AxiosAPiGet(
      `${
        process.env.REACT_APP_AXIOS_BASE_URL
      }/members/list?${searchUrl}&show=${rowsPerPage}&page=${page + 1}`,
      "get",
      (res) => {
        setLoading(false);

        const filteredClientList =
          res?.data?.results?.results?.data.length !== 0
            ? Object.assign(
                ...res?.data?.results?.results?.data?.map((item) => ({
                  ...item,
                  [item.id]: item.enable_invoice_email,
                }))
              )
            : res?.data?.results?.results?.data;
        setCheckedItems(filteredClientList);
        setClientData(res?.data?.results?.results?.data);
        setDataCount(res?.data?.results?.results?.count);
        setCurrentPage(res.data?.results?.results?.current_page);
        setLastPage(res.data?.results?.results?.last_page);
      },
      history,
      (err) => {
        let options = {};
        options = {
          place: "tr",
          message: err.response?.data.message,
          type: "warning",
          icon: "tim-icons icon-bell-55",
          autoDismiss: 3,
        };
        notificationAlertRef.current.notificationAlert(options);
        setLoading(false);
      }
    );
  }, [history, page, rowsPerPage, searchUrl]);
  useEffect(() => {
    getAllMembersList();
  }, [getAllMembersList]);

  //    Loading initial data using useEffect //
  //Pagination page change and search functionality//

  const handleSearch = () => {
    setPage(0);
    tableRef?.current?.scrollIntoView();
    let key_array = [];
    let value_array = [];
    let updated_search_data = {
      clientId: searchData.clientId.trim(),
      email: searchData.email.trim().toLowerCase(),
      status: searchData.status,
    };
    Object.keys(updated_search_data).forEach((key) => {
      if (updated_search_data[key] !== "") {
        key_array.push(key);
        value_array.push(updated_search_data[key]);
      }
    });
    let key_string = "";
    let value_string = "";
    for (var i = 0; i < key_array?.length; i++) {
      key_string += '"' + key_array[i] + '"';
      key_string += ",";
      value_string += '"' + value_array[i] + '"';
      value_string += ",";
    }

    key_string = key_string.replace(/,+$/, "");
    value_string = value_string.replace(/,+$/, "");
    if (searchData.role === "") {
      let where_clause_without_role =
        value_string !== ""
          ? `where_clause={"where_fields":[${key_string},"is_test_account"],"where_values":[${value_string},"false"]}`
          : `where_clause={"where_fields":["is_test_account"],"where_values":["false"]}`;
      setSearchUrl(where_clause_without_role);
    } else {
      let url = `roles=${searchData.role}&`;
      let where_clause_with_role =
        value_string !== ""
          ? `where_clause={"where_fields":[${key_string},"is_test_account"],"where_values":[${value_string},"false"]}`
          : `where_clause={"where_fields":["is_test_account"],"where_values":["false"]}`;
      url += where_clause_with_role;
      setSearchUrl(url);
    }
  };
  //Pagination page change and search functionality//
  // Reset filter search //
  const resetSearch = () => {
    setPage(0);
    setSearchData({
      clientId: "",
      email: "",
      status: "Active",
      role: "",
    });
    setSearchUrl(
      'where_clause={"where_fields":["status","is_test_account"],"where_values":["Active","false"]}'
    );
  };
  // Reset filter search //
  // Handle page change in pagination //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };
  // Handle page change in pagination //
  //Rows per page in pagination //
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };
  //Rows per page in pagination //
  // Function for opening edit dialog //
  const fetchClientDetails = (data) => {
    dispatchEditMemberState({ type: "OPEN_EDIT_DIALOG", editData: data });
  };
  // Function for opening edit dialog //
  // Function for opening update password client dialog //
  const fetchLoginUpdateDetails = (id) => {
    dispatchLoginUpdateState({ type: "OPEN_LOGIN_UPDATE_DIALOG", loginId: id });
  };
  // Function for opening update password client dialog //

  // Onchange for filter inputs //
  const handleSearchInput = (e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.value });
    if (e.target.name === "status") {
      setMemberStatusChange(true);
    }
  };
  // Onchange for filter inputs //

  // onchange invoice email flag //
  const handleEnableInvoice = (e) => {
    setCheckedItems({ ...checkedItems, [e.target.name]: e.target.checked });
    document.getElementById("toggle_button").setAttribute("disabled", true);
    setTimeout(() => {
      AxiosAPiPost(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/members/enableDisableInvoiceMail`,
        "post",
        { id: e.target.name, enable_invoice_email: e.target.checked },
        (res) => {
          if (res.data.status === true) {
            let options = {};
            options = {
              place: "tr",
              message: res.data.message,
              type: "success",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 3,
            };
            notificationAlertRef.current.notificationAlert(options);
            getAllMembersList();
          }
        },
        history,
        (err) => {
          let options = {};
          options = {
            place: "tr",
            message: err.response.data.message,
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef.current.notificationAlert(options);
        }
      );
    }, 1000);
  };
  // onchange invoice email flag //
  // Skeleton loader //
  const MemberListLoader = () => {
    return [...Array(rowsPerPage)].map((val, index) => (
      <tr key={index}>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        {rolePermissions?.includes("invoiceEmailColumn") && (
          <td className="textColor">
            <Skeleton animation="wave" variant="text" />
          </td>
        )}
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className="textColor">
          <Skeleton animation="wave" variant="text" />
        </td>
        {rolePermissions?.includes("invoiceEmailColumn") && (
          <td className="textColor">
            <Skeleton animation="wave" variant="text" />
          </td>
        )}
      </tr>
    ));
  };
  // Skeleton loader //
  // Generalize function for notification alert //
  const AlertShow = (message, type) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // Generalize function for notification alert //
  // Close dialog functionality //
  const closeEditDialog = () => {
    dispatchEditMemberState({ type: "CLOSE_EDIT_DIALOG" });
  };
  const closeLoginUpdateDialog = () => {
    dispatchLoginUpdateState({ type: "CLOSE_LOGIN_UPDATE_DIALOG" });
  };
  const closeMemberAddDialog = () => {
    setOpen(false);
  };
  // Close dialog functionality //
  // function for handling white spaces //
  const handleWhiteSpaces = (e) => {
    var keycode = e.which || e.keyCode;
    if (keycode === 32) {
      e.preventDefault();
    }
  };
  // function for handling white spaces //
  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>

      <div className="customColor4 p-3 p-sm-4 rounded-2">
        <Form className="selectBox customColor6 rounded-2 py-3 px-sm-3 mb-4">
          <Card className="trnstprnt-bg commonForm mb-0">
            <p className="select-title textColor pt-1 pt-md-2 px-3">
              Member Details
            </p>
            <CardBody>
              <Row>
                <Col lg="3" sm="6">
                  <FormGroup>
                    <Label className="darkText labelText">Member Status:</Label>
                    <Select
                      className={`w-100 bg-white ${classes["client_status_input"]}`}
                      name="status"
                      value={searchData.status}
                      onChange={(e) => handleSearchInput(e)}
                      displayEmpty
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Deactivated">Deactivated</MenuItem>
                      <MenuItem value="Hold">Hold</MenuItem>
                      <MenuItem value="Pause">Pause</MenuItem>
                      <MenuItem value="">All Clients</MenuItem>
                    </Select>
                  </FormGroup>
                </Col>
                <Col lg="3" sm="6" className="mt-3 mt-sm-0">
                  <FormGroup>
                    <Label className="darkText labelText">Member Id:</Label>
                    <Input
                      className="from-control bg-white"
                      name="clientId"
                      type="text"
                      placeholder="Enter Member Id"
                      value={searchData.clientId}
                      onChange={(e) => handleSearchInput(e)}
                      onKeyDown={(e) => handleWhiteSpaces(e)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="3" sm="6" className="mt-3 mt-lg-0">
                  <FormGroup>
                    <Label className="darkText labelText">Member Email:</Label>
                    <Input
                      className="from-control bg-white"
                      name="email"
                      type="text"
                      placeholder="Enter Member Email"
                      value={searchData.email}
                      onChange={(e) => handleSearchInput(e)}
                      onKeyDown={(e) => handleWhiteSpaces(e)}
                    />
                  </FormGroup>
                </Col>
                <Col lg="3" sm="6" className="mt-3 mt-lg-0">
                  <FormGroup>
                    <Label className="darkText labelText">Member Role:</Label>
                    <Select
                      className={`w-100 bg-white ${classes["client_type_input"]}`}
                      name="role"
                      value={searchData.role}
                      onChange={(e) => handleSearchInput(e)}
                      displayEmpty
                    >
                      <MenuItem value="">--All Member role--</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="super_admin">Super Admin</MenuItem>
                      <MenuItem value="developer">Developer</MenuItem>
                    </Select>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="8"></Col>
                <Col lg="4" className={`btn-set ${classes["filter_buttons"]}`}>
                  <Button
                    color="info"
                    type="button"
                    disabled={
                      !searchData.clientId &&
                      !searchData.email &&
                      !searchData.role &&
                      !memberStatusChange
                    }
                    onClick={handleSearch}
                  >
                    <FaSearch /> Search
                  </Button>
                  <Button
                    type="button"
                    className="custom-bg"
                    color="info"
                    onClick={resetSearch}
                  >
                    <i className="fa-sharp fa-solid fa-rotate-left"></i> Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
        <Form action="#">
          <Row className="d-flex justify-content-end align-self-end">
            <Col lg="12" sm="12" className="text-right btn-set mb-4">
              <Button color="info" type="button" onClick={() => setOpen(true)}>
                + Add Member
              </Button>
            </Col>
          </Row>
        </Form>
        <Row>
          <Col>
            {dataCount !== 0 && (
              <Card className="rounded-2 mb-2">
                <CardBody className="py-1 pagination-holder">
                  <TablePagination
                    className={classes["pagination_style"]}
                    rowsPerPageOptions={[10, 25, 100]}
                    backIconButtonProps={{
                      style: { color: currentPage === "1" ? "grey" : "black" },
                    }}
                    nextIconButtonProps={{
                      style: {
                        color:
                          Number(currentPage) === lastPage ? "grey" : "black",
                      },
                    }}
                    component="div"
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="rounded-2 my-3">
              <CardBody>
                <div
                  className={`${classes["table-responsive"]} table-full-width`}
                  ref={tableRef}
                >
                  <Table className={"text-center"}>
                    <tbody>
                      <tr>
                        <th className="darkText labelText">Member ID</th>
                        <th className="darkText labelText">Member Role</th>
                        <th className="darkText labelText">Member Name</th>
                        <th className="darkText labelText">Email</th>
                        {rolePermissions?.includes("invoiceEmailColumn") && (
                          <th className="darkText labelText">Invoice Email</th>
                        )}
                        <th className="darkText labelText">Phone</th>
                        <th className="darkText labelText">Status</th>
                        {rolePermissions?.includes("invoiceEmailColumn") && (
                          <th className="darkText labelText">Action</th>
                        )}
                      </tr>
                      {loading ? (
                        MemberListLoader()
                      ) : clientData?.length !== 0 ? (
                        clientData?.map((data, i) => (
                          <tr key={i}>
                            <td className="textColor">
                              {data?.clientId ? data?.clientId : i + 1}
                            </td>
                            {data?.roles[0] === "developer" && (
                              <td className="textColor">Developer</td>
                            )}
                            {data?.roles[0] === "admin" && (
                              <td className="textColor">Admin</td>
                            )}
                            {data?.roles[0] === "super_admin" && (
                              <td className="textColor">Super Admin</td>
                            )}

                            <td className="textColor">
                              {data?.first_name} {data?.last_name}
                            </td>

                            <td className="textColor">{data?.email}</td>
                            {rolePermissions?.includes(
                              "invoiceEmailColumn"
                            ) && (
                              <td className="textColor">
                                <FormGroup>
                                  <Switch
                                    id="toggle_button"
                                    checked={checkedItems[data?.id]}
                                    name={data?.id}
                                    onChange={(e) => handleEnableInvoice(e)}
                                  />
                                </FormGroup>
                              </td>
                            )}

                            <td className="textColor">{data?.phone}</td>
                            <td className="textColor">{data?.status}</td>
                            {rolePermissions?.includes(
                              "invoiceEmailColumn"
                            ) && (
                              <td className="textColor">
                                <p className={classes["icon_alignment"]}>
                                  <Tooltip title="Set Password" placement="top">
                                    <button
                                      type="button"
                                      onClick={() =>
                                        fetchLoginUpdateDetails(data.id)
                                      }
                                      className={`${classes["edit_icon"]}`}
                                    >
                                      <FaKey />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title="Edit" placement="top">
                                    <button
                                      type="button"
                                      // onClick={() => setOpenEditForm(true)}
                                      onClick={() => fetchClientDetails(data)}
                                      className={`${classes["edit_icon"]}`}
                                    >
                                      <FaEdit />
                                    </button>
                                  </Tooltip>
                                </p>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={12}
                            className={classes["no_records_title"]}
                          >
                            No records Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {dataCount !== 0 && (
              <Card className="rounded-2 mb-2">
                <CardBody className="py-1 pagination-holder">
                  <TablePagination
                    className={classes["pagination_style"]}
                    rowsPerPageOptions={[10, 25, 100]}
                    backIconButtonProps={{
                      style: { color: currentPage === "1" ? "grey" : "black" },
                    }}
                    nextIconButtonProps={{
                      style: {
                        color:
                          Number(currentPage) === lastPage ? "grey" : "black",
                      },
                    }}
                    component="div"
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </div>

      {open && (
        <MemberForm
          open={open}
          onAlertShow={AlertShow}
          onCloseMemberAddDialog={closeMemberAddDialog}
          getAllMembersList={getAllMembersList}
        />
      )}
      {editMemberState.isOpenEdit && (
        <EditMemberForm
          openEdit={editMemberState.isOpenEdit}
          memberEditedData={editMemberState.editMemberData}
          getAllMembersList={getAllMembersList}
          onAlertShow={AlertShow}
          onCloseEditDialog={closeEditDialog}
        />
      )}
      {loginUpdateState.isLoginUpdate && (
        <MemberLoginUpdateForm
          openLoginUpdateForm={loginUpdateState.isLoginUpdate}
          loginId={loginUpdateState.loginUpdateId}
          onAlertShow={AlertShow}
          getAllMembersList={getAllMembersList}
          onCloseLoginUpdateDialog={closeLoginUpdateDialog}
        />
      )}
    </div>
  );
}

export default MemberList;
