import * as Yup from "yup";
export const DomainLabellingSchema = Yup.object().shape(
  {
    domain_name: Yup.string()
      .required("Domain Name Required")
      .matches(/^(?:[-A-Za-z0-9]+\.)+[A-Za-z]{2,6}$/, "Invalid domain name"),
    app_name: Yup.string().required("Product Name Required"),
    client_type: Yup.string().required("Client type field can not be blank"),
    user: Yup.string()
      .ensure()
      .when("client_type", {
        is: (client_type) => !client_type||client_type.length===0,
        then: Yup.string().required("Please choose a client type first"),
        otherwise: Yup.string().required('Client name field can not be blank'),
      }),
  },
  [["user","client_type"]]
);

export const DomainLabellingClientSchema = Yup.object({
  domain_name: Yup.string()
    .required("Domain Name Required")
    .matches(/^(?:[-A-Za-z0-9]+\.)+[A-Za-z]{2,6}$/, "Invalid domain name"),
  app_name: Yup.string().required("Product Name Required"),
});
