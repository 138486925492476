import React, { useState } from "react";
import classnames from "classnames";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import { useHistory } from "react-router-dom";

import { useFormik } from "formik";
import { LoginSchema } from "utils/schemas/LoginSchema";
import classes from "../assets/css/Login.module.css";
import NotificationAlert from "react-notification-alert";
import { AxiosAPiPost } from "AxiosAPi";
// form data input //
const initialValues = {
  email: "",
  password: "",
};
// form data input //
function Login() {
  // Use to redirect //
  const history = useHistory();
  // Use to redirect //
  // Show notification popup //
  const notificationAlertRef = React.useRef(null);
  // Show notification popup //
  const state = {};
  // Loading state //
  const [loading, setLoading] = useState(false);
  // Loading state //
  // Input for password shown //
  const [passwordShown, setPasswordShown] = useState(false);
  // Input for password shown //
  const [focus, setFocus] = useState(false);
  // form submission and error handling //
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: LoginSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        AxiosAPiPost(
          `${process.env.REACT_APP_AXIOS_BASE_URL}/auth/login`,
          "post",
          {
            email: values.email.trim().toLowerCase(),
            password: values.password.trim(),
          },
          (res) => {
            if (res.data.status === true) {
              localStorage.setItem("access_token", res.data.access_token);
              localStorage.setItem("userData", JSON.stringify(res.data));
              localStorage.setItem(
                "permissions",
                JSON.stringify(res.data.data.permissions)
              );
              // window.location.reload();
              history.push(`/admin`);
            } else {
              LoginNotificationAlertError(res.data.message);
              setLoading(false);
            }
          },
          history,
          (err) => {
            LoginNotificationAlertError(err.response.data.errors[0].msg);
            setLoading(false);
          }
        );
        action.resetForm();
      },
    });

  // form submission and error handling //
  // functionality for password shown //
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  // functionality for password shown //
  const LoginNotificationAlertError = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="wrapper wrapper-full-page">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <div className="full-page login-page d-flex align-items-center justify-content-center">
          <div className={`content w-100 py-5 ${classes["content_padding"]}`}>
            <div className={classes["logo-content"]}>
              <img
                alt="Bawlio Logo"
                src={require("assets/img/BawlioNewLogo.png")}
              />
            </div>
            <Container>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form
                  className="form commonForm"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <Card className="card-login customColor4 p-1">
                    <CardHeader>
                      {/* <img
                        alt="..."
                        src={require("assets/img/card-info.png")}
                      /> */}
                      <CardTitle tag="h1" className="text-center">
                        Sign in
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={`mb-0 ${classnames({
                          "input-group-focus": focus,
                        })}`}
                        // id="password_input" onFocus={handleFocus}
                        // className={classnames({
                        //   "input-group-focus": state.emailFocus,
                        // })}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="bg-white">
                            <i className="tim-icons icon-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="bg-white"
                          placeholder="Enter your email address"
                          type="text"
                          name="email"
                          id="email"
                          value={values.email}
                          onChange={handleChange}
                          onFocus={() => setFocus(true)}
                          onBlur={() => setFocus(false)}
                        />
                      </InputGroup>
                      {errors.email && touched.email ? (
                        <p className={classes["error_text"]}>{errors.email}</p>
                      ) : null}
                      <InputGroup
                        className={`mb-0 mt-3 ${classnames({
                          "input-group-focus": state.passFocus,
                        })}`}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText className="bg-white">
                            <i className="tim-icons icon-lock-circle" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          className="bg-white"
                          placeholder="Enter your password"
                          type={passwordShown ? "text" : "password"}
                          name="password"
                          id="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <InputGroupAddon
                          addonType="append"
                          className={classes["password_prepend"]}
                        >
                          <InputGroupText className="bg-white border-right-0">
                            <i
                              onClick={togglePassword}
                              className={
                                passwordShown
                                  ? `fas fa-eye ${classes["password_toggle"]}`
                                  : `fas fa-eye-slash ${classes["password_toggle"]}`
                              }
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {errors.password && touched.password ? (
                        <p className={classes["error_text"]}>
                          {errors.password}
                        </p>
                      ) : null}
                    </CardBody>
                    <CardFooter>
                      <Button
                        block
                        disabled={!values.email || !values.password}
                        className="mb-4"
                        color="info"
                        type="submit"
                        size="lg"
                      >
                        {loading ? "Processing..." : "Sign In"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
