import { Dialog, DialogContent } from "@mui/material";
import React, { useRef, useState } from "react";
import classes from "../../assets/css/EditLoginUpdateForm.module.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import { AxiosAPiPost } from "AxiosAPi";
import { useHistory } from "react-router-dom";
// import ReferenceDataContext from 'AvatarContext';
import NotificationAlert from "react-notification-alert";
import { EditLoginUpdateFormSchema } from "utils/schemas/EditLoginUpdateFormSchema";
function MemberLoginUpdateForm({
  openLoginUpdateForm,
  loginId,
  onAlertShow,
  getAllMembersList,
  onCloseLoginUpdateDialog,
}) {
  // const {activeColor}=useContext(ReferenceDataContext)
  // Use to redirect to member list page after form submission //
  const history = useHistory();
  // Use to redirect to member list page after form submission //
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // Loading state //
  const [loading, setLoading] = useState(false);
  // Loading state //

  // form data inputs //
  const initialValues = {
    id: loginId,
    new_password: "",
    confirm_new_password: "",
  };
  // form data inputs //
  // Member password update form submission and error handling //
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: EditLoginUpdateFormSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        AxiosAPiPost(
          `${process.env.REACT_APP_AXIOS_BASE_URL}/members/updateLoginDetails`,
          "post",
          {
            id: values.id,
            new_password: values.new_password,
            confirm_new_password: values.confirm_new_password,
          },
          (res) => {
            if (res.data.status === true) {
              onAlertShow(res.data.message, "success");
              setLoading(false);
              getAllMembersList();
              onCloseLoginUpdateDialog();
            } else {
              let options = {};
              options = {
                place: "tr",
                message: res.data.message,
                type: "warning",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3,
              };
              notificationAlertRef.current.notificationAlert(options);
              setLoading(false);
            }
          },
          history,
          (err) => {
            let options = {};
            options = {
              place: "tr",
              message: err.response.data.message,
              type: "warning",
              icon: "tim-icons icon-bell-55",
              autoDismiss: 3,
            };
            notificationAlertRef.current.notificationAlert(options);
            setLoading(false);
          }
        );
        action.resetForm();
      },
    });
  // Member password update form submission and error handling //
  // Close dialog box //
  const handleClose = () => {
    onCloseLoginUpdateDialog();
  };
  // Close dialog box //
  return (
    <div className={classes["client_form_content"]}>
      <Dialog
        open={openLoginUpdateForm}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
          },
        }}
      >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <DialogContent dividers className="px-0 px-sm-3 px-md-4">
          <div className={`popupForm-holder ${classes["dialog_content"]}`}>
            <Row>
              <Col md="12">
                <Form
                  id="RegisterValidation"
                  onSubmit={handleSubmit}
                  className="commonForm popupForm"
                >
                  <Card className="customColor4">
                    <CardHeader>
                      <CardTitle
                        className={`darkText labelText mb-0 ${classes["view_icon_cross"]}`}
                        tag="h4"
                      >
                        Update Password Form
                        <i
                          onClick={handleClose}
                          className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
                        ></i>
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">New Password *</label>
                        <Input
                          className="bg-white"
                          name="new_password"
                          placeholder="Enter new password"
                          type="password"
                          value={values.new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.new_password && touched.new_password ? (
                          <p className={classes["form_error"]}>
                            {errors.new_password}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <label className="darkText">
                          Confirm New Password *
                        </label>
                        <Input
                          className="bg-white"
                          name="confirm_new_password"
                          placeholder="Re enter new password"
                          type="password"
                          value={values.confirm_new_password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.confirm_new_password &&
                        touched.confirm_new_password ? (
                          <p className={classes["form_error"]}>
                            {errors.confirm_new_password}
                          </p>
                        ) : null}
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="text-center btn-set">
                      <Button
                        color="info"
                        type="submit"
                        disabled={
                          !values?.new_password || !values.confirm_new_password
                        }
                      >
                        {loading ? "Processing..." : "Update"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MemberLoginUpdateForm;
