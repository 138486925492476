import { Dialog, DialogContent, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import classes from "../../assets/css/ClientForm.module.css";
import MaskedInput from "react-text-mask";
import { useHistory } from "react-router-dom";
import { AxiosAPiPost } from "AxiosAPi";
import { AxiosAPiGet } from "AxiosAPi";
import NotificationAlert from "react-notification-alert";
import { useFormik } from "formik";
import { ClientFormSchema } from "utils/schemas/ClientFormSchema";
// import ReferenceDataContext from "AvatarContext";

function EditClientForm({
  openEdit,
  onAlertShow,
  editedClientId,
  getAllClientList,
  onCloseEditDialog,
}) {
  // const {activeColor}=useContext(ReferenceDataContext)

  // Use to redirect to client list page after form submission //
  const history = useHistory();
  // Use to redirect to client list page after form submission //

  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //

  // Use for loading condition handling //
  const [loading, setLoading] = useState(false);
  // Use for loading condition handling //

  // form data inputs //
  const [editFormData, setEditFormData] = useState({
    client_id: "",
    client_type: "",
    client_first_name: "",
    client_last_name: "",
    client_email: "",
    client_name: "",
    client_phone_number: "",
    company_name: "",
    client_status: "",
    lead_price: "",
  });
  // form data inputs //

  // Fetch data by client Id //
  useEffect(() => {
    setLoading(true);
    AxiosAPiGet(
      `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/view/${editedClientId}`,
      "get",
      (res) => {
        var data = res.data.results.result;

        var first_name = "";
        var last_name = "";
        if (data.first_name) {
          first_name = data.first_name;
        } else {
          first_name = data.name;
        }

        if (data.last_name) {
          last_name = data.last_name;
        } else {
          last_name = "";
        }

        setEditFormData({
          client_id: data.clientId,
          client_type: data.client_type,
          client_first_name: first_name,
          client_last_name: last_name,
          client_email: data.email,
          client_phone_number: data.phone,
          company_name: data.company_name,
          client_name: data.name,
          client_status: data.status,
          lead_price: data.lead_price,
        });
        setLoading(false);
      },
      history,
      (err) => {}
    );
  }, [history, editedClientId]);
  // Fetch data by client Id //
  // Set initial values //
  const initialValues = {
    id: editedClientId,
    clientId: editFormData.client_id,
    client_type: editFormData.client_type,
    name: editFormData.client_name,
    first_name: editFormData?.client_first_name,
    last_name: editFormData.client_last_name,
    email: editFormData.client_email.trim(),
    phone: editFormData.client_phone_number,
    company_name: editFormData.company_name,
    status: editFormData.client_status,
    lead_price: editFormData.lead_price,
  };
  // Set initial values //
  // Submit edit client form and form validation,error handling //
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ClientFormSchema,
    onSubmit: (values, action) => {
      setLoading(true);
      AxiosAPiPost(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/clients/update`,
        "post",
        values,
        (response) => {
          if (response.data.status === true) {
            onAlertShow(response.data.message, "success");
            setLoading(false);
            getAllClientList();
            action.resetForm();
            onCloseEditDialog();
          } else {
            EditClientFormNotificationError(response.data.message);
            setLoading(false);
          }
        },
        history,
        (err) => {
          if (err.response.data.status === false) {
            setLoading(false);
            if (err.response.status === 422) {
              if (err.response.data.errors.clientId) {
                EditClientFormNotificationError(
                  err.response.data.errors.clientId.message
                );
              }
              if (err.response.data.errors.email) {
                EditClientFormNotificationError(
                  err.response.data.errors.email.message
                );
              }
              if (err.response.data.errors.lead_price) {
                EditClientFormNotificationError(
                  err.response.data.errors.lead_price.message
                );
              }
            } else {
              EditClientFormNotificationError(err.response.data.message);
            }
          }
        }
      );
    },
  });
  // Submit edit client form and form validation,error handling //

  // Close dialog box //
  const handleClose = () => {
    onCloseEditDialog();
  };
  // Close dialog box //
  const EditClientFormNotificationError = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  const handleKeyDownOrderMin = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <>
      <div className={classes["client_form_content"]}>
        <Dialog
          open={openEdit}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: "#eeefef",
            },
          }}
        >
          <div className="rna-container">
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <DialogContent dividers className="px-0 px-sm-3 px-md-4">
            <div className={`popupForm-holder ${classes["dialog_content"]}`}>
              <Row>
                <Col md="12">
                  <Form
                    id="RegisterValidation"
                    onSubmit={handleSubmit}
                    className="commonForm popupForm"
                  >
                    <Card className="customColor4">
                      <CardHeader>
                        <CardTitle
                          className={`darkText labelText mb-0 ${classes["view_icon_cross"]}`}
                          tag="h4"
                        >
                          Edit Client Form
                          <i
                            onClick={handleClose}
                            className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
                          ></i>
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Client Id <span className="text-danger">*</span>
                          </label>
                          <Input
                            name="clientId"
                            placeholder="Enter your first name"
                            type="text"
                            className={`bg-white ${classes["clientId_style"]}`}
                            min={0}
                            value={values.clientId}
                            readOnly
                          />
                        </FormGroup>

                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Client Type <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="client_type"
                            className={`w-100 bg-white ${classes["status_dropdown"]}`}
                            value={values.client_type}
                            onChange={handleChange}
                            displayEmpty
                            // onBlur={handleBlur}
                          >
                            <MenuItem value="">Choose client type</MenuItem>
                            <MenuItem value="api">API</MenuItem>
                            <MenuItem value="shopify">Shopify</MenuItem>
                          </Select>
                          {errors.client_type && touched.client_type ? (
                            <p className={classes["form_error"]}>
                              {errors.client_type}
                            </p>
                          ) : null}
                        </FormGroup>
                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Client First Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Input
                            placeholder="Enter your first name"
                            type="text"
                            name="first_name"
                            className="bg-white"
                            value={values.first_name}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                          />
                          {errors.first_name && touched.first_name ? (
                            <p className={classes["form_error"]}>
                              {errors.first_name}
                            </p>
                          ) : null}
                        </FormGroup>
                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Client Last Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Input
                            placeholder="Enter your Last name"
                            type="text"
                            className="bg-white"
                            name="last_name"
                            value={values.last_name}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                          />
                          {errors.last_name && touched.last_name ? (
                            <p className={classes["form_error"]}>
                              {errors.last_name}
                            </p>
                          ) : null}
                        </FormGroup>
                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <Input
                            name="email"
                            className="bg-white"
                            placeholder="Enter your email address"
                            type="email"
                            value={values.email}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                          />
                          {errors.email && touched.email ? (
                            <p className={classes["form_error"]}>
                              {errors.email}
                            </p>
                          ) : null}
                        </FormGroup>
                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Phone Number <span className="text-danger">*</span>
                          </label>

                          <MaskedInput
                            mask={[
                              "(",
                              /[1-9]/,
                              /\d/,
                              /\d/,
                              ")",
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            className="form-control bg-white"
                            placeholder="Enter a phone number"
                            name="phone"
                            type="text"
                            value={values.phone}
                            guide={true}
                            id="my-input-id"
                            onChange={(e) => {
                              setFieldValue(
                                "phone",
                                e.target.value.replace(/[^A-Z0-9]/gi, "")
                              );
                            }}
                            onBlur={handleBlur}
                          />
                          {errors.phone && touched.phone ? (
                            <p className={classes["form_error"]}>
                              {errors.phone}
                            </p>
                          ) : null}
                        </FormGroup>
                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Company Name <span className="text-danger">*</span>
                          </label>
                          <Input
                            placeholder="Enter Company Name"
                            type="text"
                            name="company_name"
                            className="bg-white"
                            value={values.company_name}
                            onChange={handleChange}
                          />

                          {errors.company_name && touched.company_name ? (
                            <p className={classes["form_error"]}>
                              {errors.company_name}
                            </p>
                          ) : null}
                        </FormGroup>
                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Lead Price ($){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Input
                            name="lead_price"
                            type="number"
                            className="bg-white"
                            step={0.01}
                            min={0}
                            value={values.lead_price}
                            placeholder="Lead Price"
                            onChange={handleChange}
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(e) => handleKeyDownOrderMin(e)}
                            // onBlur={handleBlur}
                          />
                          {errors.lead_price && touched.lead_price ? (
                            <p className={classes["form_error"]}>
                              {errors.lead_price}
                            </p>
                          ) : null}
                        </FormGroup>
                        <FormGroup className={`has-label`}>
                          <label className="darkText">
                            Status <span className="text-danger">*</span>
                          </label>
                          <Select
                            name="status"
                            className={`w-100 bg-white ${classes["status_dropdown"]}`}
                            value={values.status}
                            onChange={handleChange}
                            displayEmpty
                            // onBlur={handleBlur}
                          >
                            <MenuItem value="">Choose your Status</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Pause">Pause</MenuItem>
                            <MenuItem value="Hold">Hold</MenuItem>
                            <MenuItem value="Deactivated">Deactivated</MenuItem>
                          </Select>
                          {errors.status && touched.status ? (
                            <p className={classes["form_error"]}>
                              {errors.status}
                            </p>
                          ) : null}
                        </FormGroup>
                      </CardBody>
                      <CardFooter className="text-center btn-set">
                        <Button color="info" type="submit">
                          {loading ? "Processing..." : "Edit Client"}
                        </Button>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Row>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
}

export default EditClientForm;
