import React, { useRef, useState } from "react";
import classes from "../../assets/css/ClientForm.module.css";
import { Dialog, DialogContent, MenuItem, Select } from "@mui/material";
import { useHistory } from "react-router-dom";
// import ReferenceDataContext from 'AvatarContext';
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import MaskedInput from "react-text-mask";
import { AxiosAPiPost } from "AxiosAPi";
import { useFormik } from "formik";
import { MemberFormSchema } from "utils/schemas/MemberFormSchema";
function MemberForm({
  open,
  onCloseMemberAddDialog,
  getAllMembersList,
  onAlertShow,
}) {
  // const {activeColor}=useContext(ReferenceDataContext)
  // Use to redirect to member list page after form submission //
  const history = useHistory();
  // Use to redirect to member list page after form submission //

  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // Loading state //
  const [loading, setLoading] = useState(false);
  // Loading state //

  // form data inputs //
  const initialValues = {
    client_id: Date.now().toString().substring(0, 9),
    client_type: "",
    client_first_name: "",
    client_last_name: "",
    client_email: "",
    client_phone_number: "",
    client_status: "",
  };
  // form data inputs //

  // Add new member form submission and validation //
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues,
    validationSchema: MemberFormSchema,
    onSubmit: (values, action) => {
      const data = {
        clientId: values.client_id,
        roles: values.client_type,
        first_name: values.client_first_name,
        last_name: values.client_last_name,
        email: values.client_email.trim(),
        phone: values.client_phone_number,
        status: values.client_status,
      };
      setLoading(true);
      AxiosAPiPost(
        `${process.env.REACT_APP_AXIOS_BASE_URL}/members/save`,
        "post",
        data,
        (res) => {
          if (res.data.status === true) {
            onAlertShow(res.data.message, "success");
            setLoading(false);
            action.resetForm();
            getAllMembersList();
            onCloseMemberAddDialog();
          } else {
            SaveClientError(res.data.message);
            setLoading(false);
          }
        },
        history,
        (err) => {
          if (err.response.data.status === false) {
            setLoading(false);
            if (err.response.status === 422) {
              if (err.response.data.errors.clientId) {
                SaveClientError(err.response.data.errors.clientId.message);
              }
              if (err.response.data.errors.email) {
                SaveClientError(err.response.data.errors.email.message);
              }
              if (err.response.data.errors.lead_price) {
                SaveClientError(err.response.data.errors.lead_price.message);
              }
            } else {
              SaveClientError(err.response.data.message);
            }
          }
        }
      );
    },
  });
  // Add new member form submission and validation //

  // Close dialog box //
  const handleClose = () => {
    onCloseMemberAddDialog();
  };
  // Close dialog box //
  // Message shown on failed form submission //
  const SaveClientError = (message) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: "warning",
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // Message shown on failed form submission //
  // onChange phone number input //
  const handlePhoneNumberChange = (e) => {
    setFieldValue(
      "client_phone_number",
      e.target.value.replace(/[^A-Z0-9]/gi, "")
    );
  };
  // onChange phone number input //
  return (
    <div className={classes["client_form_content"]}>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#eeefef",
          },
        }}
      >
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <DialogContent dividers className="px-0 px-sm-3 px-md-4">
          <div className={`popupForm-holder ${classes["dialog_content"]}`}>
            <Row>
              <Col md="12">
                <Form
                  id="RegisterValidation"
                  onSubmit={handleSubmit}
                  className="commonForm popupForm"
                  noValidate
                >
                  <Card className="customColor4">
                    <CardHeader>
                      <CardTitle
                        className={`darkText labelText mb-0 ${classes["view_icon_cross"]}`}
                        tag="h4"
                      >
                        Add Member Form
                        <i
                          onClick={handleClose}
                          className={`fa-solid fa-xmark ${classes["cursor_style"]}`}
                        ></i>
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className="has-label">
                        <Label className="darkText labelText">
                          Member Id <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="client_id"
                          type="text"
                          min={1}
                          className="bg-white form-control w-100"
                          readOnly
                          value={values.client_id}
                          placeholder="Enter your client id"
                        />
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <Label className="darkText labelText">
                          Member Role <span className="text-danger">*</span>
                        </Label>
                        <Select
                          name="client_type"
                          className={`w-100 bg-white ${classes["status_dropdown"]}`}
                          value={values.client_type}
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value="">Choose member role</MenuItem>
                          <MenuItem value="admin">Admin</MenuItem>
                          <MenuItem value="super_admin">Super Admin</MenuItem>
                          <MenuItem value="developer">Developer</MenuItem>
                        </Select>
                        {errors.client_type && touched.client_type ? (
                          <p className={classes["form_error"]}>
                            {errors.client_type}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <Label className="darkText labelText">
                          First Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="client_first_name"
                          placeholder="Enter your first name"
                          type="text"
                          className="bg-white"
                          value={values.client_first_name}
                          onChange={handleChange}
                        />
                        {errors.client_first_name &&
                        touched.client_first_name ? (
                          <p className={classes["form_error"]}>
                            {errors.client_first_name}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <Label className="darkText labelText">
                          Last Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="client_last_name"
                          placeholder="Enter your last name"
                          type="text"
                          className="bg-white"
                          value={values.client_last_name}
                          onChange={handleChange}
                        />
                        {errors.client_last_name && touched.client_last_name ? (
                          <p className={classes["form_error"]}>
                            {errors.client_last_name}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <Label className="darkText labelText">
                          Email Address <span className="text-danger">*</span>
                        </Label>
                        <Input
                          name="client_email"
                          className="bg-white"
                          placeholder="Enter your email address"
                          type="email"
                          value={values.client_email}
                          onChange={handleChange}
                        />
                        {errors.client_email && touched.client_email ? (
                          <p className={classes["form_error"]}>
                            {errors.client_email}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className={`has-label`}>
                        <Label className="darkText labelText">
                          Phone Number <span className="text-danger">*</span>
                        </Label>
                        <MaskedInput
                          mask={[
                            "(",
                            /[1-9]/,
                            /\d/,
                            /\d/,
                            ")",
                            " ",
                            /\d/,
                            /\d/,
                            /\d/,
                            "-",
                            /\d/,
                            /\d/,
                            /\d/,
                            /\d/,
                          ]}
                          className="form-control bg-white"
                          placeholder="Enter a phone number"
                          name="client_phone_number"
                          guide={true}
                          value={values.client_phone_number}
                          id="my-input-id"
                          onBlur={handleBlur}
                          onChange={(e) => handlePhoneNumberChange(e)}
                        />

                        {errors.client_phone_number &&
                        touched.client_phone_number ? (
                          <p className={classes["form_error"]}>
                            {errors.client_phone_number}
                          </p>
                        ) : null}
                      </FormGroup>

                      <FormGroup className={`has-label`}>
                        <Label className="darkText labelText">
                          Member Status <span className="text-danger">*</span>
                        </Label>

                        <Select
                          name="client_status"
                          className={`w-100 bg-white ${classes["status_dropdown"]}`}
                          value={values.client_status}
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value="">Choose your Status</MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Deactivated">Deactivated</MenuItem>
                        </Select>

                        {errors.client_status && touched.client_status ? (
                          <p className={classes["form_error"]}>
                            {errors.client_status}
                          </p>
                        ) : null}
                      </FormGroup>
                    </CardBody>
                    <CardFooter className="text-center btn-set">
                      <Button color="info" type="submit">
                        {loading ? "Processing..." : "Add Member"}
                      </Button>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default MemberForm;
