import {
  Box,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import classes from "../../assets/css/NotificationCenter.module.css";
import { FaTimesCircle } from "react-icons/fa";
import { AxiosAPiGet } from "AxiosAPi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useFormik } from "formik";
import { NotificationSchema } from "utils/schemas/NotificationSchema";
import axios from "axios";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function NotificationCenter() {
  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  //    Use to redirect //
  const history = useHistory(null);
  //    Use to redirect //
  const [loading, setLoading] = useState(false);
  // selected menu array //
  const [personName, setPersonName] = React.useState([]);
  // selected menu array //
  // client type inputs //
  const [clientType, setClientType] = React.useState("");
   // client type inputs //
  // name list array //
  const [names, setNames] = useState([]);
  // name list array //
  const [modifiedName, setModifiedName] = useState("");
  // input for changing labels based on roles //
  const [listLabel, setListLabel] = useState("");
  // input for changing labels based on roles //
//input for notification roles //
  const [notificationRole, setNotificationRole] = useState("");
  //input for notification roles //
  // inputs for fields //
  const initialValues = {
    role: "",
    client_type: "",
    user_string: "",
    users: [],
    notification: "",
  };
  // inputs for fields //
  // Submit the form //
  const { values, errors, touched, handleChange, setFieldValue, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: NotificationSchema,
      onSubmit: (values, action) => {
        setLoading(true);
        const data = {
          role: values.role,
          client_type: values.client_type,
          users: modifiedName,
          notification: values.notification,
        };
        axios
          .post(
            `${process.env.REACT_APP_AXIOS_BASE_URL}/notifications/save`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                token: localStorage.getItem("access_token"),
              },
            }
          )
          .then((res) => {
            if (res.data.status === true) {
              let options = {};
              options = {
                place: "tr",
                message: res.data.message,
                type: "success",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3,
              };
              notificationAlertRef.current.notificationAlert(options);
              values.role = "";
              setFieldValue("role", "");
              setNotificationRole("");
              values.client_type = "";
              setClientType("");
              setPersonName([]);
              values.user_string = "";
              values.users = [];
            }
          })
          .catch((err) => {
            if (err.response.data.message === "Invalid Token") {
              localStorage.removeItem("access_token");
              localStorage.removeItem("userData");
              history.push("/login");
            } else {
              let options = {};
              options = {
                place: "tr",
                message: err.response.data.message,
                type: "warning",
                icon: "tim-icons icon-bell-55",
                autoDismiss: 3,
              };
              notificationAlertRef.current.notificationAlert(options);
            }
          });
        action.resetForm();
      },
    });
  // Submit the form //
  // selected name list //
  const isAllSelected = names.length > 0 && personName.length === names.length;
  // selected name list //
  //Changing labels based on roles //
  useEffect(() => {
    if (notificationRole !== "") {
      setListLabel(document.getElementById("client_status").innerText);
    }
  }, [notificationRole]);
  //Changing labels based on roles //
  // Take the id's of the selected value options //
  useEffect(() => {
    if (personName?.length !== 0) {
      let newArr = personName?.map((person) => {
        return person?.split(" ")[person?.split(" ").length - 1];
      });
      let secondArr = newArr?.map((val) => {
        return val?.substr(1)?.substr(0, val?.substr(1).length - 1);
      });
      setModifiedName(secondArr);
    }
  }, [personName]);
  // Take the id's of the selected value options //

  // initial list on useEffect //
  useEffect(() => {
    let url = "";
    const NotificationPeopleList = (url) => {
      AxiosAPiGet(
        url,
        "get",
        (res) => {
          setNames(
            res?.data?.results?.results.data?.map((name) => {
              let updatedName;
              switch (values.role) {
                case "":
                  break;
                case "client":
                  updatedName = name?.hasOwnProperty("company_name")
                    ? name?.company_name === "" ||
                      name?.company_name === undefined ||
                      name?.company_name === null
                      ? name?.name === "" ||
                        name?.name === undefined ||
                        name?.name === null
                        ? {
                            ...name,
                            updated_name: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                          }
                        : {
                            ...name,
                            updated_name: `${name?.name} (${name?.clientId})`,
                          }
                      : {
                          ...name,
                          updated_name: `${name.company_name} (${name?.clientId})`,
                        }
                    : name?.name === "" ||
                      name?.name === undefined ||
                      name?.name === null
                    ? {
                        ...name,
                        updated_name: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                      }
                    : {
                        ...name,
                        updated_name: `${name?.name} (${name?.clientId})`,
                      };
                  break;
                default:
                  updatedName =
                    name?.name === "" ||
                    name?.name === undefined ||
                    name?.name === null
                      ? {
                          ...name,
                          updated_name: `${name?.first_name} ${name?.last_name} (${name?.clientId})`,
                        }
                      : {
                          ...name,
                          updated_name: `${name?.name} (${name?.clientId})`,
                        };
                  break;
              }
              return { updatedName };
            })
          );
        },
        history,
        (err) => {
          let options = {};
          options = {
            place: "tr",
            message: err.response.data.message,
            type: "warning",
            icon: "tim-icons icon-bell-55",
            autoDismiss: 3,
          };
          notificationAlertRef.current.notificationAlert(options);
        }
      );
    };
    switch (notificationRole) {
      case "":
        break;
      case "client":
        switch (clientType) {
          case "":
            setNames([])
            break;
          case "all":
            url = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","is_test_account","status"],"where_values":["${values.role}","false","Active"]}`;
            NotificationPeopleList(url);
            break;
          default:
            url = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","client_type","is_test_account","status"],"where_values":["${values.role}","${values.client_type}","false","Active"]}`;
            NotificationPeopleList(url);
            break;
        }
        break;

      default:
        url = `${process.env.REACT_APP_AXIOS_BASE_URL}/users/list?where_clause={"where_fields":["roles","is_test_account","status"],"where_values":["${values.role}","false","Active"]}`;
        NotificationPeopleList(url);
        break;
    }
  }, [history, values, clientType, notificationRole]);
  // initial list on useEffect //
  // Modifing people list //
  const handleChangePersonName = (e, key) => {
    const {
      target: { value },
    } = e;
    if (value[value?.length - 1] === "all") {
      let newNames = names?.map((name) => name?.updatedName?.updated_name);
      setPersonName(personName?.length === names.length ? [] : newNames);
      values.user_string =
        personName?.length === names.length ? [].join() : newNames.join();
        setFieldValue("user_string",personName?.length === names.length ? [].join() : newNames.join())
      return;
    }
    setPersonName(typeof value === "string" ? value.split(",") : value);
    values.user_string = value.join();
    setFieldValue("user_string",value.join())
  };
  // Modifing people list //
  // Delete from selected list //
  const handleDelete = (value) => {
    let remove_names = personName?.filter((name) => name !== value);
    setPersonName(remove_names);
    values.user_string = remove_names.join();
    setFieldValue("user_string",remove_names.join())
  };
  // Delete from selected list //
  const handleRoleChange = (e) => {
    setClientType("");
    values.role = e.target.value;
    setNotificationRole(e.target.value);
    setFieldValue("role", e.target.value);
    setPersonName([]);
    setFieldValue("client_type", "");
    setFieldValue("user_string", "");
  };
  const handleClientTypeChange = (e) => {
    values.client_type = e.target.value;
    setClientType(e.target.value);
    setFieldValue("user_string", "");
    setPersonName([]);
  };
  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="customColor4 p-3 p-sm-4 rounded-2">
        <Form
          className="selectBox customColor6 rounded-2 py-3 px-sm-3 mb-4"
          onSubmit={handleSubmit}
        >
          <Card className="trnstprnt-bg commonForm mb-0">
            <p className="select-title textColor pt-1 pt-md-2 px-3">
              Notification Center{" "}
            </p>
            <CardBody>
              <Row>
                <Col lg={values.role === "" ? "12" : "3"} sm="6">
                  <FormGroup>
                    <Label className="darkText labelText">
                      Send Notification:
                    </Label>
                    <Select
                      className={`w-100 bg-white ${classes["client_status_input"]}`}
                      name="role"
                      id="client_status"
                      value={values.role}
                      onChange={(e) => handleRoleChange(e)}
                      displayEmpty
                    >
                      <MenuItem value="">Choose a Role</MenuItem>
                      <MenuItem value="client">Client</MenuItem>
                      <MenuItem value="admin">Admin</MenuItem>
                      <MenuItem value="developer">Developer</MenuItem>
                      <MenuItem value="super_admin">Super Admin</MenuItem>
                    </Select>
                    {errors.role && touched.role ? (
                      <p className={classes["form_error"]}>{errors.role}</p>
                    ) : null}
                  </FormGroup>
                </Col>
                {values.role === "client" && (
                  <Col lg="3" sm="6">
                    <FormGroup>
                      <Label className="darkText labelText">Client Type:</Label>
                      <Select
                        className={`w-100 bg-white ${classes["client_status_input"]}`}
                        name="client_type"
                        value={values.client_type}
                        onChange={(e) => handleClientTypeChange(e)}
                        displayEmpty
                      >
                        <MenuItem value="">Choose client type</MenuItem>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="api">API</MenuItem>
                        <MenuItem value="shopify">Shopify</MenuItem>
                      </Select>
                      {errors.client_type && touched.client_type ? (
                        <p className={classes["form_error"]}>
                          {errors.client_type}
                        </p>
                      ) : null}
                    </FormGroup>
                  </Col>
                )}
                {values.role !== "" && (
                  <Col lg={values.role !== "client" ? "9" : "6"} sm="6">
                    <FormGroup>
                      <Label className="darkText labelText">{`${listLabel} List:`}</Label>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        className={`w-100 bg-white ${classes["people_name_input"]}`}
                        multiple
                        // readOnly={
                        //   values.role === "client" && values.client_type === ""
                        // }
                        value={personName}
                        onChange={(e) => handleChangePersonName(e)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return `Choose ${listLabel} Name`;
                          }
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.6,
                              }}
                            >
                              {selected.map((value, i) => (
                                <Chip
                                  key={i}
                                  label={value}
                                  deleteIcon={
                                    <FaTimesCircle
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                  clickable
                                  onDelete={() => handleDelete(value)}
                                />
                              ))}
                            </Box>
                          );
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                      >
                        {names?.length !== 0 && (
                          <MenuItem disabled value="">
                            {`Choose ${listLabel} Name`}
                          </MenuItem>
                        )}
                        {names?.length !== 0 && (
                          <MenuItem value="all">
                            <Checkbox
                              checked={isAllSelected}
                              indeterminate={
                                personName.length > 0 &&
                                personName.length < names.length
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                        )}
                        {names.length !== 0 ? (
                          names?.map((name, i) => (
                            <MenuItem
                              key={i}
                              value={name?.updatedName?.updated_name}
                            >
                              <Checkbox
                                checked={
                                  personName.indexOf(
                                    `${name?.updatedName?.updated_name}`
                                  ) > -1
                                }
                              />
                              <ListItemText
                                primary={name?.updatedName?.updated_name}
                              />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem
                            className={classes["no_items_data"]}
                          >{`No ${listLabel} list found`}</MenuItem>
                        )}
                      </Select>
                      {errors.user_string && touched.user_string ? (
                        <p className={classes["form_error"]}>
                          {errors.user_string}
                        </p>
                      ) : null}
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg="12" sm="6">
                  <FormGroup>
                    <Label className="darkText labelText">
                      Notification Content:
                    </Label>
                    <textarea
                      className={`w-100 bg-white ${classes["textarea_input"]}`}
                      name="notification"
                      type="textarea"
                      rows={10}
                      placeholder="Enter Notification Content"
                      value={values.notification}
                      onChange={handleChange}
                    ></textarea>
                    {errors.notification && touched.notification ? (
                      <p className={classes["form_error"]}>
                        {errors.notification}
                      </p>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col
                  lg="12"
                  className={`btn-set ${classes["filter_buttons"]} d-flex justify-content-end`}
                >
                  <Button
                    color="info"
                    type="submit"
                
                  >
                    {loading ? "Processing" : "Send Notification"}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </div>
    </div>
  );
}

export default NotificationCenter;
