import React, { useRef} from 'react'
import NotificationAlert from "react-notification-alert";

// reactstrap components
import {
    Card,
    CardBody,
    Row,
    Col,
    Button,
  } from "reactstrap";
  import classes from "../../assets/css/ComingSoon.module.css"
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
function ComingSoon() {
  // Notification popup //
  const notificationAlertRef = useRef(null);
   // Notification popup //
   // Use to redirect //
  const history=useHistory()
     // Use to redirect //
  return (
    <>
      <div className="content">
        <div className="rna-container">
          <NotificationAlert ref={notificationAlertRef} />
        </div>
        <Row>
          <Col>
            <Card className="customColor4 p-3 p-sm-4 rounded-2">
              <CardBody className={`${classes["coming_soon_body"]}`}>
                <h1
                  className={`darkText text-uppercase ${classes["coming_soon_head"]}`}
                >
                  Coming Soon
                </h1>
                <h3 className={`darkText ${classes["coming_soon_desc"]}`}>
                  We'll notify you once we enable this feature.
                </h3>
                <div className={`darkText ${classes["coming_soon_div"]}`}></div>
                <Button
                  className="text-center btn-set"
                  color="info"
                  type="button"
                  onClick={() => history.push("/admin/dashboard")}
                >
                  Go to App Club
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}


export default ComingSoon;
