import { Skeleton, TablePagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { FaSearch } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import NotificationAlert from "react-notification-alert";
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import classes from "../../assets/css/ActivityLog.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivityDetails } from "features/ActivityLogSlice";
import { getActivityLogList } from "features/ActivityLogSlice";
import { getActivityLogLoaded } from "features/ActivityLogSlice";
import { format } from "date-fns";
import { activityLogActions } from "features/ActivityLogSlice";
function ActivityLog() {
  // Use to dispatch actions //
  const disptach = useDispatch();
  // Use to dispatch actions //
  // Get activity log list from ActivityLogSlice.js //
  const activityLogList = useSelector(getActivityLogList);
  // Get activity log list from ActivityLogSlice.js //
  // Get activity log list loading state from ActivityLogSlice.js //
  const activityLogListLoaded = useSelector(getActivityLogLoaded);
  // Get activity log list loading state from ActivityLogSlice.js //
  //    Use to redirect //
  const history = useHistory();
  //    Use to redirect //
  // Pagination page input //
  const [page, setPage] = useState(0);
  // Pagination page input //
  // Use to show table div when load //
  const tableRef = useRef(null);
  // Use to show table div when load //

  // Show notification popup //
  const notificationAlertRef = useRef(null);
  // Show notification popup //
  // Pagination number of rows per page //
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // Pagination number of rows per page //
  // input for start date //
  const [startDate, setStartDate] = useState("");
  // input for start date //
  // input for end date //
  const [endDate, setEndDate] = useState("");
  // input for end date //
  // initial search fields //
  const [searchOptions, setSearchOptions] = useState("");
  // initial search fields //
  //    Loading initial data using useEffect //
  useEffect(() => {
    disptach(
      fetchActivityDetails({
        activityUrl: "/changelogs/list",
        searchOptions: searchOptions,
        page: page,
        rowsPerPage: rowsPerPage,
        alertShow: AlertShow,
        history: history,
      })
    );
  }, [history, page, rowsPerPage, disptach, searchOptions]);
  //    Loading initial data using useEffect //

  //Pagination page change and search functionality//
  const handleSearch = () => {
    setPage(0);
    var start_date = startDate !== "" ? format(startDate, "yyyy-MM-dd") : "";
    var end_date = endDate !== "" ? format(endDate, "yyyy-MM-dd") : "";
    if (
      start_date !== "" &&
      end_date !== "" &&
      new Date(end_date) >= new Date(start_date)
    ) {
      tableRef?.current?.scrollIntoView();
      setSearchOptions({ start_date: start_date, end_date: end_date });
    } else {
      if (new Date(start_date) > new Date(end_date)) {
        disptach(
          activityLogActions.emptySearch({
            alertShow: AlertShow,
            message: "Start date should not be greater than end date",
          })
        );
      }
      if (start_date === "" && end_date !== "") {
        disptach(
          activityLogActions.emptySearch({
            alertShow: AlertShow,
            message: "Start date should not be empty",
          })
        );
      }
      if (start_date !== "" && end_date === "") {
        disptach(
          activityLogActions.emptySearch({
            alertShow: AlertShow,
            message: "End date should not be empty",
          })
        );
      }
    }
  };
  //Pagination page change and search functionality//

  const handleStartDate = (e) => {
    setStartDate(e);
  };
  // onChange function for start date & end date //

  const handleEndDate = (e) => {
    setEndDate(e);

    const newStartDate = new Date(startDate);
    const newEndDate = new Date(e);

    if (newStartDate > newEndDate) {
      setStartDate("");
    }
  };
  // onChange function for start date & end date

  // General function for showing popup //
  const AlertShow = (message, type) => {
    let options = {};
    options = {
      place: "tr",
      message: message,
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  // General function for showing popup //

  // Handle page change in pagination //
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableRef?.current?.scrollIntoView();
  };
  // Handle page change in pagination //

  //Rows per page in pagination //

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  //Rows per page in pagination //

  // Reset filetered data //
  const handleReset = () => {
    setPage(0);
    setStartDate("");
    setEndDate("");
    setSearchOptions("");
  };

  // Reset filetered data //

  // Skeleton loader //
  const ActivityLogListLoader = () => {
    return [...Array(rowsPerPage)].map((val, index) => (
      <tr key={index} className={`${classes["acitvity_style"]}`}>
        <td className={`textColor ${classes["acitivity_style"]}`}>
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className={`textColor ${classes["acitivity_style"]}`}>
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className={`textColor ${classes["acitivity_style"]}`}>
          <Skeleton animation="wave" variant="text" />
        </td>
        <td className={`textColor ${classes["acitivity_style"]}`}>
          <Skeleton animation="wave" variant="text" />
        </td>
      </tr>
    ));
  };
  // Skeleton loader //
  return (
    <div className="content">
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="customColor4 p-3 p-sm-4 rounded-2">
        <Form className="selectBox customColor6 rounded-2 py-3 px-sm-3 mb-4">
          <Card className="trnstprnt-bg commonForm mb-0">
            <p className="select-title textColor pt-1 pt-md-2 px-3">
              Activity Log
            </p>
            <CardBody>
              <Row>
                <Col lg="6" sm="6">
                  <FormGroup className="d-flex flex-column">
                    <Label className="darkText labelText">Start Date:</Label>
                    <ReactDatePicker
                      name="created_start_date"
                      className="form-control bg-white"
                      selected={startDate}
                      value={startDate}
                      maxDate={new Date()}
                      onChange={(e) => handleStartDate(e)}
                      dateFormat="MM-dd-yyyy"
                      placeholderText={"Start Date"}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6" sm="6" className="mt-3 mt-sm-0">
                  <FormGroup className="d-flex flex-column">
                    <Label className="darkText labelText">End Date:</Label>
                    <ReactDatePicker
                      className="form-control bg-white"
                      name="cancel_end_date"
                      type="date"
                      placeholderText={"End Date"}
                      selected={endDate}
                      value={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      dateFormat="MM-dd-yyyy"
                      // onKeyDown={(e) => handleKeyDownEndDate(e)}
                      onChange={(e) => handleEndDate(e)}
                      onKeyDown={(e) => e.preventDefault()}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="4"></Col>
                <Col lg="8" className={`btn-set ${classes["filter_buttons"]}`}>
                  <Button
                    color="info"
                    type="button"
                    disabled={!startDate && !endDate}
                    onClick={handleSearch}
                  >
                    {" "}
                    <FaSearch /> Search
                  </Button>
                  <Button
                    className="custom-bg"
                    color="info"
                    type="button"
                    onClick={handleReset}
                  >
                    <i className="fa-sharp fa-solid fa-rotate-left"></i> Reset
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
        <Row>
          <Col>
            {activityLogList?.length !== 0 ? (
              activityLogList[0].totalCount && (
                <Card className="rounded-2 mb-0">
                  <CardBody className="py-1 pagination-holder">
                    <TablePagination
                      className={classes["pagination_style"]}
                      rowsPerPageOptions={[10, 25, 100]}
                      slotProps={{
                        actions: {
                          previousButton: {
                            color:
                              Number(activityLogList[0].currentPage) === 1
                                ? "#fff"
                                : "black",
                          },
                          nextButton: {
                            color:
                              Number(activityLogList[0].currentPage) ===
                              activityLogList[0].lastPage
                                ? "#fff"
                                : "black",
                          },
                        },
                      }}
                      component="div"
                      count={activityLogList[0].totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardBody>
                </Card>
              )
            ) : (
              <></>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="rounded-2 mb-2 my-3">
              <CardBody>
                <div
                  className={`${classes["table-responsive"]} table-full-width`}
                  ref={tableRef}
                >
                  <Table className={`text-center ${classes["table_width"]}`}>
                    <tbody>
                      <tr>
                        <th className="darkText labelText">Role</th>
                        <th className="darkText labelText">Action</th>

                        <th className="darkText labelText">Message</th>

                        <th className="darkText labelText">Date & Time</th>
                      </tr>
                      {activityLogListLoaded === "idle" ||
                      activityLogListLoaded === "loading" ? (
                        ActivityLogListLoader()
                      ) : activityLogList?.length !== 0 ? (
                        activityLogList[0].acitivityLogList?.map(
                          (activity, i) => {
                            let remove_char =
                              activity?.operation_action?.replace(
                                /[^a-z\d\s]+/gi,
                                ""
                              );
                            let operation_action_array =
                              remove_char?.split(" ");
                            let operation_action_array_space_remove =
                              operation_action_array?.filter(
                                (action) => action !== ""
                              );
                            if (
                              activity?.operation_role[0] === "customer" &&
                              operation_action_array_space_remove?.length > 1 &&
                              operation_action_array_space_remove[1] ===
                                "SALE" &&
                              operation_action_array_space_remove[2] !==
                                "COMPLETE"
                            ) {
                              operation_action_array_space_remove?.push(
                                "COMPLETE"
                              );
                            }

                            let operation_message_array =
                              activity?.Operation_message?.split(", ");
                            let operation_message_array_customer =
                              activity?.operation_role[0] === "customer" &&
                              activity?.Operation_message?.indexOf("SALE") ===
                                12 &&
                              activity?.Operation_message?.indexOf(
                                "COMPLETE"
                              ) !== 40
                                ? [
                                    activity?.Operation_message.slice(0, 40) +
                                      "COMPLETE" +
                                      activity?.Operation_message.slice(40),
                                  ][0]?.split(", ")
                                : activity?.Operation_message?.split(", ");
                            let created_date = activity?.createdAt?.split(" ");

                            return (
                              <tr
                                key={i}
                                className={`${classes["acitvity_style"]}`}
                              >
                                <td
                                  className={`textColor ${classes["acitivity_style"]}`}
                                >
                                  {activity?.operation_role[0] === "customer"
                                    ? `Client${
                                        activity?.operation_type === "Auto"
                                          ? `-${activity?.operation_by}`
                                          : ""
                                      }`
                                    : `${activity?.operation_role[0]
                                        ?.charAt(0)
                                        .toUpperCase()}${activity?.operation_role[0]?.slice(
                                        1
                                      )}`}
                                </td>

                                {activity?.operation_role[0] === "customer" ? (
                                  <td
                                    className={`textColor ${classes["acitivity_style"]}`}
                                  >
                                    {operation_action_array_space_remove?.map(
                                      (action, indexAction) => (
                                        <div key={indexAction}>
                                          {action}
                                          <br />
                                        </div>
                                      )
                                    )}
                                  </td>
                                ) : (
                                  <td
                                    className={`textColor ${classes["acitivity_style"]}`}
                                  >
                                    {activity?.operation_action === "login"
                                      ? "Login"
                                      : activity?.operation_action}
                                  </td>
                                )}
                                {activity?.operation_role[0] === "customer" ? (
                                  <td
                                    className={`textColor ${classes["acitivity_style"]}`}
                                  >
                                    {operation_message_array?.map(
                                      (message, indexMessage) =>
                                        operation_message_array[0] ===
                                        message ? (
                                          activity?.operation_action ===
                                            "Customer creation" ||
                                          activity?.operation_action ===
                                            "Customer Deactivation" ? (
                                            <div key={indexMessage}>
                                              {message}
                                            </div>
                                          ) : (
                                            <b key={indexMessage}>
                                              {message}
                                              <br />
                                            </b>
                                          )
                                        ) : (
                                          <div key={indexMessage}>
                                            {operation_message_array_customer[1]
                                              ?.split(" ")
                                              ?.map((character, indexChar) =>
                                                character ===
                                                character?.toUpperCase() ? (
                                                  <b key={indexChar}>
                                                    {character}{" "}
                                                  </b>
                                                ) : (
                                                  <span key={indexChar}>
                                                    {character}{" "}
                                                  </span>
                                                )
                                              )}
                                          </div>
                                        )
                                    )}
                                  </td>
                                ) : (
                                  <td
                                    className={`textColor ${classes["acitivity_style"]}`}
                                  >
                                    {activity?.Operation_message}
                                  </td>
                                )}
                                <td
                                  className={`textColor ${classes["acitivity_style"]}`}
                                >
                                  {created_date[0]}
                                  <br />
                                  {created_date[1]}
                                </td>
                              </tr>
                            );
                          }
                        )
                      ) : (
                        <tr>
                          <td
                            colSpan={12}
                            className={classes["no_records_title"]}
                          >
                            No records Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {activityLogList?.length !== 0 ? (
              activityLogList[0].totalCount && (
                <Card className="rounded-2 mb-0">
                  <CardBody className="py-1 pagination-holder">
                    <TablePagination
                      className={classes["pagination_style"]}
                      rowsPerPageOptions={[10, 25, 100]}
                      slotProps={{
                        actions: {
                          previousButton: {
                            color:
                              Number(activityLogList[0].currentPage) === 1
                                ? "#fff"
                                : "black",
                          },
                          nextButton: {
                            color:
                              Number(activityLogList[0].currentPage) ===
                              activityLogList[0].lastPage
                                ? "#fff"
                                : "black",
                          },
                        },
                      }}
                      component="div"
                      count={activityLogList[0].totalCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </CardBody>
                </Card>
              )
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ActivityLog;
