import * as Yup from "yup";

export const ClientFormSchema = Yup.object({
  client_type: Yup.string().required("Client type can not be blank"),
  first_name: Yup.string().required("Client first name can not be blank"),

  last_name: Yup.string().required("Client last name can not be blank"),
  email: Yup.string()
    .email("Invalid Email")
    .required("Client email can not be blank"),
  company_name: Yup.string().required("Company name can not be blank"),
  lead_price: Yup.number().required("Lead price can not be blank"),
  phone: Yup.string()
    .min(10, "Phone no is invalid")
    .required("Client phone number can not be blank"),
  status: Yup.string().required("Client status can not be blank"),
});
