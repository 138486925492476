import axios from "axios";
// Generalise Get api calling //
export async function AxiosAPiGet(
  url,
  method,
  callback,
  history,
  errorCallBack
) {
  return await axios({
    method: method,
    url: url,
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("access_token"),
    },
  })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      if (err.response?.data.message === "Invalid Token") {
        localStorage.clear();
        history.push("/login");
      } else {
        errorCallBack(err);
      }
    });
}
// Generalise Get api calling //
// Generalise Post api calling //
export async function AxiosAPiPost(
  url,
  method,
  data,
  callback,
  history,
  errorCallBack
) {
  return await axios({
    method: method,
    url: url,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      token: localStorage.getItem("access_token"),
    },
  })
    .then((res) => {
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // console.log(err);

      if (err.response.data.message === "Invalid Token") {
        localStorage.clear();
        history.push("/login");
      } else {
        errorCallBack(err);
      }
    });
}
// Generalise Post api calling //
