import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import classes from "../../assets/css/Support.module.css";
import { CircularProgress } from "@mui/material";
function Support() {
  const [loading, setLoading] = useState(true);

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className={`customColor4 p-3 p-sm-4 rounded-2 ${classes["iframe_height"]}`}>
            <CardBody className={`px-0 px-sm-3 px-md-4 ${classes["iframe_position"]}`}>
              {loading ? <CircularProgress /> : null}
              <iframe
                title="Bawlio Support"
                src="https://forms.monday.com/forms/embed/854583a9975e2e04297c47c9f91d45dd?r=use1"
                width="650"
                onLoad={() => setLoading(false)}
                className={
                  loading
                    ? `popupForm-holder ${classes["iframe_style"]} ${classes["iframe_hide"]}`
                    : `popupForm-holder ${classes["iframe_style"]} ${classes["iframe_show"]}`
                }
                height="1120"
              ></iframe>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Support;
