import React, { useMemo, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "auth/Login";
import AdminPrivateRoute from "auth/AdminPrivateRoute";
import AdminLayout from "layouts/Admin/Admin.js";
import { Redirect } from "react-router-dom";
import LoginPrivateRoute from "auth/LoginPrivateRoute";
import ReferenceDataContext from "AvatarContext";
import AdminDashboard from "views/adminModule/AdminDashboard";
import CutsomerDetails from "views/cutomerDetails/CutsomerDetails";
import UpdatePassword from "views/pages/UpdatePassword";
import User from "views/pages/User";
import Notifications from "views/components/Notifications";
import Payment from "views/payment/Payment";
import Support from "views/support/Support";
import ComingSoon from "views/pages/ComingSoon";
import DomainLabelling from "views/domainLabelling/DomainLabelling";
import ClientList from "views/clientManagement/ClientList";
import ActivityLog from "views/activityLog/ActivityLog";
import MemberList from "views/memberList/MemberList";
import NotificationCenter from "views/notificationCenter/NotificationCenter";
import { useLocation } from "react-router-dom";
import SubUser from "views/subUser/SubUser";
function App() {
  // access token of logged in user //
  const token = localStorage.getItem("access_token");
  // access token of logged in user //
  // input for change avatar throughout the application //
  const [avatarUrl, setAvatarUrl] = useState(
    require("./assets/img/user-avtar.png")
  );
  // input for change avatar throughout the application //
  // use to fetch current location //
  const location = useLocation();
  // use to fetch current location //
  // use to fetch permissions array from local storage //
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  // use to fetch permissions array from local storage //
  const userData = JSON.parse(localStorage.getItem("userData"));
  // use to fetch permissions array from local storage //
  // input for change the color //
  const [activeColor, setActiveColor] = useState("blue");
  const [classes, setClasses] = useState(false);
  // input for change the color //
  // input for sidebar size change //
  const [sidebarMini, setSidebarMini] = useState(true);
  // input for sidebar size change //

  // useMemo for different routes in a centralized way //
  const routePermissions = useMemo(() => {
    let subUserRole = [
      {
        path: "/dashboard",
        permissionKey: "dashboard",
        name: "App Club",
        rtlName: "لوحة القيادة",
        icon: "fa-solid fa-gauge-high",
        component: AdminDashboard,
        layout: "/admin",
      },
      {
        path: "/customers",
        permissionKey: "customers",
        name: "Customers",
        rtlName: "لوحة القيادة",
        icon: "fa-solid fa-users",
        component: CutsomerDetails,
        layout: "/admin",
      },
      {
        path: "/payment",
        permissionKey: "payment",
        name: "Payment",
        rtlName: "لوحة القيادة",
        icon: "fa-solid fa-credit-card",
        component: Payment,
        layout: "/admin",
      },
      {
        path: "/domainLabelling",
        permissionKey: "domainLabelling",
        name: "Web App Mngt.",
        rtlName: "لوحة القيادة",
        icon: "fa-solid fa-globe",
        component: DomainLabelling,
        layout: "/admin",
      },
      {
        path: "/support",
        permissionKey: "support",
        name: "Support",
        rtlName: "لوحة القيادة",
        icon: "fa-solid fa-headset",
        component: Support,
        layout: "/admin",
      },
    ];
    let non_icon_routes = [
      {
        path: "/update-password",
        name: "Update Password",
        rtlName: "ملف تعريفي للمستخدم",
        icon: "tim-icons icon-compass-05",
        component: UpdatePassword,
        layout: "/admin",
      },
      {
        path: "/user-profile",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        icon: "tim-icons icon-compass-05",
        component: User,
        layout: "/admin",
      },
      {
        path: "/notification",
        name: "Notification Center",
        rtlName: "ملف تعريفي للمستخدم",
        icon: "tim-icons icon-compass-05",
        component: Notifications,
        layout: "/admin",
      },
    ];
    let common_routes = [
      {
        path: "/dashboard",
        name: "App Club",
        rtlName: "لوحة القيادة",
        icon: "fa-solid fa-gauge-high",
        component: AdminDashboard,
        layout: "/admin",
      },
      {
        path: "/customers",
        name: "Customers",
        rtlName: "لوحة القيادة",
        icon: "fa-solid fa-users",
        component: CutsomerDetails,
        layout: "/admin",
      },
      ...non_icon_routes,
    ];
    let permission_key = permissions?.map((val) => Object.keys(val));
    let new_routes = [];
    let new_sub_array = [];
    for (let index = 0; index < permission_key?.length; index++) {
      for (let j = 0; j < permission_key[index]?.length; j++) {
        new_sub_array = new_sub_array.concat(permission_key[index][j]);
      }
    }
    let filtered_sub_role = subUserRole.filter((val) =>
      new_sub_array.includes(val.permissionKey)
    );
    switch (userData?.data.roles[0]) {
      case "client":
        new_routes = [
          ...common_routes,
          {
            path: "/payment",
            name: "Payment",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-credit-card",
            component: Payment,
            layout: "/admin",
          },
          {
            path: "/support",
            name: "Support",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-headset",
            component: Support,
            layout: "/admin",
          },
          {
            path: "/coming-soon-club",
            name: "VIP Club",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-kaaba",
            component: ComingSoon,
            layout: "/admin",
          },
          {
            path: "/coming-soon-capital",
            name: "Capital",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-coins",
            component: ComingSoon,
            layout: "/admin",
          },
          {
            path: "/domainLabelling",
            name: "Web App Mngt.",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-globe",
            component: DomainLabelling,
            layout: "/admin",
          },
          {
            path: "/create-subUser",
            name: "Sub User",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-user-plus",
            component: SubUser,
            layout: "/admin",
          },
        ];
        break;
      case "admin":
        new_routes = [
          ...common_routes,
          {
            path: "/clients",
            name: "Client Management",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-users",
            component: ClientList,
            layout: "/admin",
          },
          {
            path: "/activity-log",
            name: "Activity Log",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-chart-line",
            component: ActivityLog,
            layout: "/admin",
          },
          {
            path: "/member-list",
            name: "Member List",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-user-group",
            component: MemberList,
            layout: "/admin",
          },
        ];
        break;
      case "sub_client":
        new_routes = [...filtered_sub_role, ...non_icon_routes];
        break;
      default:
        new_routes = [
          ...common_routes,
          {
            path: "/clients",
            name: "Client Management",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-users",
            component: ClientList,
            layout: "/admin",
          },
          {
            path: "/activity-log",
            name: "Activity Log",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-chart-line",
            component: ActivityLog,
            layout: "/admin",
          },
          {
            path: "/member-list",
            name: "Member List",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-user-group",
            component: MemberList,
            layout: "/admin",
          },
          {
            path: "/notification-center",
            name: "Notification Center",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-bell",
            component: NotificationCenter,
            layout: "/admin",
          },
          {
            path: "/domainLabelling",
            name: "Web App Mngt.",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-globe",
            component: DomainLabelling,
            layout: "/admin",
          },
          // {
          //   path: "/metrics",
          //   name: "Metrics",
          //   rtlName: "لوحة القيادة",
          //   icon: "fa-solid fa-database",
          //   component: MetricsDetails,
          //   layout: "/admin",
          // },
          {
            path: "/create-subUser",
            name: "Sub User",
            rtlName: "لوحة القيادة",
            icon: "fa-solid fa-user-plus",
            component: SubUser,
            layout: "/admin",
          },
        ];
    }
    return new_routes;
  }, [userData, permissions]);
  // useMemo for different routes in a centralized way //
  // Generalize role based permission function for client and sub-client role //
  function generalSwitchFunc(
    location,
    current_sub_user_data,
    access_permission
  ) {
    switch (location) {
      case "customers":
        if (
          current_sub_user_data?.includes("sidebar_customers") &&
          current_sub_user_data?.includes("view_customers")
        ) {
          access_permission = ["customerList", "view"];
        } else if (current_sub_user_data?.includes("sidebar_customers")) {
          access_permission = ["customerList"];
        } else {
          access_permission = ["customerList", "view"];
        }

        break;
      case "payment":
        if (
          current_sub_user_data?.includes("sidebar_payment") &&
          current_sub_user_data?.includes("update_add_payment_details")
        ) {
          access_permission = ["view", "add/update"];
        } else if (current_sub_user_data?.includes("sidebar_payment")) {
          access_permission = ["view"];
        } else {
          access_permission = ["view", "add/update"];
        }
        break;
      case "domainLabelling":
        if (
          current_sub_user_data?.includes("sidebar_domain") &&
          current_sub_user_data?.includes("add_domain")
        ) {
          access_permission = ["add", "listByClient"];
        } else if (current_sub_user_data?.includes("sidebar_domain")) {
          access_permission = ["listByClient"];
        } else {
          access_permission = ["add", "listByClient"];
        }
        break;
      case "dashboard":
        if (current_sub_user_data?.includes("sidebar_dashboard")) {
          access_permission = ["chart", "searchClient", "clientDashboardList"];
        } else {
          access_permission = ["chart", "searchClient", "clientDashboardList"];
        }
        break;
      case "user-profile":
        access_permission = ["viewProfile"];
        break;
      default:
        break;
    }

    return access_permission;
  }
  // Generalize role based permission function for client and sub-client role //
  // useMemo for different role based permission in a centralized way //
  const rolePermissions = useMemo(() => {
    let access_permission = [];

    if (location.pathname.includes("/admin/") && token) {
      let current_location = location.pathname.replace("/admin/", "");
      let current_permission = permissions?.filter((val) =>
        location.pathname.includes(Object.keys(val))
      );
      let current_sub_user_data =
        current_permission?.length !== 0
          ? current_permission[0][current_location]
          : [];
      switch (userData?.data.roles[0]) {
        case "sub_client":
          access_permission = generalSwitchFunc(
            current_location,
            current_sub_user_data,
            access_permission
          );

          break;
        case "client":
          access_permission = generalSwitchFunc(
            current_location,
            current_sub_user_data,
            access_permission
          );
          break;
        default:
          switch (current_location) {
            case "dashboard":
              access_permission = ["users", "search"];
              break;
            case "customers":
              access_permission = ["customerList", "view", "clientList"];
              break;
            case "domainLabelling":
              access_permission = ["add", "list"];
              break;
            case "create-subUser":
              access_permission = ["clientList"];
              break;
            case "member-list":
              access_permission =
                userData?.data.roles[0] !== "admin"
                  ? ["invoiceEmailColumn"]
                  : [""];
              break;
            default:
              break;
          }
          break;
      }
    } else {
      return <Redirect to={{ pathname: "/login" }} />;
    }
    return access_permission;
  }, [userData, location, permissions, token]);
  // useMemo for different role based permission in a centralized way //
  return (
    <ReferenceDataContext.Provider
      value={{
        avatarUrl,
        setAvatarUrl,
        activeColor,
        setActiveColor,
        sidebarMini,
        setSidebarMini,
        classes,
        setClasses,
        routePermissions,
        rolePermissions,
      }}
    >
      <div>
        <Switch>
          <AdminPrivateRoute
            path={`/admin`}
            componentPrivateAdmin={AdminLayout}
          />
          <LoginPrivateRoute path="/login" componentPrivateLogin={Login} />
          <Route path="/login" component={Login} />

          <Redirect from="/" to="/login" />
        </Switch>
      </div>
    </ReferenceDataContext.Provider>
  );
}

export default App;
