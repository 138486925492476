import * as Yup from "yup";

export const UpdatePasswordSchema = Yup.object({
  old_password: Yup.string()
    .required("Please enter your old password")
    .min(6, 'Password must contain 6 or more characters'),
  new_password: Yup.string()
    .required("Please enter your new password")
    .min(6, 'Password must contain 6 or more characters'),
  confirm_new_password: Yup.string()
    .required("Please confirm your new password")
    .min(6, 'Password must contain 6 or more characters').oneOf([Yup.ref('new_password'), null], 'New password and confirm password does not match'),
     
});
